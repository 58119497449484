import { Alert, Button, HeaderText, Icon } from '@elements'
import { Product, ProductType, isShare } from '@models/Product'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { FormikContextType, FormikErrors, useFormikContext } from 'formik'
import { memo, useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { AdminProductsParamList } from '../../../navigation/types'
import { errorsMap } from '../ProductForm/helpers/ProductErrorMap'

import Colors from '@/constants/Colors'
import { bullet } from '@helpers/display'
import { ProductDetailsForm } from '../ProductForm/helpers/formHelpers'

type Props = {
  goBack(): void
  title: string
  actionTitle: 'Edit' | 'Save' | 'Submit'
  goAction(): void
  isLoading?: boolean
}

/** Base header UI for the product details screen */
export function EditHeader({ goBack, goAction, actionTitle, title, isLoading }: Props) {
  return (
    <View style={styles.container}>
      <Icon name="times" color={Colors.shades[500]} onPress={goBack} />
      <HeaderText style={styles.title}>{title}</HeaderText>
      <Button small title={actionTitle} onPress={goAction} loading={isLoading} />
    </View>
  )
}

/** callback that handles the formik submission process on the product details form */
export const useHandleSubmitProd = (formik: FormikContextType<ProductDetailsForm>) =>
  useCallback(() => {
    if (!formik.dirty) return Alert('The form has not changed', 'Please make some changes before submitting')
    formik.handleSubmit()
    if (!formik.isValid) Alert('The following fields need attention: ', makeErrorAlertMessage(formik.errors))
  }, [formik])

export const AddEditProductHeader = memo(function AddEditProductHeader({
  product,
  prodType,
}: {
  product?: Pick<Product, 'name' | 'id'>
  prodType: ProductType
}) {
  const formik = useFormikContext<ProductDetailsForm>()
  const submitForm = useHandleSubmitProd(formik)
  const navigation = useNavigation<StackNavigationProp<AdminProductsParamList, 'AddProduct' | 'EditProduct'>>()

  /** Should use navigation.navigate here instead of navigate.replace because we want the screen to retain its state when navigating away and coming back */
  const goBack = useCallback(
    (isDirty: boolean) => {
      if (isDirty) {
        Alert("Your changes aren't saved!", 'Do you want to continue without saving or want to go back?', [
          { text: 'Cancel', style: 'cancel' },
          { text: 'Continue without saving', onPress: () => navigation.navigate('ProductList') },
        ])
      } else {
        navigation.navigate('ProductList')
      }
    },
    [navigation],
  )

  const title = useMemo(() => {
    if (product?.id) return `Editing: ${product.name}`

    if (isShare(prodType)) return `Add new share`

    return `Add new ${prodType} product`
  }, [prodType, product?.id, product?.name])

  return (
    <EditHeader
      isLoading={formik.isSubmitting}
      // Check if touched which will tell us if the form is dirty and should prompt user before exiting
      goBack={() => goBack(Object.keys(formik.touched).length !== 0)}
      title={title}
      actionTitle="Submit"
      goAction={submitForm}
    />
  )
})

/** Creates a string of formik errors for UI display */
const makeErrorAlertMessage = (errors: FormikErrors<ProductDetailsForm>): string => {
  let list: string[] = []

  for (const k in errors) {
    const key = k as keyof typeof errors

    // If the field for the error has a string error message from formik prioritize displaying that
    if (typeof errors[key] === 'string') {
      list = [...list, errors[key] as string]
      continue
    }

    // If a string error message was not found in the formik errors object, then just display the field name which had the error, using a UI friendly representation of the field name. (errorsMap)
    if (errorsMap[key].length) {
      list = [...list, errorsMap[key]]
    }
  }
  return list.map((fieldMsg) => `\n ${bullet} ${fieldMsg}`).join('')
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades['100'],
  },
  title: { flex: 1, marginHorizontal: 20 },
})
