import { CSA } from '@models/CSA'
import { Distribution } from '@models/Distribution'
import { isNonPickup, isNonPickupDistLocation } from '@models/Location'
import { isCartStandard } from '@models/Order'
import { PaymentSchedule, PhysicalProduct, Unit, isAddon, isShare, isStandard } from '@models/Product'
import { DateTime } from 'luxon'
import { useCallback } from 'react'

import { useCartService } from '../useCartService'

import { Logger } from '@/config/logger'
import { CartServiceType } from '@/constants/types/cartService'
import { Loader, hideModal } from '@elements'
import { addressBuilder } from '@helpers/builders'
import { canUpdateQuantity } from '@helpers/canUpdateQuantity'
import { removeComplexDuplicates } from '@helpers/helpers'
import { findPriceForAppMode } from '@helpers/products'
import { isSameDay } from '@helpers/time'
import { getFarmCartItems } from '@models/Cart'
import { DataError } from '@shared/Errors'
import { setLocation } from '../../../components/AddToCartFlow-components/SetLocation'
import { setScheduleAndDates } from '../../../components/AddToCartFlow-components/SetScheduleAndDates'
import { getAvailableSchedules } from '../../../components/AddToCartFlow-components/useAvailableSchedules'
import { getAutoSelectedDates, getAutoSelectedSchedule, getPickupsCacheAddtoCartFlow } from './helpers'
import { OnSuccessFn } from './useAddToCartFlow'

/** Options for the setLocation callback which is called as part of the addToCart flow. This is meant for any options which are variable depending on the item being added to the cart. */
export type SetLocationFlowOpts = {
  prod: PhysicalProduct
  /** in case the schedule is selected in advance */
  preselectedSchedule?: Distribution
  /** The schedules from the cart which match the ids of some product schedules. These will be used to determine if a schedule can be selected automatically. It is assumed these schedules are non-hidden, non-closed (if consumer mode), have an address if they're NonPickup, and have future pickups left for this product */
  matchingSchedulesCart: Distribution[]
  /** this will limit the schedule options to these, for the purposes of auto-selection and manual selection. This is intended to be used for availAddons matchingDistIds based on cart or past purchases, so the available addons will always be available at these schedules only. They may coincide with the matching schedules from cart, or they may not, in the case of matching schedules based on past purchases. Some may be NonPickup and may not have a delivery address */
  matchingSchedulesAvailAddon?: Distribution[]
  /** unit selected in previous steps. required for unit products. If it's a standard product, the unit argument will be originated from the unit selection modal. */
  unit?: Unit
  /** csa selected in previous steps. required for shares */
  csa?: CSA
  /** The pay schedule to be selected in advance */
  paymentSchedule?: PaymentSchedule
  /** will be invoked with result from addToCart, or void if a modal gets dismissed. It resolves the outer promise of the main addToCart flow. It is expected to hide the modal, so it is not necessary to call hideModal from here. */
  onSuccess: OnSuccessFn
  /** should be called on errors. It rejects the outer promise of the main addToCart flow. It is expected to hide the modal, so it is not necessary to call hideModal from here. */
  onErr: (err: unknown) => void
  /** When `autoAddMatchingDates` is true... If prod is standard and only one schedule matches, we allow for adding automatically if the product is available on any same date as another cartitem with that schedule */
  enabledAutoAddMatchingDates?: boolean
  /** An optional array of pickup dates that should be added to cart for this product. Only intended for standard products if pickups are selected in advance of calling this helper by some other means. If defined the flow skip the date selector and add these instead, just like it should do in case of auto-add matching dates. */
  preselectedDates?: DateTime[]
}

/** Options for the main hook that provides the setLocation callback. This is meant for any options which are constant. */
export type UseSetLocationFlowOpts = {
  cartServiceType?: CartServiceType
  /** modifies behaviors specific to the given app mode */
  isWholesale: boolean | undefined
}

/** the last step of the add-to-cart flow which handles selecting location, address, schedule, and dates
 *
 * Tips:
 * - Don't use toasts or alerts here, because the onSuccess and onErr callbacks should already include a confirmation alert, which is configurable based on the arguments passed. Use that instead.
 */
export type SetLocationFlow = (opts: SetLocationFlowOpts) => Promise<void>

/** Step where a product may select a CSA */
export function useSetLocationFlow({ cartServiceType = 'consumer', isWholesale }: UseSetLocationFlowOpts) {
  const { cart: cartProp, addToCart, isAdmin } = useCartService({ cartServiceType, isWholesale })

  return useCallback<SetLocationFlow>(
    async function setLocationFlow({
      prod,
      preselectedSchedule,
      preselectedDates,
      enabledAutoAddMatchingDates = true,
      matchingSchedulesCart,
      matchingSchedulesAvailAddon,
      csa,
      paymentSchedule,
      onSuccess,
      onErr,
      unit,
    }) {
      if (isWholesale === undefined) {
        Logger.error(
          new Error("The addToCart flow is being called before the app mode is defined. This shouldn't happen"),
        )
        return onSuccess(undefined, { msg: 'Some resources are still initializing. Please try again', showMsg: true })
      }

      if (isAddon(prod) && !isAdmin && !matchingSchedulesAvailAddon?.length) {
        return onErr(new Error('Missing a list of available schedules for the addon in consumer mode'))
      }

      // We will run all the logic necessary to auto-select a schedule
      const autoSelectedSchedule = getAutoSelectedSchedule({
        prod,
        preselectedSchedule,
        matchingSchedulesCart,
        matchingSchedulesAvailAddon,
        isAdmin,
        isWholesale,
      })

      /** This cart array should have only the items of the current appMode and farm. It must be selected at runtime and not through the selector hook because only at runtime we can know the product farm id */
      const cart = getFarmCartItems({ items: cartProp, farmId: prod.farm.id, isWholesale })

      // If an auto-selected schedule is defined, it will enter the flow for auto-selecting schedule and dates
      if (autoSelectedSchedule) {
        // This schedule will be automatically selected

        /** This is a sanity check and shouldn't happen because all the routes that would lead to a single schedule should have their own way to prevent it from fulfilling this condition. BUT Just in case... */
        if (autoSelectedSchedule.isHidden || (!isAdmin && autoSelectedSchedule.closed)) {
          const err = new DataError(
            "A physical product couldn't be added to cart because an auto-selected schedule is either hidden or closed. This shouldn't happen.",
            { prod, autoSelectedSchedule },
          )
          return onErr(err)
        }
        /** All the available distribution dates for this product at the auto-selected schedule */
        const pickups =
          preselectedDates ??
          getPickupsCacheAddtoCartFlow(autoSelectedSchedule, prod, {
            excludeClosedDistros: !isAdmin,
            ignoreDisableBuyInFuture: isAdmin,
            ignoreOrderCutoffWindow: isAdmin,
          })

        /** This scenario should never happen if all is going well, because a schedule shouldn't be auto-selected if it doesn't have pickups. But it's an extra check for safety at this layer */
        if (!pickups.length) {
          return onErr(
            new DataError(
              "A physical product couldn't be added to cart due to a lack of distribution dates left for this product at the auto-selected schedule. This should've been prevented by the UI.",
              { prod, autoSelectedSchedule },
            ),
          )
        }

        const canAddResult = canUpdateQuantity({
          cart,
          cartItem: { product: prod, quantity: 1, pickups, unit },
          isWholesale,
        })

        // If it's a share and the canAddResult is positive, we can already add automatically because there's no date selection step for shares
        if (isShare(prod) && canAddResult) {
          try {
            hideModal()
            Loader(true)
            const res = await addToCart({
              product: prod,
              distribution: autoSelectedSchedule,
              pickups,
              csa,
              paymentSchedule,
              isAdmin,
            })
            return onSuccess(res, { autoAddedDistro: true, autoAddedDates: false })
          } catch (err) {
            return onErr(err)
          }
        } else {
          // Scenario: We have an auto-selected schedule and the product either is Standard, or it's a Share but there's no stock left at the autoSelected schedule.

          // Next comes the check for potential auto date-selection

          // We select dates automatically if either:
          // 1) The product is available on any same date/s as a cartItem at the matching schedule,
          // OR 2) There's a single pickup left at this schedule for this product.
          // - Product must have enough stock for the auto-selected date/s
          // - In wholesale there is a limit of 1 auto-selected date per farm

          // Only try this if the auto-add feature is enabled. Exception: if we're in wholesale it should ignore enabledAutoAddMatchingDates and try auto-date selection anyway
          if (enabledAutoAddMatchingDates || isWholesale) {
            // Get non-share physical items in cart at the auto-selected schedule
            // We check only cartStandards, because cartShare pickups don't get selected in a custom way
            // So for autoAdd purposes, we only want to consider pickups in cart which were selected manually by the user
            const itemsMatchingSchedule = cart
              .filter(isCartStandard)
              .filter((ci) => ci.distribution?.id === autoSelectedSchedule.id)

            // Gets the possible pickup dates which match the selected pickup dates of the cart items at the auto-selected schedule
            const matchingPickups = pickups.filter(
              (d) =>
                !!itemsMatchingSchedule.find(
                  (ci) => !!ci.pickups.find((d2) => isSameDay(d, d2, autoSelectedSchedule.location.timezone)),
                ),
            )

            /** These are the tentative pickups to auto-add to the cart */
            const autoSelectedDates = getAutoSelectedDates({ isWholesale, matchingPickups, possiblePickups: pickups })

            // Before auto-adding we must ensure this operation doesn't exceed stock
            const canAddResult = canUpdateQuantity({
              cart,
              cartItem: { product: prod, quantity: 1, pickups: autoSelectedDates, unit },
              isWholesale,
            })

            // This is the last check before auto-adding both schedule and date/s
            if (
              isStandard(prod) &&
              autoSelectedDates.length >= (isWholesale ? 1 : prod.minPickups ?? 1) &&
              canAddResult
            ) {
              try {
                hideModal()
                Loader(true)
                const res = await addToCart({
                  product: prod,
                  distribution: autoSelectedSchedule,
                  pickups: autoSelectedDates,
                  unit, // Unit and price are expected to be defined here for this to work.
                  price: unit ? findPriceForAppMode(unit.prices, isWholesale) : undefined,
                  csa,
                  paymentSchedule,
                  isAdmin,
                })
                return onSuccess(res, {
                  autoAddedDistro: !!autoSelectedSchedule,
                  autoAddedDates: !!autoSelectedDates.length,
                })
              } catch (err) {
                return onErr(err)
              }
            }
          }

          if (!isWholesale) {
            /**  Scenario: We're in retail and there's an auto-selected schedule, but it was not possible to auto-add dates...
             *
             * In retail mode we will let a modal handle the schedule and date selection. If it's Standard, it should allow them to select the date/s, and the auto-selected schedule should be used in the modal
             * In wholesale this should be skipped because this is part of the auto-add logic and this scenario means the 2nd item has a schedule in common with an existing cart item but the dates couldn't be auto-added for some other reason; perhaps due to insufficient stock. So in wholesale the flow must continue to the manual selection portion. */
            try {
              const scheduleAndDates = await setScheduleAndDates({
                prod,
                initialSchedule: autoSelectedSchedule,
                // The autoSelected schedule must be the only schedule choice presented to the user.
                schedulesFilter: [autoSelectedSchedule],
                locsFilter: [autoSelectedSchedule.location],
                unit,
                cartServiceType,
                isWholesale,
                cart,
              })
              if (!scheduleAndDates) {
                return onSuccess()
              }

              const { schedule, dates } = scheduleAndDates

              /** The modal component should be in charge of only allowing a valid stock to continue. But this is an additional layer of safety against purchases beyond stock available */
              const canAddResult = canUpdateQuantity({
                cart,
                cartItem: { product: prod, quantity: 1, pickups: dates, unit },
                isWholesale,
              })

              if (canAddResult) {
                /** If the stock check was successful for the autoSelectedSchedule and manually selected dates, we can add to cart.
                 * Otherwise the flow should allow the user to select both the schedule and dates. */
                hideModal()
                Loader(true)
                const res = await addToCart({
                  product: prod,
                  distribution: schedule,
                  pickups: dates,
                  unit, // Unit and price are expected to be defined here for this to work.
                  price: unit ? findPriceForAppMode(unit.prices, isWholesale) : undefined,
                  csa,
                  paymentSchedule,
                  isAdmin,
                })
                return onSuccess(res, { autoAddedDistro: !!autoSelectedSchedule, autoAddedDates: false })
              }
            } catch (err) {
              return onErr(err)
            }
          }
        }
      }

      // Scenario: Nothing got auto-added (schedule or dates)
      // Next: All manual selection.

      /** The schedules shown as options will be limited to these */
      const availSchedules = getAvailableSchedules({
        prod,
        isAdmin,
        isWholesale,
        cart,
        schedulesIdsFilter: (isAddon(prod) && !isAdmin
          ? // If it's an addon in consumer mode, choose only from the matching availAddon schedules.
            matchingSchedulesAvailAddon!
          : matchingSchedulesCart.length
          ? // Else if there are any matching schedules, limit to those
            matchingSchedulesCart
          : undefined
        )?.map((sch) => sch.id),
        locsIdsFilter: undefined,
      })

      if (!availSchedules.length) {
        return onSuccess(undefined, {
          alertType: 'alert',
          msg: 'No locations are available for this product.',
          showMsg: true,
        })
      }

      // Flow: Get the location, schedule and dates from user input through modals flow

      try {
        // Next step: location selection
        const selectableLocs = await setLocation({
          prod,
          schedulesFilter: availSchedules,
          cartServiceType,
          isWholesale,
        })

        if (!selectableLocs) {
          return onSuccess()
        }

        // These address validations are here for sanity, and shouldn't happen if the location selection modal is working correctly
        if (!selectableLocs.length) {
          return onErr(
            new DataError('No locations were returned by setLocation', {
              prod,
              isAdmin,
              isWholesale,
              schedulesFilter: availSchedules,
            }),
          )
        }

        // Validate location types and addresses
        // This should never happen if the location selection modal is working correctly
        selectableLocs.forEach((loc) => {
          if (isNonPickup(loc)) {
            if (!loc.address) {
              return onErr(new DataError('No delivery address was assigned on location selection', { loc, prod }))
            }

            try {
              addressBuilder.validate(loc.address, { allowPO: false })
            } catch (error) {
              return onErr(
                new DataError("Invalid address was allowed in location selection modal. This shouldn't happen.", {
                  loc,
                  prod,
                }),
              )
            }
          }
        })

        /** In wholesale if there's a single selectable location and schedule and there's an existing date in the cart for the product farm we should auto-select it */
        if (isWholesale && selectableLocs.length === 1) {
          // Get the schedules associated with the single possible location
          const selectableSchedules = availSchedules.filter((sch) => sch.location.id === selectableLocs[0].id)
          if (selectableSchedules.length === 1) {
            // This means only one schedule is a viable option
            // Get the date/s of items in the cart (For the current appMode and product farm)
            const cartDates = removeComplexDuplicates(
              cart.filter(isCartStandard).flatMap((itm) => itm.pickups),
              isSameDay,
            )
            if (cartDates.length) {
              // This means there is at least one date in the cart
              // Check if the date is available at the single selectable schedule
              const scheduleDates = getPickupsCacheAddtoCartFlow(selectableSchedules[0], prod, {
                excludeClosedDistros: !isAdmin,
                ignoreDisableBuyInFuture: isAdmin,
                ignoreOrderCutoffWindow: isAdmin,
              })

              const cartDateInScheduleDates = scheduleDates.find((schDate) => isSameDay(schDate, cartDates[0]))

              if (cartDateInScheduleDates) {
                // This means the cart date is available at the single selectable schedule.
                // Find if the stock is sufficient
                const canAddResult = canUpdateQuantity({
                  cart,
                  cartItem: { product: prod, quantity: 1, pickups: [cartDateInScheduleDates], unit },
                  isWholesale,
                })
                if (canAddResult) {
                  try {
                    // We can auto-add to the cart
                    hideModal()
                    Loader(true)
                    const res = await addToCart({
                      product: prod,
                      distribution: selectableSchedules[0],
                      pickups: [cartDateInScheduleDates],
                      unit,
                      price: unit ? findPriceForAppMode(unit.prices, isWholesale) : undefined,
                      csa,
                      paymentSchedule,
                      isAdmin,
                    })
                    return onSuccess(res, { autoAddedDistro: true, autoAddedDates: true })
                  } catch (err) {
                    return onErr(err)
                  }
                }
              }
            }
          }
        }

        // Next step: Schedule and date selection
        const scheduleAndDates = await setScheduleAndDates({
          prod,
          schedulesFilter: availSchedules,
          locsFilter: selectableLocs,
          unit,
          cartServiceType,
          isWholesale,
          cart,
        })

        if (!scheduleAndDates) {
          return onSuccess()
        }
        const { schedule, dates } = scheduleAndDates

        if (isNonPickupDistLocation(schedule.location) && isNonPickupDistLocation(selectableLocs[0])) {
          // Assign the address of the selected delivery location onto the selected schedule's location
          schedule.location.address = selectableLocs[0].address
        }

        /** The modal component should be in charge of only allowing a valid stock to continue. But this is an additional layer of safety against purchases beyond stock available */
        const canAddResult = canUpdateQuantity({
          cart,
          cartItem: { product: prod, quantity: 1, pickups: dates, unit },
          isWholesale,
        })
        if (!canAddResult) {
          return onSuccess(undefined, { msg: 'The stock is insufficient', alertType: 'alert', showMsg: true })
        }

        hideModal()
        Loader(true)
        const res = await addToCart({
          product: prod,
          distribution: schedule,
          pickups: dates,
          unit,
          price: unit ? findPriceForAppMode(unit.prices, isWholesale) : undefined,
          csa,
          paymentSchedule,
          isAdmin,
        })
        return onSuccess(res, { autoAddedDistro: false, autoAddedDates: false })
      } catch (error) {
        onErr(error)
      }
    },
    [cartProp, addToCart, isAdmin, isWholesale, cartServiceType],
  )
}
