import { Address } from '@models/Address'
import {
  PostalCodeSchemaCanada,
  ShortStateSchema,
  ShortStateSchemaCanada,
  StateSchemaCountry,
  ZipCodeSchema,
  ZipCodeSchemaCountry,
} from './builders/validators/sharedSchemasAddress'
import { findCountryData } from './international/types'

/** Will try to get the state abbreviation from either the full name or abbreviation  **/
export function getShortState(name: string, country: string): string | undefined {
  return findCountryData(country)?.states.getShortState(name)
}

/** Takes in the state in any format and returns an object with the abbreviation and full name */
export function getState(name: string, country: string): { name: string; abbreviation: string } | undefined {
  return findCountryData(country)?.states.getState(name)
}

/** Lists the abbreviated state names */
export function listStateAbbr(country: string): string[] | undefined {
  return findCountryData(country)?.states.listStateAbbr() as string[]
}

/** Lists the state names as labels and abbreviations as values */
export function stateItemList(country: string):
  | {
      label: string
      value: string
    }[]
  | undefined {
  return findCountryData(country)?.states.stateItemList()
}

/** Validates a state code by country. If country is undefined it will check whether it is valid in any country */
export function isValidShortState(stateCode: string, country: string | undefined): boolean {
  if (country) {
    const ctryData = findCountryData(country)
    if (!ctryData) throw new Error('Wrong country for state validation')

    return StateSchemaCountry({ errorMsgMode: 'data' }).isValidSync(stateCode, {
      context: { country: ctryData.code },
    })
  } else {
    return (
      ShortStateSchema({ errorMsgMode: 'data' }).isValidSync(stateCode) ||
      ShortStateSchemaCanada({ errorMsgMode: 'data' }).isValidSync(stateCode)
    )
  }
}

/** Validates a zipcode by country. If country is undefined it will check whether it is valid in any country */
export function isValidZipcode(zipcode: string, country: string | undefined): boolean {
  if (country) {
    const ctryData = findCountryData(country)

    if (!ctryData) throw new Error('Wrong country for zip code validation')

    return ZipCodeSchemaCountry.isValidSync(zipcode, { context: { country: ctryData.code }, strict: true })
  } else {
    return ZipCodeSchema.isValidSync(zipcode) || PostalCodeSchemaCanada.isValidSync(zipcode)
  }
}

/** Receives a string from an unknown source which is assumed to contain a postal code, and converts it to a generic format that works for the zipcode field of the address model in all supported countries
 * - It could be used to parse the zipcode on a google places result item, or other location APIs
 * - Could be used to process the zipcode text from an input field
 */
export function parsePostalCode(code: string | number): Address['zipcode'] {
  return (
    (typeof code === 'number' ? code.toString() : code)
      // Removing whitespace and punctuation because google places might have a middle space in canadian farms
      .replace(/[^\w]|_/g, '')
      // To upper case because we want canadian postal codes to be saved in upper case in the DB
      .toUpperCase()
  ) // Convert to uppercase
}
