import { CustomShareRunCard } from '@/admin/screens/CustomShares/CustomShareRunCard'
import Colors, { withAlpha } from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { fetchAllCustomShares } from '@api/FarmGenAdmin'
import { InfoRow } from '@components'
import { Button, HeaderText, LoadingView, Modal, Text } from '@elements'
import { formatShareRunType } from '@helpers/custom_shares/display'
import { formatPickupDate, formatTime } from '@helpers/display'
import { sortByEarliest, sortByLatest } from '@helpers/sorting'
import { isAfter, isSameDay } from '@helpers/time'
import { CustomShareRun, ScheduledCustomShare, isScheduledCustomShare } from '@models/CustomShare'
import { Farm } from '@models/Farm'
import { DateTime } from 'luxon'
import { FlatList, StyleSheet, View } from 'react-native'

const sortPastAndScheduledRuns = async () => {
  const customShares = await fetchAllCustomShares()
  const weekAgoDate = DateTime.now().minus({ days: 7 })

  return {
    // Filter out past runs older than 7 days, sort by latest
    pastRuns: customShares
      .flatMap((cs) => {
        return cs.runs.map((run) => ({ farm: cs.farm, run }))
      })
      .filter((item) => isAfter(item.run.date, weekAgoDate))
      .sort((a, b) => sortByLatest('date')(a.run, b.run)),

    // Sort scheduled runs by earliest next run
    scheduledRuns: customShares
      .filter(isScheduledCustomShare)
      .sort((a, b) => sortByEarliest('date')(a.nextRun, b.nextRun)),
  }
}

// Helper function to render past runs
const renderPastRuns = ({ item }: { item: { farm: Pick<Farm, 'name'>; run: CustomShareRun } }) => {
  return (
    <View style={styles.row}>
      <View style={globalStyles.flex1}>
        <InfoRow
          data={{
            left: item.farm.name,
            right: `${formatShareRunType(item.run.type)} on ${formatPickupDate(item.run.date)}, ${formatTime(
              item.run.date,
            )}`,
          }}
          infoRowContainerStyle={styles.alignCenter}
        />
      </View>
      <Button
        small
        outline
        title="View"
        onPress={() => {
          Modal(<CustomShareRunCard run={item.run} />, { title: 'Share Run Details', dismissable: true })
        }}
      />
    </View>
  )
}

// Helper function to render scheduled runs
const renderScheduledRuns = ({ item }: { item: ScheduledCustomShare }) => {
  return (
    <InfoRow
      data={{
        left: item.farm.name,
        right: `${formatShareRunType(item.nextRun.type)} on ${formatPickupDate(item.nextRun.date)}, ${formatTime(
          item.nextRun.date,
        )}`,
      }}
      infoRowContainerStyle={
        isSameDay(DateTime.now(), item.nextRun.date) ? { backgroundColor: withAlpha(Colors.red, 0.4) } : undefined
      }
    />
  )
}

/** Quick glance at the past and scheduled runs for all farms. */
export function FarmGenAdminQuickGlance() {
  const customSharesFx = useApiFx(sortPastAndScheduledRuns, [])

  return (
    <View style={globalStyles.flex1}>
      <HeaderText>Quick Glance</HeaderText>
      <LoadingView switchMode loading={customSharesFx.loading} error={customSharesFx.err} success={customSharesFx.data}>
        {({ pastRuns, scheduledRuns }) => (
          <>
            <View style={styles.container}>
              <View style={styles.section}>
                <Text style={styles.sectionTitle} type="medium" size={16}>
                  Past Runs (Last 7 Days)
                </Text>
                <FlatList
                  data={pastRuns}
                  renderItem={renderPastRuns}
                  ListEmptyComponent={<Text>No past runs in the last 7 days</Text>}
                />
              </View>

              <View style={styles.section}>
                <Text style={styles.sectionTitle} type="medium" size={16}>
                  Scheduled Runs
                </Text>
                <FlatList
                  data={scheduledRuns}
                  renderItem={renderScheduledRuns}
                  ListEmptyComponent={<Text>No scheduled runs</Text>}
                />
              </View>
            </View>
          </>
        )}
      </LoadingView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 2,
    padding: 16,
  },
  section: {
    marginBottom: 24,
    flex: 1,
  },
  sectionTitle: {
    marginBottom: 12,
  },
  alignCenter: {
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 6,
  },
})
