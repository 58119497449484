import { Button, HeaderText, Modal, Tooltip, ToolTips, typography } from '@elements'
import { AlgoliaAdminCustomer, AlgoliaDocType } from '@models/Algolia'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { withAdminAuth } from '../../../../hooks/withAdminAuth'
import { withAdminIndex } from '../../../../hooks/withAlgoliaIndex'

import { AdminTable } from '@/admin/components/AdminTable/AdminTable'
import { defaultRefinementLimit } from '@/admin/components/AdminTable/ConnectedRefinementDropdown'
import { AdminView } from '@/admin/components/AdminView'
import { InviteCustomerPrompt } from '@/admin/components/InviteCustomerPrompt'
import { CustomerParamList } from '@/admin/navigation/types'
import { globalStyles } from '@/constants/Styles'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { UseLayoutReturn } from '@/hooks/useLayout'
import { formatMoney, unmarshalPhoneNumber } from '@helpers/display'
import { Permission } from '@helpers/Permission'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { View } from 'react-native'
import { ExportCSVButton } from './ExportCsvButton'

/** Screen that displays farm's customers  */
export function AdminCustomerScreenComp() {
  const navigation = useNavigation<StackNavigationProp<CustomerParamList, 'CustomerList'>>()
  const styles = useStyles()
  const [csaFilterShowMore, setCsaFilterShowMore] = useState(false)
  const [locFilterShowMore, setLocFilterShowMore] = useState(false)
  const [scheduleFilterShowMore, setScheduleFilterShowMore] = useState(false)
  const inviteCustomerPermit = useHasPermissionWithFlag(Permission.AccountSetup)
  const { params } = useRoute<RouteProp<CustomerParamList, 'CustomerList'>>()

  const goInviteCustomerPrompt = () => {
    Modal(<InviteCustomerPrompt />, { title: 'Add Customer' })
  }

  /** This function should be called after defaultCsaRefinement (if exists) is applied every time. */
  const onDefaultCsaRefinementApplied = useCallback(() => {
    // resets csaName parameter
    navigation.setParams({ csaName: undefined })
  }, [navigation])

  return (
    <AdminView>
      <View style={styles.wrapper}>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={30}>Customers</HeaderText>
          <Tooltip title="Customers" size={15} id={ToolTips.CUSTOMERS} />
        </View>
        <View style={styles.buttonContainer}>
          <ExportCSVButton />
          {inviteCustomerPermit && (
            <Button
              small
              size={15}
              title="Add or Invite Customer"
              style={styles.addButton}
              textStyle={styles.addButtonText}
              onPress={goInviteCustomerPrompt}
            />
          )}
        </View>
        <AdminTable<AlgoliaAdminCustomer>
          type={AlgoliaDocType.CUSTOMER}
          sortOptionKeys={['farmCredit']}
          columns={[
            {
              title: 'Name',
              key: 'name',
            },
            {
              title: 'Email',
              key: 'email',
              size: 2,
              process: (user) => user.email,
            },
            {
              title: 'Phone Number',
              key: 'phone',
              process: (user) => unmarshalPhoneNumber(user.phone, false),
            },
            {
              title: 'Farm Credit',
              key: 'farmCredit',
              process: (user) => (user.farmCredit ? formatMoney(user.farmCredit) : formatMoney(0)),
            },
          ]}
          refinements={[
            {
              attribute: 'csas.name',
              placeholder: 'All CSAs',
              limit: defaultRefinementLimit,
              showMoreLimit: defaultRefinementLimit * 3,
              setShowMore: setCsaFilterShowMore,
              showMore: csaFilterShowMore,
              defaultRefinement: params?.csaName,
              onDefaultRefinementApplied: onDefaultCsaRefinementApplied,
            },

            {
              attribute: 'locations.name',
              placeholder: 'All Locations',
              limit: defaultRefinementLimit,
              showMoreLimit: defaultRefinementLimit * 3,
              setShowMore: setLocFilterShowMore,
              showMore: locFilterShowMore,
            },
            {
              attribute: 'distributions.name',
              placeholder: 'All Schedules',
              limit: defaultRefinementLimit,
              showMoreLimit: defaultRefinementLimit * 3,
              setShowMore: setScheduleFilterShowMore,
              showMore: scheduleFilterShowMore,
            },
          ]}
          expandRefinementsInitial={params?.csaName ? true : undefined}
          searchPlaceholder="Email, name or phone"
          onRowPress={(user) => navigation.navigate('CustomerDetails', { custId: user.id })}
        />
      </View>
    </AdminView>
  )
}

const useStyles = () =>
  useLayoutFnStyles((layout: UseLayoutReturn) => ({
    addButton: {
      paddingHorizontal: 10,
      alignSelf: layout.isExtraSmallDevice ? 'flex-start' : 'auto',
    },
    addButtonText: { fontFamily: typography.body.regular, fontWeight: '600', fontSize: 14 },
    buttonContainer: {
      marginVertical: 10,
      flexDirection: layout.isExtraSmallDevice ? 'column' : 'row',
    },

    wrapper: {
      marginHorizontal: layout.isSmallDevice ? 10 : 30,
      marginTop: layout.isSmallDevice ? 10 : 30,
    },
  }))

export const AdminCustomerScreen = withAdminAuth(
  withAdminIndex(AdminCustomerScreenComp, AlgoliaDocType.CUSTOMER) as React.ComponentType,
  Permission.Orders,
)
