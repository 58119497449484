import { ErrorTypes, ErrorWithCode } from '@shared/Errors'
import * as yup from 'yup'
import { ValidateOptions } from 'yup'
import { nonEmptyString } from '../../helpers'

type validateFromSchemaOpts = Omit<ValidateOptions, 'strict'> & {
  /** If true this validation function will throw the unmodified error direct from the schema. Otherwise it will use the default behavior which is to re-throw the error within a custom error class. This is necessary in some situations such as passing the error to the formik helper "yupToFormErrors" which does not handle correctly the custom error */
  useDefaultYupError?: boolean
}

/**
 * Will validate data against a Yup schema and cast any Yup errors as ValidationErrors
 * @param schema The schema to validate the data against
 * @param value The data to validate
 * @param opts Options to pass to the Yup Validation
 */
export function validateFromSchema<Type>(
  schema: yup.Schema<Type>,
  value: unknown,
  { useDefaultYupError, ...opts }: validateFromSchemaOpts = {},
): yup.InferType<typeof schema> {
  try {
    return schema.validateSync(value, {
      ...opts,
      // strict should be true in all builders' validate method
      strict: true,
    }) as Type
  } catch (error) {
    if (useDefaultYupError) {
      throw error
    }
    if (error instanceof yup.ValidationError) {
      if (nonEmptyString(error.path)) {
        throw new ValidationError({ path: error.path ?? '', msg: error.message })
      } else {
        throw new ValidationError({ path: 'unknown', msg: error.message })
      }
    }
    // Unknown error throw it directly
    throw error
  }
}

/** Wrapper for the default yup.ValidationError. It's thrown when a field validation fails and contains information about the path */
export class ValidationError extends ErrorWithCode<'form-schema-error', { path: string }> {
  constructor({ msg, path }: { msg: string; path: string }) {
    super({ type: ErrorTypes.Validation, code: 'form-schema-error', devMsg: msg, data: { path } })
    Object.setPrototypeOf(this, ValidationError.prototype)
  }
}

/** Returns true if the supplied error is a validation error. */
export function isValidationError(err: unknown): err is ValidationError {
  return err instanceof ValidationError
}
