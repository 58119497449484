import { YUP_MONEY_REQUIRED, YUP_WHOLE_NUMBER_REAL } from '@helpers/Yup'
import { Farm } from '@models/Farm'

import { isValidShortState, isValidZipcode } from '@helpers/address'
import { RegionType } from '@models/Location'
import { MoneyWithCurrency } from '@models/Money'
import { FeeType, FeeValueType, NoneValue, ProductFee } from '@models/ProductFee'
import * as yup from 'yup'
import { nonEmptyString } from '../helpers'
import { Builder } from './Builder'
import { ValidationError, isValidationError, validateFromSchema } from './validators/helpers'

export const productFeeSchema: yup.ObjectSchema<ProductFee> = yup.object().shape({
  id: yup.string().defined(),
  name: yup.string().trim('Fee name cannot contain spaces at the beginning or end').defined(),
  farm: yup.object<Pick<Farm, 'id'>>().shape({ id: yup.string().defined() }).defined(),
  type: yup.mixed<FeeType>().defined().oneOf(Object.values(FeeType)),
  valueType: yup.mixed<FeeValueType>().defined().oneOf(Object.values(FeeValueType)),
  value: yup
    .mixed<number | MoneyWithCurrency>()
    .defined()
    .when('type', ([type]) => {
      if (type === FeeType.Tax)
        return YUP_WHOLE_NUMBER_REAL('Value', { allowDecimal: true }).max(1, 'Cannot be greater than 1')
      else if (type === FeeType.Fee) return YUP_MONEY_REQUIRED('Value', { requireCurrency: true })
      throw new Error('Invalid fee type')
    }),
  regionType: yup
    .mixed<ProductFee['regionType']>()
    .defined()
    .oneOf([...Object.values(RegionType), NoneValue]),
  regions: yup
    .array<string[]>()
    .defined()
    .when('regionType', ([regionType], schema) => {
      const message = `Region must be a valid ${
        regionType === RegionType.State ? 'state' : regionType === RegionType.Zipcode ? 'postal code' : 'region'
      }`

      return schema.test('Valid regions', message, function (v?: string[]) {
        if (!Array.isArray(v)) {
          return false
        }
        if (v.length === 0) return true

        return v.every((region) => {
          if (!nonEmptyString(region)) return false

          // We don't know the country so just check that the region is either a valid zip code or state in any country
          if (regionType === RegionType.State) return isValidShortState(region, undefined)
          else if (regionType === RegionType.Zipcode) return isValidZipcode(region, undefined)
          else return this.createError({ message: 'Cannot validate the region because the region type is invalid' })
        })
      })
    }),
  archived: yup.boolean().defined(),
})

export class ProductFeeBuilder extends Builder<ProductFee> {
  constructor() {
    super('productFee')
  }

  build(data: Partial<ProductFee>): ProductFee {
    return this.validate(data)
  }

  validate(productFee: Partial<ProductFee>): ProductFee {
    try {
      return validateFromSchema(productFeeSchema, productFee)
    } catch (error) {
      if (isValidationError(error)) {
        throw new ValidationError({ path: 'productFee.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
