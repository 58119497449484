import { Timestamp } from '@api/encoding/Time'
import { PartialExcept } from '@helpers/typescript'

/** When adding a new payment form the code should be searched and something like the PaymentMethod definitions should be created.
 * Right now it is easy to add a new source but harder to add a new form. */
export enum PaymentForms {
  CARD = 'card',
  CASH = 'cash',
  FARM_CREDIT = 'balance',
  EBT = 'ebt',
  BANK = 'bank_account',
}

export enum EbtCardTypes {
  CASH = 'CS',
  SNAP = 'FS',
}

export enum PaymentSources {
  STRIPE = 'stripe',
  STRIPE_ACH = 'stripe_ach',
  // This allows us to accept both invoices and payments from Stripe as we begin to transition away from invoices
  // This source is now deprecated and should not be used, we only leave it for now to support reporting for Stripe Invoices
  STRIPE_INVOICE = 'stripe_invoice',
  WORLD_PAY_EBT = 'worldpay_ebt',
  //Below two will be used in refund or orderCancel When we need to distinguish ebt payment between ebt cash and ebt snap during refund
  WORLD_PAY_EBT_CASH = 'worldpay_ebtcash',
  WORLD_PAY_EBT_SNAP = 'worldpay_ebtsnap',
  OFFLINE = 'offline',
  FARM_CREDIT = 'farmcredit',
}

export type PaymentMethodBase<Type = PaymentForms, Source = PaymentSources> = {
  id: string
  token: string
  type: Type
  source: Source
  isDefault: boolean
  // Only exist for EBT and Credit card
  card_type?: string
  last4?: string
}

export type CashPaymentMethod = PaymentMethodBase<PaymentForms.CASH, PaymentSources.OFFLINE> & {
  token: PaymentForms.CASH
}

export type FarmCreditPaymentMethod = PaymentMethodBase<PaymentForms.FARM_CREDIT, PaymentSources.FARM_CREDIT> & {
  // This will be the stripe customerRef
  token: string
}

export type CardPaymentMethodBase = PaymentMethodBase<PaymentForms.CARD, PaymentSources.STRIPE> & {
  token: string
  card_type: string
  holder_name?: string
  last4: string
  expiration?: {
    month: number
    year: number
  }
}

export type StripeCardPaymentMethod = CardPaymentMethodBase &
  PaymentMethodBase<PaymentForms.CARD, PaymentSources.STRIPE>

export type BankPaymentMethodBase = PaymentMethodBase<PaymentForms.BANK, PaymentSources.STRIPE_ACH> & {
  token: string
  bank_name: string
  account_type: string
  last4: string
}

export type StripeAchPaymentMethod = BankPaymentMethodBase &
  PaymentMethodBase<PaymentForms.BANK, PaymentSources.STRIPE_ACH>

export type EbtPaymentMethod = PaymentMethodBase<PaymentForms.EBT, PaymentSources.WORLD_PAY_EBT> & {
  token: string
  // High value means it can be used multiple times, low value can only be used once with Raft-API
  tokenType: 'high-value' | 'low-value'
  // Whether to use EBT SNAP (FS) or EBT Cash (CS)
  card_type?: EbtCardTypes
  // If this payment method has been deleted by the customer
  isDeleted?: boolean
  holder_name?: string
  last4: string
  pin?: {
    token: string
    expires: Timestamp
  }
}

export type PaymentMethod =
  | EbtPaymentMethod
  | CardPaymentMethodBase
  | CashPaymentMethod
  | FarmCreditPaymentMethod
  | BankPaymentMethodBase

/** Determine if a payment method is for EBT and cast it if so */
export function isEbtPayment(payMethod: PartialExcept<PaymentMethod, 'type'>): payMethod is EbtPaymentMethod {
  return payMethod.type === PaymentForms.EBT
}

/** Determine if a payment method is for Offline and cast it if so */
export function isCashPayment(payMethod: PartialExcept<PaymentMethod, 'type'>): payMethod is CashPaymentMethod {
  return payMethod.type === PaymentForms.CASH
}

/** Determine if a payment method is for Farm Credit and cast it if so */
export function isFarmCreditPayment(
  payMethod: PartialExcept<PaymentMethod, 'type'>,
): payMethod is FarmCreditPaymentMethod {
  return payMethod.type === PaymentForms.FARM_CREDIT
}

/** Determine if a payment method is for credit card and cast it if so */
export function isCreditPayment(payMethod: PartialExcept<PaymentMethod, 'type'>): payMethod is CardPaymentMethodBase {
  return payMethod.type === PaymentForms.CARD
}

/** Determine if a payment method is for Ach Bank transfer and cast it if so */
export function isAchPayment(payMethod: PartialExcept<PaymentMethod, 'type'>): payMethod is BankPaymentMethodBase {
  return payMethod.type === PaymentForms.BANK
}

// The default cash payment method
export const pmt_CashMethod: CashPaymentMethod = {
  id: PaymentForms.CASH,
  source: PaymentSources.OFFLINE,
  token: PaymentForms.CASH,
  isDefault: false,
  type: PaymentForms.CASH,
}

// The default farm credit payment method for when the user has a farm balance
export const pmt_FarmCredit: FarmCreditPaymentMethod = {
  id: PaymentForms.FARM_CREDIT,
  source: PaymentSources.FARM_CREDIT,
  token: PaymentForms.FARM_CREDIT,
  isDefault: false,
  type: PaymentForms.FARM_CREDIT,
}

/** Will map the payment source processor to the form of payment it is */
export function mapSourceToType(source: PaymentSources) {
  switch (source) {
    case PaymentSources.FARM_CREDIT:
      return PaymentForms.FARM_CREDIT
    case PaymentSources.STRIPE:
      return PaymentForms.CARD
    case PaymentSources.STRIPE_ACH:
      return PaymentForms.BANK
    case PaymentSources.STRIPE_INVOICE:
      return PaymentForms.CARD
    case PaymentSources.OFFLINE:
      return PaymentForms.CASH
    case PaymentSources.WORLD_PAY_EBT:
    case PaymentSources.WORLD_PAY_EBT_CASH:
    case PaymentSources.WORLD_PAY_EBT_SNAP:
      return PaymentForms.EBT
  }
}
