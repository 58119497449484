import { ShortStateCanada, stateHelpersCA } from '@/assets/data/statesCanada'
import { capitalizeFirst, getPostalCodeKind, getStateKind } from '@helpers/display'
import { CountryCode, countryCodes } from '@helpers/international/types'
import { Address, ShortZip } from '@models/Address'
import { PostalCodeCanada } from '@models/AddressCanada'
import * as yup from 'yup'
import { ShortState, stateHelpersUS } from '../../../../assets/data/states'
import { AddressSchemaContext } from '../AddressBuilder'
import { coordinateSchema } from './coordinateSchema'

/** Validates a country code */
export const CountryCodeSchema = yup
  .string<CountryCode>()
  .required('Country is required')
  .oneOf(countryCodes, 'Invalid country')

/** Object that holds the schemas for the generic address fields. This can be used when the individual field schemas must be accessed independently of the generic address schema and when it's not important to validate the zipcode and state based on the country field */
export const addressFieldsGenericSchemas = {
  coordinate: coordinateSchema,
  street1: yup.string().label('Street 1').min(3).required('Street1 is required'),
  street2: yup.string().label('Street 2').optional(),
  city: yup.string().label('City').min(2).required('City is required'),
  state: yup.string().label('State').min(2).required('State is required'),
  zipcode: yup.string().label('Postal code').min(5).required('Postal code is required'),
  country: CountryCodeSchema,
} as const

/** Validates a US zip code */
export const ZipCodeSchema = yup
  .string<ShortZip>()
  .label('Zip code')
  .required('Zip code is required')
  // Start at the first character in the string (^), it should match exactly 5 numeric digits, and then the end of the string ($)
  .matches(/^\d{5}$/, 'Must be a 5-digit US zip code')

/** Options for validating a state */
type StateSchemaOpts = {
  /** This value may be used to customize error messages for various purposes.
   * - 'data' means the schema is being used to validate an address in any situation other than the user interface. In this case the error message will be very specific in regards to the 2-character state code which is expected of the data model.
   * - 'picker' means the schema is being used to validate the address in a picker form component, meaning a user will immediately see any error messages produced. In this case the error message will mention the state is invalid but won't specifically say it expects a 2-character state code because the form would allow them to simply choose from a picker.
   */
  errorMsgMode?: 'data' | 'picker'
  /** When true the state will match in a case insensitive way, and the valid matches will also include the state full name as opposed to just the abbreviation. This is meant to be used in a situation where the form input must be a freehand input in which the user could enter whatever they want as opposed to a state picker where their options are predefined */
  isFreeInput?: boolean
}

/** Validates a US state code */
export const ShortStateSchema = ({ errorMsgMode = 'data', isFreeInput = false }: StateSchemaOpts) =>
  yup
    .string<ShortState, AddressSchemaContext>()
    .label('State')
    .required('State is required')
    .test(
      'value',
      errorMsgMode === 'picker' ? 'Must be a valid US state' : 'State must be a valid 2-letter US state code',
      (val) => {
        const valuesToMatch = isFreeInput
          ? // In free input it can match any state name or abbreviation, case insensitive
            stateHelpersUS.stateItemList().flatMap(({ label, value }) => [label.toLowerCase(), value.toLowerCase()])
          : // Otherwise it must match the state code perfectly
            stateHelpersUS.listStateAbbr()

        return valuesToMatch.includes(isFreeInput ? val.toLowerCase() : val)
      },
    )

/** Validates a canadian postal code */
export const PostalCodeSchemaCanada = yup
  .string<PostalCodeCanada>()
  .label('Postal code')
  .required('Postal code is required')
  /** This schema only allows upper case alphabetic characters in the postal code because that's correct for the data.
   * - For a good user experience, the form should have a component that automatically transforms the user's text into upper case, so they can enter text in whichever case they want and they'll see it converted to upper case. Then this error would not fire as a false alarm */
  .matches(/^[A-Z]\d[A-Z]\d[A-Z]\d$/, 'Postal code must be a valid Canadian postal code in the format "A1A1A1"')

/** Validates a canadian province/ state */
export function ShortStateSchemaCanada({
  errorMsgMode,
  isFreeInput = false,
}: StateSchemaOpts): yup.StringSchema<Address['state'], AddressSchemaContext> {
  return yup
    .string<ShortStateCanada, AddressSchemaContext>()
    .label('Province')
    .required('Province is required')
    .test(
      'value',
      errorMsgMode === 'picker'
        ? 'Province must be a valid Canadian province'
        : 'Province must be a valid 2-letter Canadian province code',
      (val) => {
        const valuesToMatch = isFreeInput
          ? // In free input it can match any state name or abbreviation, case insensitive
            // A form using this schema with a regular input is still expected to handle converting the string to upper case before saving to the DB
            stateHelpersCA.stateItemList().flatMap(({ label, value }) => [label.toLowerCase(), value.toLowerCase()])
          : // Otherwise it must match a state code perfectly
            stateHelpersCA.listStateAbbr()

        return valuesToMatch.includes(isFreeInput ? val.toLowerCase() : val)
      },
    )
}

/** Will validate the postal code based on the country in context, or the country field */
export const ZipCodeSchemaCountry: yup.StringSchema<Address['zipcode'], AddressSchemaContext> = yup
  .string<Address['zipcode'], AddressSchemaContext>()
  .when(['country', '$country'], ([countryFieldVal, countryCtxVal], schemaArg) => {
    /** This will validate the postal code based on the country. The country can be obtained either from context or from the country field. Context should be preferred if available; country field will be used as fallback. At least one of them must be defined in order to validate the postal code */

    const country = (countryCtxVal || countryFieldVal) as CountryCode

    const schema = schemaArg.required(`${capitalizeFirst(getPostalCodeKind(country))} is required`)

    switch (country) {
      case 'CA':
        return schema.concat(PostalCodeSchemaCanada)
      case 'US':
        return schema.concat(ZipCodeSchema)
      default:
        return schema.typeError('Cannot validate zipcode with an invalid country')
    }
  })
  /** This ".required()" will not activate because the schema inside the ".when()" already has the ".required()" and it will have a personalized error message for the country-based state kind. However it is here so the final schema can conform with the expected type signature.
   * - It should be after everything else because the correct .require() should engage before this one. */
  .required()

/** Will validate the state based on the country in context, or the country field */
export function StateSchemaCountry(opts: StateSchemaOpts): yup.StringSchema<Address['state'], AddressSchemaContext> {
  return (
    yup
      .string<Address['state'], AddressSchemaContext>()
      .when(['country', '$country'], ([countryFieldVal, countryCtxVal], schemaArg) => {
        /** This will validate the state based on the country. The country can be obtained either from context or from the country field. Context should be preferred if available; country field will be used as fallback. At least one of them must be defined in order to validate the state */

        const country = (countryCtxVal || countryFieldVal) as CountryCode

        const schema = schemaArg.required(`${capitalizeFirst(getStateKind(country))} is required`)

        switch (country) {
          case 'CA':
            return schema.concat(ShortStateSchemaCanada(opts))
          case 'US':
            return schema.concat(ShortStateSchema(opts))
          default:
            return schema.typeError('Cannot validate state with an invalid country')
        }
      })
      /** This ".required()" will not activate because the schema inside the ".when()" already has the ".required()" and it will have a personalized error message for the country-based state kind. However it is here so the final schema can conform with the expected type signature.
       * - It should be after everything else because the correct .require() should engage before this one.
       */
      .required(`${capitalizeFirst(getStateKind('US'))} is required`)
  )
}
