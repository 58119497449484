import { useParamsConverter } from '@/hooks/useParamsConverter/useParamsConverter'
import { withConsumerIndexHooks } from '@/hooks/withAlgoliaIndex'
import { withWholesaleAuth } from '@/hooks/withWholesaleAuth'
import { HomeParamList, SearchScreenParams } from '@/navigation/types'
import { internationalSelector } from '@/redux/selectors'
import { getShortState, isValidZipcode, parsePostalCode } from '@helpers/address'
import { parseCoordString } from '@helpers/coordinate'
import { nonEmptyString } from '@helpers/helpers'
import { CountryCode } from '@helpers/international/types'
import { includes, keys } from '@helpers/typescript'
import { RouteProp, useRoute } from '@react-navigation/native'
import { createContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SearchScreenUi } from './SearchScreenUi'
import { useAlgoliaSearchData } from './hooks/useAlgoliaSearchData'
import { useSetInitialCenter } from './hooks/useSetInitialCenter'
import { useSetInitialRadius } from './hooks/useSetInitialRadius'
import { useSetInitialRegion } from './hooks/useSetInitialRegion'
import { LocType, locTypeToDisplayMap } from './searchScreen-helpers'

export type ContextData = {
  searchLayer: ReturnType<typeof useAlgoliaSearchData>
}

export const SearchScreenContext = createContext<ContextData>({
  searchLayer: {} as ReturnType<typeof useAlgoliaSearchData>,
})

/** Parses the region param into a ShortState or ShortZip if valid */
function parseRegionParam(regionParam: string, country: CountryCode, locType?: LocType): string | undefined {
  if (!nonEmptyString(regionParam)) return undefined

  if (locType === 'state') {
    const state = getShortState(regionParam, country)
    if (state) return state
  } else if (locType === 'zip') {
    const isValidZip = isValidZipcode(parsePostalCode(regionParam), country)

    if (isValidZip) return parsePostalCode(regionParam)
  }

  return undefined
}

const paramsToParse: (keyof SearchScreenParams)[] = [
  'priceLow',
  'priceHigh',
  'orderMinLow',
  'orderMinHigh',
  'radius',
  'isGlobal',
]

function SearchScreenComp() {
  const { params = {} } = useRoute<RouteProp<HomeParamList, 'SearchScreen'>>()
  const { country } = useSelector(internationalSelector)

  useParamsConverter(paramsToParse)

  useSetInitialCenter(params.locType, params.center, params.isGlobal)
  useSetInitialRadius(params.locType, params.radius)
  useSetInitialRegion(params.locType, params.region, params.isGlobal)

  /** These coords are intended to be the correct source of truth for the coords currently used anywhere in the search screen */
  const coords = useMemo(() => parseCoordString(params.center || '') ?? undefined, [params.center])

  const isValidLocType = includes(keys(locTypeToDisplayMap), params.locType)

  /** Numeric params must be converted to number type, because after refresh, all params will be strings */
  const searchLayer = useAlgoliaSearchData({
    categoryId: params.categoryId,
    orderMinHigh: params.orderMinHigh,
    orderMinLow: params.orderMinLow,
    practices: params.practices,
    stringSearchValue: params.q,
    priceLow: params.priceLow,
    priceHigh: params.priceHigh,
    center: coords,
    radius: params.radius,
    locType: isValidLocType ? params.locType : undefined,
    region: parseRegionParam(params.region || '', country, params.locType),
    farm: params.farm,
  })

  const value = useMemo<ContextData>(() => ({ searchLayer, coords }), [searchLayer, coords])

  return (
    <SearchScreenContext.Provider value={value}>
      <SearchScreenUi />
    </SearchScreenContext.Provider>
  )
}

export const SearchScreen = withWholesaleAuth(withConsumerIndexHooks(SearchScreenComp))
