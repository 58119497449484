import { Logger } from '@/config/logger'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { getInitialMapFilters } from '@screens/Explore/types'
import React, { memo, useCallback } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { LogoResponsive } from '../../admin/components/LogoResponsive'
import Colors from '../../constants/Colors'
import { globalStyles } from '../../constants/Styles'
import { setMapFilters, setSearchLocation } from '../../redux/actions/appState'
import {
  isAdminSelector,
  searchLocationSelector,
  sessionLocationSelector,
  userSelector,
  wholesaleSelector,
} from '../../redux/selectors'
import { ConsumerSearch } from '../ConsumerSearch'
import HamburgerMenu from '../HamburgerMenu'
import { ShoppingCart } from '../ShoppingCart'
import { UserDropdown } from '../UserDropdown'
import { Button } from '../elements/Button'
import { ButtonClear } from '../elements/ButtonClear'
import { makeHeaderTestId, useCreateSearchParams } from './Header-helper'

import { useCartService } from '@/hooks/useCart'
import { useLayoutFnStyles } from '@/hooks/useFnStyles'
import { spreadStyle } from '@/hooks/useMergeStyle'
import { openUrl } from '@helpers/client'
import { getFarmCartItems } from '@models/Cart'
import { SearchScreenHeaderComp } from '@screens/SearchScreen/components/SearchScreenHeaderComp/SearchScreenHeaderComp'
import { MAX_SHOP_WIDTH } from '@screens/Shopping/FarmShop/helpers'
import { COOP_WEB_ONBOARD_URL } from '@shared/BaseUrl'
import { useVisibleComponents } from './useVisibleComponents'

type HeaderProps = {
  /** Whether the safe area margin should be disabled. This should be used when the safe area margin is being handled through a container, for example when rendering the header from inside the screen component as opposed to from the navigator. */
  noSafeareaMargin?: boolean
}

/**
 * The main app header, intended for large devices.
 * - Since it is sometimes rendering at the navigator level, and sometimes directly inside the screen component, it's better for it to not rely on the StackHeaderProps, and instead always useNavigation.
 */
export const Header = memo(function Header({ noSafeareaMargin = false }: HeaderProps) {
  //StackNavigationProp<T> is "any" because this could be used in many different screens with different possible routes
  const navigation = useNavigation<StackNavigationProp<any>>()
  const route = useRoute()
  const key = route.key //The key is the only way a UI testing library can select the Header of a particular screen, because in a stack navigator, there will be a Header for each screen, in the DOM.
  const dispatch = useDispatch()
  const visibility = useVisibleComponents()
  const { isWholesale = false } = useSelector(wholesaleSelector)

  const user = useSelector(userSelector)
  const isAdmin = useSelector(isAdminSelector)
  const { cart } = useCartService()

  const searchLoc = useSelector(searchLocationSelector)
  const sessionLoc = useSelector(sessionLocationSelector)

  const styles = useStyles(noSafeareaMargin, isWholesale)

  const goAdmin = useCallback(() => {
    if (user.id && isAdmin === undefined) {
      Logger.debug('Still loading farmer status')
    } else if (isAdmin) {
      navigation.navigate('AdminDrawerNavigator')
    } else {
      openUrl(COOP_WEB_ONBOARD_URL)
    }
  }, [user.id, isAdmin, navigation])

  const createSearchParams = useCreateSearchParams()

  const onExplorePress = useCallback(() => {
    dispatch(setMapFilters(getInitialMapFilters(isWholesale))) //resets filters when pressing this button
    const loc = searchLoc ?? sessionLoc
    if (loc) dispatch(setSearchLocation({ ...loc }))

    //Only navigate directly if destination is available to current navigator
    if (navigation.getState().routeNames.includes('ExploreScreen')) {
      navigation.navigate('ExploreScreen', createSearchParams({ coords: loc?.coordinate }))
    } else {
      //Else specify nested navigator path
      navigation.navigate('Consumer', {
        screen: 'Home',
        params: {
          screen: 'ExploreScreen',
          params: createSearchParams({ coords: loc?.coordinate }),
        },
      })
    }
  }, [createSearchParams, sessionLoc, dispatch, navigation, searchLoc, isWholesale])

  const logoPress = useCallback(
    () =>
      navigation.navigate('Consumer', {
        screen: 'Home',
        params: { screen: 'HomeScreen' },
      }),
    [navigation],
  )

  return (
    <View style={styles.main} testID={makeHeaderTestId(key)}>
      <View style={styles.header}>
        <View style={globalStyles.flexRowCenter}>
          <HamburgerMenu clear />
          <LogoResponsive white style={globalStyles.padding10} onPress={logoPress} />
          {visibility.exploreBtn && (
            <ButtonClear
              style={styles.exploreBtn}
              color={Colors.white}
              title="Explore"
              onPress={onExplorePress}
              icon="map"
            />
          )}
        </View>

        <View style={globalStyles.flex1}>
          {visibility.consumerSearch ? (
            <View style={styles.consumerSearchCont}>
              <ConsumerSearch />
            </View>
          ) : (
            visibility.searchScreenInput && <SearchScreenHeaderComp />
          )}
        </View>

        <View style={styles.rightContent}>
          {visibility.adminBtn && (
            <ButtonClear
              color={Colors.white}
              title={isAdmin ? 'Farmer Dashboard' : 'Start selling on GrownBy'}
              onPress={goAdmin}
              testID="header_farmer_text"
            />
          )}
          <UserDropdown />
          {visibility.checkoutBtn && (
            <Button
              style={styles.checkoutBtn}
              outline
              title={`Checkout (${getFarmCartItems({ items: cart, isWholesale }).length})`}
              icon="shopping-cart"
              url="/farms/shop/my-cart"
              testID="header-cart-large"
            />
          )}
          {visibility.shoppingCartBtn && <ShoppingCart color={Colors.white} testID="header-cart-small" />}
        </View>
      </View>
    </View>
  )
})

const useStyles = (noSafeareaMargin: boolean, isWholesale: boolean) =>
  useLayoutFnStyles(
    ({ isMedDevice, isSmallDevice, top }, noSafeareaMargin, isWholesale) => ({
      main: spreadStyle([
        {
          backgroundColor: isWholesale ? Colors.purple : Colors.darkGreen,
          alignItems: 'center',
        },
        !noSafeareaMargin && { marginTop: top },
      ]),
      header: {
        flexDirection: 'row',
        paddingVertical: isSmallDevice || isMedDevice ? 0 : 10,
        paddingHorizontal: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        elevation: 10,
        maxWidth: MAX_SHOP_WIDTH,
        width: '100%',
        gap: 20,
      },
      avatar: {
        width: isSmallDevice || isMedDevice ? 50 : 60,
        height: isSmallDevice || isMedDevice ? 50 : 60,
        borderWidth: 5,
        borderRadius: 40,
        borderColor: Colors.transparent,
      },
      onHover: {
        borderColor: Colors.green,
      },
      link: {
        color: Colors.black,
        padding: 10,
        backgroundColor: Colors.transparent,
      },
      activeLink: {
        color: Colors.white,
        padding: 10,
        backgroundColor: Colors.green,
        overflow: 'visible',
      },
      checkoutBtn: { backgroundColor: Colors.white },
      consumerSearchCont: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: 700,
        flex: 1, // Hotfix for mobile (tablet) until ConsumerSearch styles are refactored
      },
      rightContent: {
        marginRight: 10,
        flexDirection: 'row',
        alignItems: 'center',
      },

      exploreBtn: {
        marginLeft: 30,
      },
      adminBtn: { justifyContent: 'center', flex: 1, flexGrow: 1 },
    }),
    noSafeareaMargin,
    isWholesale,
  )
