import { addLocation, snapshotDelivery } from '@api/Locations'
import { ToolTips } from '@components'
import {
  ErrorText,
  FormInput,
  FormInputLabel,
  FormMoneyInput,
  KeyboardAvoidingScrollView,
  Text,
  Toast,
} from '@elements'
import { errorToString } from '@helpers/helpers'
import { getZero } from '@helpers/money'
import { FeeWaiveOptionType, LocationTypes, NonPickup, NonPickupLocationTypes, RegionType } from '@models/Location'
import { Money } from '@models/Money'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import LoaderWithMessage from '../../../components/LoaderWithMessage'
import Colors from '../../../constants/Colors'
import { withAdminAuth } from '../../../hooks/withAdminAuth'
import { setAdminNav } from '../../../redux/actions/adminState'
import { adminCurrencySelector, adminFarmIdSelector, adminFarmSelector } from '../../../redux/selectors'
import { AdminView } from '../../components/AdminView'
import { EditHeader } from '../../components/EditHeader'
import { LocationsAndZonesParamList } from '../../navigation/types'
import { StateZipInput } from './StateZipsInput'
import { ReturnStates, validateDeliveryShippingEdit } from './editValidation'

import { Logger } from '@/config/logger'
import { useSnapshot } from '@/hooks/useSnapshot'
import { locationsCollection } from '@api/framework/ClientCollections'
import { AccessRight, Permission } from '@helpers/Permission'
import { getPostalCodeKind, getStateKind, plural } from '@helpers/display'
import { getSchemaDeliveryShipping } from './helpers'

type FormType = {
  type: NonPickupLocationTypes
  locationName: string
  abbreviation?: string
  regions: NonPickup['regions']
  cost?: Money
  feeWaiveOption: FeeWaiveOptionType
}

const dynamicLocationText = (type?: LocationTypes) => {
  if (type === LocationTypes.Shipping) return 'Shipping'
  return 'Delivery'
}

/** Screen component dedicated to adding or editing nonpickup farm locations */
function AddDeliveryShippingScreen() {
  const navigation = useNavigation<StackNavigationProp<LocationsAndZonesParamList, 'AddDeliveryShipping'>>()
  const dispatch = useDispatch()
  const { params } = useRoute<RouteProp<LocationsAndZonesParamList, 'AddDeliveryShipping' | 'EditDeliveryShipping'>>()
  const farmId = useSelector(adminFarmIdSelector)
  const farm = useSelector(adminFarmSelector)

  const isEdit = !!params?.id
  const locationType = params?.type || LocationTypes.Delivery

  const {
    data: editLocation,
    loading,
    error,
  } = useSnapshot('snapshotDelivery', snapshotDelivery, [params?.id, farmId], isEdit, {
    onStateChange({ data }) {
      dispatch(setAdminNav({ location: data }))
    },
  })

  /** Sets form initial values if a location is being edited */
  const initialValues: FormType = useMemo(() => {
    if (isEdit && editLocation) {
      return {
        locationName: editLocation.name,
        abbreviation: editLocation.abbreviation ?? '',
        type: editLocation.type,
        cost: editLocation.cost,
        regions: editLocation.regions,
        feeWaiveOption: FeeWaiveOptionType.None,
      }
    } else
      return {
        type: params?.type || LocationTypes.Delivery,
        locationName: '',
        abbreviation: '',
        cost: undefined,
        regions: [],
        feeWaiveOption: FeeWaiveOptionType.None,
      }
  }, [editLocation, isEdit, params?.type])

  const handleSubmitLocation = useCallback(
    async function handleSubmitLocation(values: FormType, { resetForm }: FormikHelpers<FormType>) {
      try {
        const type: LocationTypes = values.type

        // Set values from the form
        const newLocation: NonPickup = {
          id: editLocation?.id || '',
          farm: { id: farm?.id, urlSafeSlug: farm?.urlSafeSlug },
          name: values.locationName,
          abbreviation: values.abbreviation,
          type,
          timezone: editLocation?.timezone || farm.timezone,
          cost: values.cost ?? getZero(currency),
          regions: values.regions,
          feeWaiveOption: values.feeWaiveOption,
        }
        if (isEdit) {
          if (!editLocation) {
            throw new Error(
              'The form is wrongly allowing the user to edit and submit changes while the existing location was not loaded',
            )
          }

          let result: ReturnType<typeof validateDeliveryShippingEdit>

          try {
            // Check the data is valid and we are actually updating something and warn the farmer of any important changes
            result = validateDeliveryShippingEdit(editLocation, newLocation)
          } catch (error) {
            // This is meant to catch validation errors
            return Toast(errorToString(error))
          }

          switch (result.status) {
            case ReturnStates.GO_BACK:
              Toast('Nothing to update, going back')
              break
            case ReturnStates.DO_NOTHING:
              return // return here so we don't go back if something requires more attention or a popup was cancelled
            case ReturnStates.SAVE: {
              // If the farmer agrees and there are changes then proceed to update
              if (result.status === ReturnStates.SAVE) {
                await locationsCollection.update(result.data)
                Toast('Location was updated')
              }
              break
            }
            default: {
              Toast('Something unexpected happened while validating your edit')
              break
            }
          }
        } else {
          await addLocation(newLocation, farm)
          Toast('Location was added')
        }
        resetForm()
        navigation.navigate('Locations')
      } catch (error) {
        Logger.error(error)
        Toast('Something went wrong while handling your request')
      }
    },
    [editLocation, farm, isEdit, navigation],
  )

  const isDeliveryAction = editLocation?.type === LocationTypes.Delivery || locationType === LocationTypes.Delivery

  const schema: Yup.ObjectSchema<FormType> = useMemo(
    () => getSchemaDeliveryShipping(locationType, farm),
    [farm, locationType],
  )

  const isFarmLoaded = !!farmId && farmId === farm.id

  const isEditDataLoaded = !loading && !error && !!editLocation

  const isScreenReady = isFarmLoaded && (!isEdit || isEditDataLoaded)

  const currency = useSelector(adminCurrencySelector)

  return (
    <AdminView>
      {isScreenReady ? (
        <Formik<FormType>
          validationSchema={schema}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmitLocation}
        >
          {({
            touched,
            errors,
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }: FormikProps<FormType>) => (
            <>
              <EditHeader
                goBack={() => navigation.navigate('Locations')}
                title={
                  isEdit && !!editLocation
                    ? `Edit ${dynamicLocationText(editLocation.type)} Zone - ${editLocation.name}`
                    : `Add ${dynamicLocationText(locationType)} Zone`
                }
                actionTitle="Save"
                isLoading={isSubmitting}
                onPress={handleSubmit}
                toolTipId={isDeliveryAction ? ToolTips.DELIVERY_ZONE : undefined}
              />
              <View style={styles.formWrapper}>
                <KeyboardAvoidingScrollView>
                  <FormInput
                    onChangeText={handleChange('locationName')}
                    value={values.locationName}
                    onBlur={handleBlur('locationName')}
                    label={`${dynamicLocationText(values.type)} Zone Name`}
                    errorMessage={touched.locationName ? errors.locationName : ''}
                  />
                  <FormInput
                    onChangeText={(val) => setFieldValue('abbreviation', val.toUpperCase())}
                    placeholder="LOC1"
                    maxLength={4}
                    onBlur={handleBlur('abbreviation')}
                    value={values.abbreviation}
                    errorMessage={touched.abbreviation ? errors.abbreviation : ''}
                    label={<FormInputLabel label="Label Abbreviation" />}
                    helperText="A short version of the location name for packing labels."
                  />
                  <FormMoneyInput
                    maxLength={11}
                    label={
                      <FormInputLabel
                        label={isDeliveryAction ? 'Delivery fee' : 'Shipping fee (Optional)'}
                        tooltipId={isDeliveryAction ? ToolTips.DELIVERY_FEE : ToolTips.SHIPPING_FEE}
                      />
                    }
                    placeholder="$0.00"
                    onBlur={handleBlur('cost')}
                    value={values.cost}
                    onChangeText={(value) => setFieldValue('cost', value)}
                    errorMessage={touched.cost && !!errors.cost ? (errors.cost as string) : ''}
                    currency={currency}
                  />
                  {/* Temporarily hiding the feeWaiveOption from UI until the functionality is implemented. In the meantime, option "None" should be saved with the location. */}
                  {/* <View style={styles.pickerWrapper}>
                    <CustomInput
                      label="Fee waive option"
                      errorMessage={touched.feeWaiveOption ? errors.feeWaiveOption : ''}
                      InputComponent={forwardRef(() => (
                        <DropDownPicker
                          style={{ flex: 1, margin: 10 }}
                          inputStyle={{ fontSize: fontSize(14, 2) }}
                          value={values.feeWaiveOption}
                          minimal
                          noPlaceholder
                          onValueChange={handleChange('feeWaiveOption')}
                          items={[
                            {
                              label: FeeWaiveOptionType.None,
                              value: FeeWaiveOptionType.None,
                            },
                            {
                              label: FeeWaiveOptionType.PartialEBT,
                              value: FeeWaiveOptionType.PartialEBT,
                            },
                            {
                              label: FeeWaiveOptionType.FullEBT,
                              value: FeeWaiveOptionType.FullEBT,
                            },
                          ]}
                        />
                      ))}
                    />
                  </View> */}

                  <StateZipInput
                    country={farm.address.country}
                    helperText={
                      values.type === LocationTypes.Delivery
                        ? `Any ${plural(2, getPostalCodeKind(farm.address.country))} you deliver to`
                        : `Any ${plural(2, getStateKind(farm.address.country))} you will ship to`
                    }
                    values={values.regions}
                    label={
                      <FormInputLabel
                        label={`Enter ${
                          values.type === LocationTypes.Shipping
                            ? // "Enter 2 letter state/province code"
                              `2-letter ${getStateKind(farm.address.country)} code`
                            : // "Enter zipcode/postal code"
                              `${getPostalCodeKind(farm.address.country)}`
                        }`}
                        tooltipId={
                          values.type === LocationTypes.Shipping ? ToolTips.ADD_SHIPPING_ZONE : ToolTips.ENTER_ZIP_CODE
                        }
                      />
                    }
                    onUpdate={(val) => {
                      if (values.regions.length >= 600) {
                        Toast('You can enter a maximum of 600 regions')
                        return
                      }
                      setFieldValue('regions', val)
                    }}
                    type={values.type === LocationTypes.Delivery ? RegionType.Zipcode : RegionType.State}
                  />
                  {touched.regions && !!errors.regions && <ErrorText>{errors.regions}</ErrorText>}
                </KeyboardAvoidingScrollView>
              </View>
            </>
          )}
        </Formik>
      ) : (
        <>
          <EditHeader
            goBack={() => navigation.navigate('Locations')}
            title={`Edit ${dynamicLocationText(locationType)} Zone`}
            actionTitle="Back"
            onPress={() => navigation.navigate('Locations')}
            toolTipId={isDeliveryAction ? ToolTips.DELIVERY_ZONE : undefined}
          />
          <LoaderWithMessage
            loading={loading}
            icon="map-marked"
            title={`${dynamicLocationText(locationType)} not found`}
          >
            <Text>
              {!farmId || farmId !== farm.id
                ? 'Loading the farm data'
                : error ||
                  `This ${dynamicLocationText(
                    locationType,
                  )} could not be loaded, please click the "X" in the upper right corner and go back and select a location from the list.`}
            </Text>
          </LoaderWithMessage>
        </>
      )}
    </AdminView>
  )
}

const styles = StyleSheet.create({
  headerCont: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades[100],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  formWrapper: {
    marginVertical: 20,
    width: '90%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  pickerWrapper: {
    width: '50%',
  },
  marginBottom: {
    marginBottom: 5,
  },
})
export default withAdminAuth(AddDeliveryShippingScreen, Permission.ProductSetup, AccessRight.Edit)
