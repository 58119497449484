import { YUP_MONEY_OPTIONAL } from '@helpers/Yup'
import { getShortState, isValidZipcode } from '@helpers/address'
import { getPostalCodeKind, getStateKind, plural } from '@helpers/display'
import { nonEmptyString } from '@helpers/helpers'
import { Farm } from '@models/Farm'
import { FeeWaiveOptionType, LocationTypes, NonPickup, NonPickupLocationTypes } from '@models/Location'
import { Money } from '@models/Money'
import * as Yup from 'yup'

export const locAbbreviationSchema = Yup.string()
  .trim()
  .label('Label Abbreviation')
  .optional()
  .max(4, 'Abbreviation must be 4 characters or less')
  .matches(/^[a-zA-Z0-9]+$/, 'Only letters and numbers are allowed')

/** Returns the schema for the addDeliveryShipping screen */
export function getSchemaDeliveryShipping(
  locationType: LocationTypes,
  farm: Farm,
): Yup.ObjectSchema<
  {
    type: NonPickupLocationTypes
    locationName: string
    abbreviation?: string
    cost?: Money
    regions: NonPickup['regions']
    feeWaiveOption: FeeWaiveOptionType
  },
  Yup.AnyObject,
  {
    type: undefined
    locationName: undefined
    abbreviation: undefined
    cost: any
    regions: undefined
    feeWaiveOption: undefined
  },
  ''
> {
  return locationType === LocationTypes.Delivery
    ? Yup.object().shape({
        type: Yup.string<NonPickupLocationTypes>()
          .oneOf([locationType === LocationTypes.Delivery ? LocationTypes.Delivery : LocationTypes.Shipping])
          .required(),
        locationName: Yup.string().trim().label('Location Name').required(),
        abbreviation: locAbbreviationSchema,
        cost: YUP_MONEY_OPTIONAL('cost', { allowZero: true, requireCurrency: true }),
        regions:
          locationType === LocationTypes.Delivery
            ? Yup.array()
                .required()
                .min(1, `You must select at least one ${getPostalCodeKind(farm.address.country)}`)
                .test(
                  'Valid postal codes',
                  `One or more ${getPostalCodeKind(farm.address.country)} are not valid for the farm country`,
                  (v) => {
                    return !!v && v.every((code) => nonEmptyString(code) && isValidZipcode(code, farm.address.country))
                  },
                )
                .test(
                  'max600',
                  `You can enter a maximum of 600 ${plural(2, getPostalCodeKind(farm.address.country))}`,
                  (v) => !!v && v.length <= 600,
                )
                .required()
            : Yup.array()
                .required()
                .min(1, `You must select at least one ${getStateKind(farm.address.country)}`)
                .test(
                  'Valid states',
                  `One or more ${getStateKind(farm.address.country)} are not valid for the farm country`,
                  (v) => {
                    return !!v && v.every((s) => nonEmptyString(s) && !!getShortState(s, farm.address.country))
                  },
                ),
        feeWaiveOption: Yup.mixed<FeeWaiveOptionType>().oneOf(Object.values(FeeWaiveOptionType)).required(),
      })
    : Yup.object().shape({
        type: Yup.string<NonPickupLocationTypes>().required().oneOf([LocationTypes.Shipping]),
        locationName: Yup.string().trim().label('Location Name').required(),
        abbreviation: locAbbreviationSchema,
        cost: YUP_MONEY_OPTIONAL('cost', { allowZero: true, requireCurrency: true }),
        regions: Yup.array()
          .required()
          .min(1, 'You must select at least one state')
          .test('Valid states', `One or more regions are not valid for the farm country`, (v) => {
            return !!v && v.every((s) => nonEmptyString(s) && !!getShortState(s, farm.address.country))
          }),
        feeWaiveOption: Yup.mixed<FeeWaiveOptionType>().oneOf(Object.values(FeeWaiveOptionType)).required(),
      })
}
