import { DateTime } from 'luxon'

import { CountryCode } from '@helpers/international/types'
import { Address } from './Address'
import { Commodity } from './Commodity'
import { Features } from './Features'
import { Money } from './Money'
import { Timezone } from './Timezone'
import { FarmAssociation, User } from './User'
import { Media } from './shared/Media'

export enum FarmStatus {
  /** This farm shows up on searches */
  Listed = 'Listed',
  /** This farm has been claimed and is being approved by admin */
  Pending = 'Pending',
  /** This farm has been successfully claimed */
  Claimed = 'Claimed',
  /** This farm is pending stripe verification */
  PendingStripe = 'PendingStripe',
  /** This farm needs to provide more info to stripe to begin selling */
  NeedInfoStripe = 'NeedInfoStripe',
  /** This farm is all setup and ready to be shown */
  Registered = 'Registered',
  /** This farm has been removed from GrownBy */
  Inactive = 'Inactive',
}

/** Steps the farm needs to take to be onboarded */
export enum OnboardSteps {
  CLAIM = 'claim',
  FARM_PROFILE = 'farm_profile',
  PAYMENTS = 'payments',
  EBT = 'ebt',
  LOCATIONS_ZONES = 'locations_zones',
  SCHEDULES = 'schedules',
  PRODUCTS = 'products',
  REVIEW_SHOP = 'review_shop',
  SHARE_FARM = 'share_farm',
}

export type ManagerType = {
  user: Pick<User, 'id' | 'name' | 'notifications' | 'email' | 'notificationToken'>
  farmAssociation: Pick<FarmAssociation, 'role' | 'customPermissions'>
}

export type Farm = {
  id: string
  name: string
  // keeps track of all completed steps on the farm
  onboardSteps?: Partial<Record<OnboardSteps, boolean>>

  // A url for the farms logo
  logo?: string
  nameUppercase?: string
  email?: string
  phoneNumber?: string
  website?: string
  about: string

  // Text to be displayed to user on how to handle offline payments
  offlinePayments?: string

  // When the farm accepted the terms of service
  acceptedTerms?: DateTime

  /** The farm address.
   * - Changing the country is not allowed */
  address: Address

  /** Properties is used to store farm tags */
  properties: Properties

  socialmedia?: {
    twitter?: string
    instagram?: string
    facebook?: string
  }

  status: FarmStatus

  // Stores the previous farm status when setting farm status as 'Inactive'
  previousStatus?: FarmStatus

  pricingModel?: {
    // Should be a percent, so 2% would be displayed as 2
    appFeePercent?: number
    /** Hosting fee is not implemented yet, but will provide a way to charge farms monthly platform fees */
    hostingFee?: number
  }
  reviews?: {
    numRatings: number
    rating: number
  }
  numFavorites: number

  localBaseUnits?: string[]
  localCategories?: string[]
  localCommodities?: Commodity[]
  localProducers?: string[]

  // Stripe Connect account ID.
  accountRef: string

  // The users able to manage the farm.
  managers?: ManagerType[]

  // verificationCode is assigned when a user is attempting to claim a farm.
  verificationCode?: VerificationCode

  joinDate?: DateTime

  // If the farm is a member of the coop their ID can be stored here
  memberId?: string

  // The types of payments the farm accepts.
  paymentTypes: {
    card: boolean
    cash: boolean
    /** FIXME: Check is really the same as ACH, so we will use check for ACH, however it should be renamed to bank in the future */
    check: boolean
    ebt: boolean
  }

  /** EBT WorldPay Merchant ID.
   * FIXME: This should go under the ebt property. Needs migration due to bad planning of model design.
   */
  worldPayMerchantId?: string

  ebt?: {
    fnsOnline: string
  }

  tipsAndFees?: {
    showTipOption: boolean
    customerChooseToPayFees: boolean
  }

  media: Media[]

  // List of ID's in certification
  practices: string[]

  // The name of the timezone from the tz database that the farm primarily operates in. (e.g. America/New_York)
  timezone: Timezone

  // branding options for stripe and GrownBy in the future
  branding?: {
    brand: string
    accent: string
  }

  // customProps allows selective enabling of features on a per-farm basis. By default, values are assumed to be disabled.
  customProps?: Partial<Record<Features, boolean>>

  /** stores the number of locations the farm has */
  locationCount: number
  /** We use product count to give customers an estimate of the number of products the farm has to visualize if they are active. See productCountRounding for exact formula on how it is calculated. */
  productCount: number

  /** urlSafeSlug is a unique string name for a farm across all farms and is intended to be used in URLs to represent a farm which owns this urlSafeSlug.  */
  urlSafeSlug: string

  /** Minimum total cart amount required for an order to be placed */
  orderMinimum?: {
    retail?: Money
    wholesale?: Money
  }

  /** Invoices must be paid by X number of days after the date of distribution. Zero means due on delivery */
  dueDateTolerance?: {
    retail?: number
    wholesale?: number
  }

  /** If true, means the farm is a wholesale seller */
  isWholesale?: boolean
}

/** Simple typecast to illustrate the relationship an object of keys that contain the properties */
export type Properties = { [key: string]: true }

export type Certification = {
  badge: string
  id: string
  title: string
  /** If the certification is only available in a specific country, this will be the country code */
  countryCode?: CountryCode
}

export type VerificationCode = {
  user: Pick<User, 'id'>
  code: string
}
