import { FormInput, FormInputProps, Toast, UniversalTag } from '@elements'
import { nonEmptyString, removeDuplicates } from '@helpers/helpers'
import { RegionType } from '@models/Location'
import { memo, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { getState, parsePostalCode } from '@helpers/address'
import { PostalCodeSchemaCanada, ZipCodeSchema } from '@helpers/builders/validators/sharedSchemasAddress'
import { getPostalCodeKind, getStateKind } from '@helpers/display'
import { CountryCode, findCountryData } from '@helpers/international/types'

type Props = FormInputProps & {
  values: string[]
  type: RegionType
  onUpdate: (props: string[]) => void
  country: CountryCode
}

const processZipcode = (text: string, country: CountryCode): [string, string[]] => {
  const tags = []
  let cleaned = text

  if (country === 'US') {
    // Will clean zipcodes and add every 5 numbers as a code

    cleaned = cleaned.replace(/[^0-9]/g, '').trim()

    if (cleaned.length === 5) {
      tags.push(cleaned)
      cleaned = ''
    } else {
      while (cleaned.length >= 5) {
        tags.push(cleaned.slice(0, 5))
        cleaned = cleaned.slice(5)
      }
    }
  } else if (country === 'CA') {
    // Remove white spaces and add every 6 characters as a code
    cleaned = cleaned.replace(/\s+/g, '')

    if (cleaned.length === 6) {
      tags.push(cleaned)
      cleaned = ''
    } else {
      while (cleaned.length >= 6) {
        tags.push(cleaned.slice(0, 6))
        cleaned = cleaned.slice(6)
      }
    }
  } else {
    throw new Error('Country not implemented')
  }

  return [cleaned, tags]
}

/** Will clean states and add every 2 letters as a state */
const processState = (text: string): [string, string[]] => {
  const tags = []
  let cleaned = text
    .replace(/[^a-zA-Z]/g, '')
    .toUpperCase()
    .trim()

  if (cleaned.length === 2) {
    tags.push(cleaned)
    cleaned = ''
  } else {
    while (cleaned.length >= 2) {
      tags.push(cleaned.slice(0, 2))
      cleaned = cleaned.slice(2)
    }
  }
  return [cleaned, tags]
}

export const StateZipInput = memo(function StateZipInput({ values, onUpdate, type, country, ...inputProps }: Props) {
  const [text, setText] = useState('')
  const [errorStateMessage, setErrorStateMessage] = useState('')

  const removeTag = (tag: string) => {
    onUpdate(values.filter((v) => v !== tag))
  }

  const onChange = (textOriginal: string) => {
    // Should set it to upper case for all purposes because canadian zipcodes have alphabetic characters that should be saved in upper case, and state codes should all be in upper case
    const text = parsePostalCode(textOriginal)

    // Will clean the type and return a list of tags and the resulting text
    const [cleaned, tags] = type === RegionType.Zipcode ? processZipcode(text, country) : processState(text)

    let isValid = true

    if (type === RegionType.State) {
      if (country === 'US' && text.length === 2) {
        // Validate the us state
        isValid = nonEmptyString(text) && !!getState(text, country)
        setErrorStateMessage(isValid ? '' : `${text.toUpperCase()} is not a valid 2-letter US state code`)
      } else if (country === 'CA' && text.length === 2) {
        // Validate the canadian province
        isValid = nonEmptyString(text) && !!getState(text, country)
        setErrorStateMessage(isValid ? '' : `${text.toUpperCase()} is not a valid 2-letter Canadian province code`)
      }
    } else if (type === RegionType.Zipcode) {
      if (country === 'US' && text.length === 5) {
        // Validate the us zipcode
        isValid = ZipCodeSchema.isValidSync(text)
      } else if (country === 'CA' && text.length === 6) {
        // Validate the canadian postal code
        isValid = PostalCodeSchemaCanada.isValidSync(text)
      }
    } else {
      throw new Error('Wrong region type')
    }

    // Remove duplicates and update the added codes/states and set the rest as input
    if (isValid) onUpdate(removeDuplicates([...values, ...tags]))
    else
      Toast(
        `Not a valid ${type === RegionType.Zipcode ? getPostalCodeKind(country) : getStateKind(country)} for ${
          findCountryData(country)?.name
        }`,
      )

    setText(cleaned)
  }

  return (
    <View>
      <FormInput
        placeholder={
          type === RegionType.Zipcode ? (country === 'CA' ? 'A1A1A1' : '12345') : country === 'CA' ? 'QC' : 'NY'
        }
        value={text}
        onChangeText={onChange}
        helperText={errorStateMessage}
        {...inputProps}
      />
      <TagContainer tags={values} onPress={removeTag} />
    </View>
  )
})
type TagContProps = {
  tags: string[]
  onPress?(tag: string): void
}

/** This component allows handling dynamic display of simple list of tags */
const TagContainer = memo(function TagContainer({ tags, onPress }: TagContProps) {
  return (
    <View style={styles.tagContainer}>
      {tags.map((tag) => (
        <UniversalTag label={tag} key={tag} disabled={!onPress} onPress={() => onPress && onPress(tag)} />
      ))}
    </View>
  )
})

const styles = StyleSheet.create({
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
})
