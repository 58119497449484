import { GoogleApiGeocodingAddressComponent } from '@api/Addresses'
import { parsePostalCode } from '@helpers/address'
import { addressBuilder } from '@helpers/builders'
import { ValidateAddrOpts } from '@helpers/builders/AddressBuilder'
import { bullet } from '@helpers/display'
import { errorToString, nonEmptyString } from '@helpers/helpers'
import { findCountryData } from '@helpers/international/types'
import { Address } from '@models/Address'
import { ErrorWithCode, isErrorWithCode } from '@shared/Errors'

/** A simplified address parsed from google places api. This is not a valid addres. It merely represents the parsing results */
interface ParsingResults {
  street_number?: string
  street_name?: string
  city?: string
  state?: string
  country?: string
  postal_code?: string
}

/** Builds an address from the google places GoogleApiGeocodingAddressComponent type */
export class GoogleAddressParser {
  private result: ParsingResults = {}

  constructor(private address_components: GoogleApiGeocodingAddressComponent[]) {
    if (!Array.isArray(this.address_components)) {
      throw Error('Address Components is not an array')
    }

    if (!this.address_components.length) {
      throw Error('Address Components is empty')
    }

    for (let i = 0; i < this.address_components.length; i++) {
      const component: GoogleApiGeocodingAddressComponent = this.address_components[i]

      if (GoogleAddressParser.isStreetNumber(component)) {
        this.result.street_number = component.long_name
      }

      if (GoogleAddressParser.isStreetName(component)) {
        this.result.street_name = component.long_name
      }

      if (!this.result.city && GoogleAddressParser.isCity(component)) {
        this.result.city = component.long_name
      }

      if (GoogleAddressParser.isCountry(component)) {
        this.result.country = findCountryData(component.short_name)?.code
      }

      if (GoogleAddressParser.isState(component)) {
        this.result.state = component.short_name || component.long_name
      }

      if (GoogleAddressParser.isPostalCode(component)) {
        this.result.postal_code = parsePostalCode(component.short_name)
      }
    }
  }

  private static isStreetNumber(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('street_number')
  }

  private static isStreetName(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('route')
  }

  private static isCity(component: GoogleApiGeocodingAddressComponent): boolean {
    return (
      component.types.includes('locality') ||
      component.types.includes('administrative_area_level_3') ||
      component.types.includes('neighborhood')
    )
  }

  private static isState(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('administrative_area_level_1')
  }

  private static isCountry(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('country')
  }

  private static isPostalCode(component: GoogleApiGeocodingAddressComponent): boolean {
    return component.types.includes('postal_code')
  }

  /** Returns the non-validated parsing results */
  getResult(): ParsingResults {
    return this.result
  }

  getCityState(): string | null {
    const { city, state } = this.result
    if (!nonEmptyString(city) || !state) {
      return null
    }
    return `${this.result.city}, ${this.result.state}`
  }

  /** Parses the google place into an address validated based on its country */
  getAddress(opts: Omit<ValidateAddrOpts, 'noCheckCoords'> = { allowPO: true }): Omit<Address, 'coordinate'> {
    const parsingResult = this.result

    try {
      const street1 = (
        !parsingResult.street_number && !parsingResult.street_name
          ? undefined
          : (parsingResult.street_number ? parsingResult.street_number + ' ' : '') + parsingResult.street_name
      )?.trim()

      // Create a base address object to be validated below
      const address = {
        street1,
        city: parsingResult.city,
        state: parsingResult.state,
        zipcode: parsingResult.postal_code,
        country: parsingResult.country,
      } as Omit<Address, 'coordinate'>

      const addrErrors = addressBuilder.getAddressErrors(address, { ...opts, noCheckCoords: true })

      if (addrErrors) {
        // If there's validation errors, throw an error message that includes the errors with a formatted string
        const errStr = Object.entries(addrErrors)
          .map(([k, v]) => ` ${bullet} ${k}: ${v}.\n`)
          .join('')

        throw new ErrorWithCode({ code: 'AddressError', devMsg: errStr, data: parsingResult })
      }
      return address
    } catch (error) {
      if (isErrorWithCode(error, 'AddressError')) {
        // If it's the 'AddressError' code, throw as-is, because it means the error was controlled and should be ready to be displayed
        throw error
      }

      // Otherwise it is an unexpected error, so something went wrong while parsing. Must manage it here
      throw new ErrorWithCode({
        code: 'ParsingError',
        devMsg: errorToString(error),
        uiMsg: 'The address data could not be parsed.',
        data: parsingResult,
      })
    }
  }
}
