import { hasEbtHoldsDates, removeUserEvents } from '@api/Event'

import { Alert, Button, ButtonClear, Icon, LoadingView, Modal, Text, TextH1, Toast, Touchable } from '@elements'

import { MoneyCalc } from '@helpers/money'
import { Money } from '@models/Money'
import { getBusinessNameOrUsername, hasGrownByPrivilege, User, userName, UserRole } from '@models/User'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React, { useCallback, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { Logger } from '@/config/logger'
import Colors from '@/constants/Colors'
import { AccessRight, Permission } from '@helpers/Permission'

import { AdminDrawerParamList } from '@/admin/navigation/types'
import { globalStyles } from '@/constants/Styles'
import { useApiFx } from '@/hooks/useApiFx'
import { useHasPermissionWithFlag } from '@/hooks/useHasPermission'
import { useLayout } from '@/hooks/useLayout'
import { adminFarmIdSelector, userSelector } from '@/redux/selectors'
import { getDraftOrdersCountByFarmAndUser } from '@api/DraftOrders'
import { Image } from '@components'
import { formatPickupDate } from '@helpers/display'
import { isNonNullish } from '@helpers/helpers'
import { SupportTicketType } from '@models/SupportTicket'
import SupportTicket from '@screens/UserScreen/Feedback'

export default function CustomerDetailsNavTitle({ customer, totalDue }: { customer?: User; totalDue: Money }) {
  const layout = useLayout()
  const navigation = useNavigation<StackNavigationProp<AdminDrawerParamList>>()
  const hasAccessCreateOrder = useHasPermissionWithFlag(Permission.Orders, AccessRight.Edit)
  const farmId = useSelector(adminFarmIdSelector)
  const user = useSelector(userSelector)
  const isWholeSaleBuyer = customer?.institution?.accountType === 'wholesale-buyer'
  const draftOrdersCount = useApiFx(
    getDraftOrdersCountByFarmAndUser,
    [customer?.id, farmId],
    customer !== undefined && farmId !== undefined && isWholeSaleBuyer === true,
  )
  const styles = useStyles()
  const ebtHolds = useApiFx(hasEbtHoldsDates, [customer?.id], customer !== undefined)
  const [loading, setLoading] = useState(false)

  const handleAlertClearHolds = useCallback(() => {
    Alert('Clear customer holds', 'Are you sure you want to delete this customers holds?', [
      {
        text: 'Cancel',
        onPress: () => Logger.info('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: 'Clear holds',
        onPress: async () => {
          try {
            setLoading(true)
            await removeUserEvents(customer!.id)
            Toast('Success! The holds have been cleared.')
            ebtHolds.refresh()
          } catch (error) {
            Logger.error(error)
            Toast('Uh oh, an error happened. Please try again later.')
          } finally {
            setLoading(false)
          }
        },
        style: 'destructive',
      },
    ])
  }, [customer, ebtHolds])

  const handleSubmitTicketClearHolds = useCallback(() => {
    if (customer === undefined) return
    Modal(<SupportTicket type={SupportTicketType.CLEAR_EBT_HOLD} customer={customer} />, {
      title: 'Submit ticket: Clear hold for this customer',
    })
  }, [customer])

  return (
    <View style={styles.header}>
      {!!customer && (
        <View style={styles.customerBox}>
          <View style={globalStyles.flexRowCenter}>
            <Image source={{ uri: customer.avatar }} style={styles.customerPhoto} />
            <View style={styles.customer}>
              <TextH1 size={layout.isLargeDevice ? 32 : 21}>{getBusinessNameOrUsername(customer)}</TextH1>
              {MoneyCalc.isGTZero(totalDue) && (
                <Text size={10} style={styles.customerWarning} type="bold">
                  PAYMENT DUE
                </Text>
              )}
              {!ebtHolds.loading && ebtHolds.data && ebtHolds.data.last_expire !== null && (
                <>
                  <View style={styles.customerHoldWarningContainer}>
                    <Text style={styles.customerHoldWarning} type="regular">
                      Customer security hold until {formatPickupDate(ebtHolds.data.last_expire)}.
                    </Text>
                    {hasGrownByPrivilege(user, UserRole.Admin) ? (
                      <LoadingView loading={loading} noDefaultLoadingContainerStyle style={styles.customerClearLoading}>
                        <Touchable
                          style={[styles.customerHoldWarning, styles.customerHoldClearWarning]}
                          onPress={handleAlertClearHolds}
                        >
                          <Text style={styles.warningText} type="medium" color={Colors.gold}>
                            <Icon color={Colors.gold} name="user-shield" /> Clear holds
                          </Text>
                        </Touchable>
                      </LoadingView>
                    ) : (
                      <Touchable
                        style={[styles.customerHoldWarning, styles.customerHoldClearWarning]}
                        onPress={handleSubmitTicketClearHolds}
                      >
                        <Text size={12} style={styles.warningText} type="medium" color={Colors.red}>
                          Submit ticket to Support to clear hold.
                        </Text>
                      </Touchable>
                    )}
                  </View>
                  <Text size={12} style={styles.customerHoldWarning} type="regular">
                    Holds: {ebtHolds.data.types.join(', ')}
                  </Text>
                </>
              )}
            </View>
          </View>
          <View style={globalStyles.flexRowCenter}>
            {isWholeSaleBuyer && isNonNullish(draftOrdersCount.data) && draftOrdersCount.data > 0 && (
              <ButtonClear
                title={`${draftOrdersCount.data} pending draft orders`}
                onPress={() =>
                  navigation.navigate('Orders', {
                    screen: 'AdminOrderList',
                    params: { draftOnly: true, customerName: userName(customer) },
                  })
                }
              />
            )}
            {hasAccessCreateOrder && (
              <Button
                title="New Order"
                onPress={() =>
                  navigation.navigate('Orders', {
                    screen: 'OrderCreator',
                    params: {
                      custId: customer.id,
                      orderType: 'standard',
                      goBack: 'customerDetails',
                    },
                  })
                }
              />
            )}
          </View>
        </View>
      )}
    </View>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    // Make sure that it aligns with the `AdminCard`
    header: {
      width: '95%',
      alignSelf: 'center',
    },
    customer: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    customerBox: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' },
    customerWarning: {
      backgroundColor: Colors.red,
      color: Colors.white,
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 100,
      justifyContent: 'flex-start',
      marginLeft: 10,
    },
    customerHoldWarningContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    customerHoldWarning: {
      color: Colors.red,
      paddingVertical: 5,
      justifyContent: 'flex-start',
    },
    customerHoldClearWarning: {
      marginLeft: 5,
    },
    customerClearLoading: {
      marginLeft: 5,
    },
    warningText: {
      textDecorationLine: 'underline',
    },
    customerPhoto: {
      width: 40,
      height: 40,
      borderRadius: 20,
      marginRight: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      customer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      customerWarning: {
        marginLeft: 0,
      },
      customerHoldWarningContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      customerHoldClearWarning: {
        marginLeft: 0,
      },
      customerPhoto: {
        width: 30,
        height: 30,
      },
    },
  },
)
