import { OrderCancelPreflightResponse } from '@shared/types/v2/order'
import { memo, useCallback, useMemo } from 'react'
import { AdminOrderCancelProps } from '../../ModalAdminOrderCancel'

import { formatMoney } from '@helpers/display'
import { getZero, MoneyCalc } from '@helpers/money'
import { Money } from '@models/Money'
import { View } from 'react-native'
import { claimedPickupRatio, confirmCancelOrder, paidAndTotalAmountRatio } from '../../helpers/orderCancelHelper'

import Colors from '@/constants/Colors'
import { globalStyles } from '@/constants/Styles'
import { useSizeFnStyles } from '@/hooks/useFnStyles'
import { useDeepCompareFocusFx } from '@/hooks/useFocusFx'
import useKeyedState from '@/hooks/useKeyedState'
import { adminCurrencySelector, adminFarmSelector } from '@/redux/selectors'
import { Button, Divider, ErrorText, Picker, Text, TextH2 } from '@elements'
import { getServiceFeeForPayment } from '@helpers/serviceFee'
import { useSelector } from 'react-redux'
import { ItemRow } from './ItemRow'
import { PaymentSources } from '@models/PaymentMethod'

type OrderCancelProps = AdminOrderCancelProps & {
  /** @orderCancelPreflightResponse refers to the response from orderCancelPreflight api */
  orderCancelPreflightResponse: OrderCancelPreflightResponse
  /** @chosenDate is string of ISO time and it is the start date of cancellation */
  chosenDate: string
}

enum RefundType {
  Refund_deduct_Processing_Fees = 'refund_deduct_processing_fees',
  Refund_In_Full = 'refund_in_full',
  No_Refund = 'no_refund',
}

/** stripe handle different payment type. stripe => credit card, farmCredit => farmCredit  */
enum StripePaymentType {
  StripeGroups = 'stripeGroups',
  FarmCredit = 'farmCredit',
}

/** stripePaymentOption is used in stripe payment methods Picker like credit card and farm credit */
const StripePaymentOptions: { label: string; value: string }[] = [
  { label: 'Bank & Credit Card', value: StripePaymentType.StripeGroups },
  { label: 'Farm Credit', value: StripePaymentType.FarmCredit },
]

const defaultStripePaymentOption = StripePaymentOptions[0]
const additionalStripePaymentOptions = StripePaymentOptions.slice(1)

/** This is passed in Refund Payment Methods section and only top payment method will display this */
const titleDefaultPaymentMethodSection = { leftSideValues: ['Refund Payment Methods'], leftOpts: { isTitleLeft: true } }

/** This is passed in Refund Summary Section and only top refund source will display this */
const titleDefaultRefundSummarySection = { leftSideValues: ['Refund Summary'], leftOpts: { isTitleLeft: true } }

/** all keyStates props */
type KeyStates = {
  isLoading: boolean
  /** refundType means different refund options */
  refundType: string
  /** stripePaymentType includes stripeGroups (stripe and stripeACH) or farm credit */
  stripePaymentType: StripePaymentType
  /** refundAmountsData holds all related data for refundAmounts */
  refundAmountsData: {
    /** customer refund amount */
    customerRefundAmount: Money
    /** adjustedRefundAmount will be applied when any pattern are going to impact final refund amounts like, RefundType.Refund_deduct_Processing_Fees is chosen or stripePaymentType is changed */
    adjustedRefundAmount: Omit<OrderCancelPreflightResponse['refundAmounts'], 'offline'>
  }
}

export const OrderCancelView = memo(function OrderCancel({
  orderCancelPreflightResponse,
  currentOrder,
  cancelledItems,
  entireOrder,
  onClose,
  chosenDate,
}: OrderCancelProps) {
  const farm = useSelector(adminFarmSelector)
  const currency = useSelector(adminCurrencySelector)

  const styles = useStyles()
  const [{ isLoading, refundType, stripePaymentType, refundAmountsData }, set] = useKeyedState<KeyStates>({
    isLoading: false,
    refundType: RefundType.Refund_deduct_Processing_Fees,
    stripePaymentType: StripePaymentType.StripeGroups,
    refundAmountsData: {
      customerRefundAmount: getZero(currency),
      adjustedRefundAmount: {
        stripe: getZero(currency),
        stripeACH: getZero(currency),
        farmCredit: getZero(currency),
        ebtCASH: getZero(currency),
        ebtSNAP: getZero(currency),
      },
    },
  })

  const { stripe, stripeACH, ebtCASH, ebtSNAP, farmCredit, offline } = orderCancelPreflightResponse.refundAmounts

  const { contributeTips, maxRefundAmounts } = orderCancelPreflightResponse

  /** get stripe processing fee */
  const stripeProcessingFee = useMemo(() => {
    const fee = MoneyCalc.isZero(stripe)
      ? getZero(currency)
      : getServiceFeeForPayment(PaymentSources.STRIPE, stripe, farm.pricingModel?.appFeePercent)

    if (!contributeTips) return fee

    const refundedAmount = MoneyCalc.min(MoneyCalc.add(stripe, fee), maxRefundAmounts.stripe)
    return MoneyCalc.max(MoneyCalc.subtract(refundedAmount, stripe), getZero(currency))
  }, [contributeTips, farm.pricingModel?.appFeePercent, maxRefundAmounts.stripe, stripe, currency])

  /** get stripe ACH processing fee */
  const stripeACHProcessingFee = useMemo(() => {
    const fee = MoneyCalc.isZero(stripeACH)
      ? getZero(currency)
      : getServiceFeeForPayment(PaymentSources.STRIPE_ACH, stripeACH, farm.pricingModel?.appFeePercent)

    if (!contributeTips) return fee

    const refundedAmount = MoneyCalc.min(MoneyCalc.add(stripeACH, fee), maxRefundAmounts.stripeACH)
    return MoneyCalc.max(MoneyCalc.subtract(refundedAmount, stripeACH), getZero(currency))
  }, [contributeTips, farm.pricingModel?.appFeePercent, maxRefundAmounts.stripeACH, stripeACH, currency])

  /** refundOptions is used in refund Picker */
  const refundOptions: { label: string; value: string }[] = useMemo(
    () => [
      {
        label: `Refund and deduct non-refundable processing fees (${formatMoney(
          MoneyCalc.add(stripeProcessingFee, stripeACHProcessingFee),
        )})`,
        value: RefundType.Refund_deduct_Processing_Fees,
      },
      { label: 'Refund in full', value: RefundType.Refund_In_Full },
      { label: 'No Refund', value: RefundType.No_Refund },
    ],
    [stripeACHProcessingFee, stripeProcessingFee],
  )

  const defaultRefundOption = useMemo(() => refundOptions[0], [refundOptions])
  const additionalRefundOptions = useMemo(() => refundOptions.slice(1), [refundOptions])
  const noRefundOption = useMemo(() => refundOptions[2], [refundOptions])

  /** One invoice can be paid by any other source even if first time the order purchased by Offline */
  const isOffline =
    MoneyCalc.isGTZero(offline) &&
    MoneyCalc.isGTZero(stripeACH) &&
    !MoneyCalc.isGTZero(stripe) &&
    !MoneyCalc.isGTZero(farmCredit) &&
    !MoneyCalc.isGTZero(ebtCASH) &&
    !MoneyCalc.isGTZero(ebtSNAP)

  /** firstRefundMethod object holds different paymentMethods. If value for a paymentMethod is true, It is should be listed as first paymentMethod. */
  const firstRefundMethod = arrangePaymentsOrder(orderCancelPreflightResponse.refundAmounts)

  /** this memo is used to set refundType when there is actually no refund and return boolean value */
  const isNoValueToRefund = useMemo(() => {
    if (MoneyCalc.isZero(MoneyCalc.add(stripe, stripeACH, farmCredit, ebtCASH, ebtSNAP))) {
      set('refundType', RefundType.No_Refund)
      return true
    }
    return false
  }, [ebtCASH, ebtSNAP, farmCredit, set, stripe, stripeACH])

  /** this deepCompareEffect is used to adjust refundAmountsData and depends on refundType and stripePaymentType  */
  useDeepCompareFocusFx(() => {
    // all stripe refund goes with farm credit only.
    const allWithFarmCredit = stripePaymentType === StripePaymentType.FarmCredit

    if (refundType === RefundType.No_Refund) {
      return set('refundAmountsData', {
        customerRefundAmount: getZero(currency),
        adjustedRefundAmount: {
          stripe: getZero(currency),
          stripeACH: getZero(currency),
          farmCredit: getZero(currency),
          ebtCASH: getZero(currency),
          ebtSNAP: getZero(currency),
        },
      })
    } else if (refundType === RefundType.Refund_In_Full) {
      // Add processing fee to the refund amount which is calculated by getStripeServiceFeeForPayment if contributeTips is true
      const localStripe = contributeTips ? MoneyCalc.add(stripe, stripeProcessingFee) : stripe

      const localStripeACH = contributeTips ? MoneyCalc.add(stripeACH, stripeACHProcessingFee) : stripeACH

      return set('refundAmountsData', {
        customerRefundAmount: MoneyCalc.add(localStripe, localStripeACH, farmCredit, ebtCASH, ebtSNAP),
        adjustedRefundAmount: {
          stripe: allWithFarmCredit ? getZero(currency) : localStripe,
          stripeACH: allWithFarmCredit ? getZero(currency) : localStripeACH,
          farmCredit: allWithFarmCredit ? MoneyCalc.add(localStripe, localStripeACH, farmCredit) : farmCredit,
          ebtCASH,
          ebtSNAP,
        },
      })
    } else {
      // Deduct processing fee to the refund amount which is calculated by getStripeServiceFeeForPayment if contributeTips is false
      const localStripe = contributeTips ? stripe : MoneyCalc.subtract(stripe, stripeProcessingFee)

      const localStripeACH = contributeTips ? stripeACH : MoneyCalc.subtract(stripeACH, stripeACHProcessingFee)

      return set('refundAmountsData', {
        customerRefundAmount: MoneyCalc.add(localStripe, localStripeACH, farmCredit, ebtCASH, ebtSNAP),
        adjustedRefundAmount: {
          stripe: allWithFarmCredit ? getZero(currency) : localStripe,
          stripeACH: allWithFarmCredit ? getZero(currency) : localStripeACH,
          farmCredit: allWithFarmCredit ? MoneyCalc.add(localStripe, localStripeACH, farmCredit) : farmCredit,
          ebtCASH,
          ebtSNAP,
        },
      })
    }
  }, [
    contributeTips,
    ebtCASH,
    ebtSNAP,
    farmCredit,
    refundType,
    set,
    stripe,
    stripeACH,
    stripeACHProcessingFee,
    stripePaymentType,
    stripeProcessingFee,
    currency,
  ])

  /** the function to process data and request server to process refunds */
  const confirmCancellation = useCallback(() => {
    const refundAmounts = {
      ...refundAmountsData.adjustedRefundAmount,
      offline: getZero(currency), // Offline payment should be refunded to the customer manually
    }
    set('isLoading', true)
    confirmCancelOrder(currentOrder, cancelledItems, refundAmounts, entireOrder, chosenDate).then(() => {
      if (onClose) onClose()
      set('isLoading', false)
    })
  }, [
    cancelledItems,
    chosenDate,
    currentOrder,
    entireOrder,
    onClose,
    refundAmountsData.adjustedRefundAmount,
    set,
    currency,
  ])

  return (
    <View>
      {/* Items section */}
      <View>
        <View style={[styles.itemInfoCont, styles.marginBottom5]}>
          <View style={globalStyles.flex1}>
            <TextH2 type="bold" size={12}>
              Item Name
            </TextH2>
          </View>

          <View style={globalStyles.flex1}>
            <TextH2 type="bold" size={12}>
              Paid/Total Price
            </TextH2>
          </View>

          <View style={globalStyles.flex05}>
            <TextH2 type="bold" size={12}>
              Received
            </TextH2>
          </View>
        </View>
        {cancelledItems.map((item, idx) => {
          const { pickupCounters, invoiceCounters } = orderCancelPreflightResponse!

          return (
            <View key={item.id + '_' + idx} style={styles.itemInfoCont}>
              <View style={globalStyles.flex1}>
                <Text size={10}>
                  {item.product.name} x{item.quantity}
                </Text>
                {item.product.cancellationPolicy && (
                  <Text size={10} color="gray">
                    {item.product.cancellationPolicy}
                  </Text>
                )}
              </View>

              <View style={globalStyles.flex1}>
                <Text size={10}>{paidAndTotalAmountRatio(item.id, { invoiceCounters })}</Text>
              </View>

              <View style={globalStyles.flex05}>
                <Text size={10}>{claimedPickupRatio(item.id, { pickupCounters })}</Text>
              </View>
            </View>
          )
        })}
      </View>

      <Divider top={10} bottom={5} />

      {/* Choose Refund Type section */}
      <View>
        <View style={styles.refundOptionsCont}>
          <Text size={12} type="bold">
            How do you want to refund?
          </Text>
          <Picker
            style={styles.refundOptionsPickerCont}
            disabled={isNoValueToRefund}
            value={refundType}
            items={isOffline ? [noRefundOption] : additionalRefundOptions}
            placeholder={isOffline ? noRefundOption : defaultRefundOption}
            onValueChange={(val) => set('refundType', val)}
          />
          {contributeTips && refundType === RefundType.Refund_In_Full && (
            <Text style={globalStyles.marginVertical10}>This amount does not include customer contributed Tips</Text>
          )}
        </View>
        <ItemRow
          leftSideValues={['Refund Amount']}
          leftOpts={{ isTitleLeft: true }}
          rightOpts={{ color: Colors.black }}
          rightSideValues={['Customer Refund', formatMoney(refundAmountsData.customerRefundAmount)]}
        />
      </View>

      <Divider top={30} bottom={5} />

      {/* Show offline message here because offline will be forced to be no refund option */}
      {isOffline && (
        <ErrorText>{formatMoney(offline)} was paid offline and should be refunded manually to the customer.</ErrorText>
      )}

      {/** Show this section only if refundType is not no_refund */}
      {refundType !== RefundType.No_Refund && (
        <>
          {/* Refund Payment Methods section */}
          <View>
            {(MoneyCalc.isGTZero(stripe) || MoneyCalc.isGTZero(stripeACH)) && (
              <ItemRow
                {...(firstRefundMethod.stripe || firstRefundMethod.stripeACH ? titleDefaultPaymentMethodSection : {})}
                rightSideValues={[
                  <Picker
                    style={styles.pickerCont}
                    key="paymentPicker"
                    items={additionalStripePaymentOptions}
                    placeholder={defaultStripePaymentOption}
                    onValueChange={(val) => set('stripePaymentType', val as StripePaymentType)}
                  />,
                ]}
              />
            )}

            {MoneyCalc.isGTZero(farmCredit) && stripePaymentType !== StripePaymentType.FarmCredit && (
              <ItemRow
                {...(firstRefundMethod.farmCredit ? titleDefaultPaymentMethodSection : {})}
                rightSideValues={['Farm Credit']}
                rightOpts={{ color: Colors.black }}
              />
            )}
            {MoneyCalc.isGTZero(ebtSNAP) && (
              <ItemRow
                {...(firstRefundMethod.ebtSNAP ? titleDefaultPaymentMethodSection : {})}
                rightSideValues={['EBT SNAP']}
                rightOpts={{ color: Colors.black }}
              />
            )}
            {MoneyCalc.isGTZero(ebtCASH) && (
              <ItemRow
                {...(firstRefundMethod.ebtCASH ? titleDefaultPaymentMethodSection : {})}
                rightSideValues={['EBT CASH']}
                rightOpts={{ color: Colors.black }}
              />
            )}
          </View>
          <Divider top={30} bottom={5} />

          {/* Refund Summary */}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount.stripe) && (
            <ItemRow
              {...(firstRefundMethod.stripe ? titleDefaultRefundSummarySection : {})}
              rightSideValues={['Credit Card', formatMoney(refundAmountsData.adjustedRefundAmount.stripe)]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount.stripeACH) && (
            <ItemRow
              {...(firstRefundMethod.stripeACH ? titleDefaultRefundSummarySection : {})}
              rightSideValues={['Bank', formatMoney(refundAmountsData.adjustedRefundAmount.stripeACH)]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount.farmCredit) && (
            <ItemRow
              // show title for farm credit only if the first refund method is farm credit or if stripe and stripeACH are zero and farm credit is greater than zero
              {...(firstRefundMethod.farmCredit ||
              ((firstRefundMethod.stripe || firstRefundMethod.stripeACH) &&
                MoneyCalc.isZero(refundAmountsData.adjustedRefundAmount.stripe) &&
                MoneyCalc.isZero(refundAmountsData.adjustedRefundAmount.stripeACH) &&
                MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount.farmCredit))
                ? titleDefaultRefundSummarySection
                : {})}
              rightSideValues={['Farm Credit', formatMoney(refundAmountsData.adjustedRefundAmount.farmCredit)]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount.ebtSNAP) && (
            <ItemRow
              {...(firstRefundMethod.ebtSNAP ? titleDefaultRefundSummarySection : {})}
              rightSideValues={['EBT SNAP', formatMoney(refundAmountsData.adjustedRefundAmount.ebtSNAP)]}
            />
          )}
          {MoneyCalc.isGTZero(refundAmountsData.adjustedRefundAmount.ebtCASH) && (
            <ItemRow
              {...(firstRefundMethod.ebtCASH ? titleDefaultRefundSummarySection : {})}
              rightSideValues={['EBT CASH', formatMoney(refundAmountsData.adjustedRefundAmount.ebtCASH)]}
            />
          )}
        </>
      )}

      {orderCancelPreflightResponse.incompleteInvoicesNum.length > 0 && (
        <ErrorText>
          {`There are one or more invoices associated with this order that have pending payments. Please wait for payment, or void these invoices (${orderCancelPreflightResponse.incompleteInvoicesNum.join(
            ', ',
          )}).`}
        </ErrorText>
      )}

      {/** Confirm Cancellation button */}
      <View style={styles.confirmButton}>
        <Button loading={isLoading} disabled={isLoading} title="Confirm Cancellation" onPress={confirmCancellation} />
      </View>
    </View>
  )
})

const useStyles = () =>
  useSizeFnStyles(({ isExtraSmallDevice, isSmallDevice }) => ({
    refundOptionsCont: {
      marginHorizontal: 8,
      marginBottom: 10,
      marginTop: 5,
    },
    refundOptionsPickerCont: {
      marginTop: 10,
      height: 35,
    },
    pickerCont: {
      height: 35,
      width: isExtraSmallDevice ? 220 : isSmallDevice ? 250 : 280,
    },
    itemInfoCont: {
      flexDirection: 'row',
      marginHorizontal: 12,
    },
    confirmButton: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    marginBottom5: {
      marginBottom: 5,
    },
  }))

/** This function is used to decide what should be listed as first/top payment method. */
const arrangePaymentsOrder = (
  refundAmounts: OrderCancelPreflightResponse['refundAmounts'],
): Omit<Record<keyof OrderCancelPreflightResponse['refundAmounts'], boolean>, 'offline'> => {
  const { stripe, stripeACH, farmCredit, ebtCASH, ebtSNAP } = refundAmounts
  const result = { stripe: false, stripeACH: false, farmCredit: false, ebtCASH: false, ebtSNAP: false }

  if (MoneyCalc.isGTZero(stripe)) {
    result.stripe = true
  } else if (MoneyCalc.isGTZero(stripeACH)) {
    result.stripeACH = true
  } else if (MoneyCalc.isGTZero(farmCredit)) {
    result.farmCredit = true
  } else if (MoneyCalc.isGTZero(ebtCASH)) {
    result.ebtCASH = true
  } else if (MoneyCalc.isGTZero(ebtSNAP)) {
    result.ebtSNAP = true
  }

  return result
}
