import { forwardRef, memo, useCallback, useEffect, useMemo } from 'react'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'

import { GooglePlace } from '@models/Address'
import {
  debouncedTimeGooglePlaces,
  GooglePlacesInputImperative,
  GooglePlacesSearchContext,
  GooglePlacesSearchContextType,
  GooglePlacesSearchProps,
} from './googlePlacesSearch.helper'
import { GooglePlacesSearchUi } from './GooglePlacesSearchUi'

import { AutoCompleteItem } from '@/hooks/useAutoComplete'

export * from './googlePlacesSearch.helper'

/** Web implementation for the google places search component */
export const GooglePlacesSearch = memo(
  forwardRef<GooglePlacesInputImperative, GooglePlacesSearchProps<google.maps.GeocoderResult>>(
    function GooglePlacesSearch(
      {
        onSelect,
        onSelectGooglePlace,
        types = '(cities)',
        inline,
        hasClearBtn,
        onClearText,
        contStyle,
        initialValue,
        enableReinitialize,
        autoSelectFirstResultOnce,
        inputRef,
        country = 'US',
        //Any props not part of the textInput props (Any custom props) must be destructured here and passed to context
        ...textInputProps
      }: GooglePlacesSearchProps<google.maps.GeocoderResult>,
      ref,
    ) {
      // In web we use the usePlacesAutocomplete 3rd party library to get the results
      const {
        ready,
        value: inputValue,
        clearCache,
        suggestions: { data: searchResults, loading: isSearching },
        setValue,
      } = usePlacesAutocomplete({
        requestOptions: {
          types: [types],
          componentRestrictions: { country },
          offset: 3,
          region: country,
        },
        debounce: debouncedTimeGooglePlaces,
      })

      // We must clear the cache to prevent seeing results from the previous country in some queries
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => clearCache(), [country])

      const getResultDetails = useCallback(
        async (item: AutoCompleteItem<google.maps.places.AutocompletePrediction>) => {
          //If this is the "Current location" item, it will be a coordinate
          const geoCodeResults = await getGeocode({ address: item.data.description })
          const detailItem: AutoCompleteItem<google.maps.GeocoderResult> = {
            text: item.text,
            data: geoCodeResults[0],
          }
          return detailItem
        },
        [],
      )

      const contextData = useMemo<GooglePlacesSearchContextType>(
        () => ({
          ref,
          textInputProps,
          customProps: {
            onSelect,
            onSelectGooglePlace,
            inline,
            initialValue,
            enableReinitialize,
            autoSelectFirstResultOnce,
            types,
            hasClearBtn,
            onClearText,
            contStyle,
            inputRef,
            country,
          },
        }),
        [
          ref,
          contStyle,
          hasClearBtn,
          onClearText,
          inline,
          initialValue,
          onSelect,
          onSelectGooglePlace,
          types,
          textInputProps,
          enableReinitialize,
          autoSelectFirstResultOnce,
          inputRef,
          country,
        ],
      )

      return (
        <GooglePlacesSearchContext.Provider value={contextData}>
          {!!ready && (
            <GooglePlacesSearchUi
              getResultDetails={getResultDetails}
              inputValue={inputValue}
              setValue={setValue}
              isSearching={isSearching}
              searchResults={searchResults}
            />
          )}
        </GooglePlacesSearchContext.Provider>
      )
    },
  ),
)

/** Components that use this component can make a simplified GooglePlace from the item passed to onSelect prop */
export const makeGooglePlace = (item: AutoCompleteItem<google.maps.GeocoderResult>): GooglePlace => {
  const data: GooglePlace = {
    coordinate: {
      latitude: item.data.geometry.location.lat(),
      longitude: item.data.geometry.location.lng(),
    },
    address_components: item.data.address_components,
    name: item.text,
  }
  return data
}
