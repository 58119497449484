import { getFarmCartDiscount, getFarmCartItems, SimpleCart } from '@models/Cart'
import { Distribution } from '@models/Distribution'
import { Invoice, invoiceEbtEligibleAmount, invoiceSubtotal, invoiceTotal } from '@models/Invoice'
import { CurrencyCode, Money, MoneyWithCurrency } from '@models/Money'
import {
  CartItem,
  CartPhysical,
  isCartPhysical,
  isCartShare,
  isCartStandardMulti,
  Order,
  OrderItem,
  Pickup,
  PickupItem,
  SplitTenderPayment,
} from '@models/Order'
import { PaymentInterval, PaymentType } from '@models/Payment'
import { pmt_CashMethod } from '@models/PaymentMethod'
import {
  isPhysical,
  isShare,
  isStandard,
  PayInFull,
  PaymentSchedule,
  PayPerPickup,
  PhysicalProduct,
  Product,
  ProductType,
  Share,
  Standard,
} from '@models/Product'
import {
  DayOfWeek,
  Frequency,
  getDayOfWeek_DateFns,
  getScheduleAvailability,
  isPatternException,
  isRescheduleException,
  isSeasonalSchedule,
  isSkipException,
  isYearRoundSchedule,
  Schedule,
} from '@models/Schedule'
import { dateTimeInZone } from '@models/Timezone'
import { DateTime, Duration } from 'luxon'
import { getCurrency, getZero, makeMoney, MoneyCalc, withCurrency, Zero } from './money'

import { deepClone, isNonNullish, makeHandleObj } from './helpers'

import { ChangeOptionKeys, CSA } from '@models/CSA'
import { Farm } from '@models/Farm'
import { createInvoiceItems } from '../services/InvoiceService'
import { invoiceApplySplitTender } from '../services/splitTender'
import { ProductFields } from './builders/buildProduct'
import { CachedCompute, encodeArgsBasic } from './cachedCompute'
import { getAllProductFeesForInvoice, getProductFeesFromCart } from './productFee'
import { getProductAvailability, getProductFrequency } from './products'
import { eachDayOfInterval, eachWeekOfInterval, getWeekOfMonth, isBefore, isSameDay, isWithinInterval } from './time'
import { omit, PartialPick, Replace } from './typescript'

/** Will get the last day given the day of week and start date
 * EX. getLastDay(DayOfWeek.TUESDAY, new Date()) will return the last tuesday */
export function getLastDay(dayOfWeek: DayOfWeek, startDate: DateTime) {
  return getNextDay(dayOfWeek, startDate).plus({ days: -7 })
}

/** Will get the next day given the day of week and start date.
 *  EX. getNextDay(DayOfWeek.TUESDAY, new Date()) will return the next tuesday */
export function getNextDay(dayOfWeek: DayOfWeek, date: DateTime) {
  let offset = dayOfWeek - date.weekday
  if (offset < 0) {
    offset += 7
  }
  return date.plus({ days: offset })
}

/**
 * Returns the PaymentSchedules of the product.
 *
 * - If product has units, must specify price. (Standard and Digital)
 * - If product is standard, must specify pickups array. (Single pickup and Multiple pickups)
 *
 * This is only necessary for dealing with the different product types, since shares already have the PaymentSchedules embedded, whereas for standard products the PaymentSchedule is derived from the CartItem properties.
 */
export function getPaymentSchedules({
  product,
  price,
  pickups,
}: Pick<CartItem, 'product' | 'pickups' | 'price'>): PaymentSchedule[] {
  const now = dateTimeInZone(product.farm.timezone)
  const currency = getCurrency(product.farm)
  switch (product.type) {
    case ProductType.Standard:
    case ProductType.Digital:
    case ProductType.FarmBalance: {
      if (!price) throw new Error('Must provide unit price when getting the payment schedules of a prod that has units')

      const payInFull: PayInFull = {
        paymentType: PaymentType.PAY_FULL,
        frequency: 'ONCE',
        paymentDates: {
          startDate: now,
          endDate: now,
        },
        amount: makeMoney(price.amount.value, currency),
        deposit: makeMoney(0, currency),
      }
      const paySchedules: PaymentSchedule[] = [payInFull]
      /** If there's multiple pickups and the price is not $0, offer the 'PER-PICKUP' option as well */
      if (isPhysical(product)) {
        if (!pickups)
          throw new Error('Must provide pickups array when getting the payment schedules of a standard prod')
        if (pickups.length > 1 && MoneyCalc.isGTZero(price.amount)) {
          const ppPickup: PayPerPickup = {
            paymentType: PaymentType.INSTALLMENTS,
            frequency: 'PER-PICKUP',
            paymentDates: {
              startDate: now,
              endDate: now,
            },
            amount: makeMoney(price.amount.value, currency),
            deposit: getZero(currency) as { value: 0; currency: CurrencyCode },
          }
          paySchedules.push(ppPickup)
        }
      }
      return paySchedules
    }
    case ProductType.PrimaryShare:
    case ProductType.AddonShare: {
      /** Cannot show multiple options with $0 */
      return [...product.paymentSchedules].filter(onlyOnceIfZero)
    }
    default:
      throw new Error('Wrong product type')
  }
}

/** if pickup item changeOptions is true(blocked), then return true(blocked) */
export function isPickupItemChangeOptionBlocked(optionName: ChangeOptionKeys, pickupItem: PickupItem): boolean {
  if (!pickupItem.csaChangeOptions) return false
  return pickupItem.csaChangeOptions[optionName] ?? false
}

/**
 * Allows only Pay-in-full for payments if the amount is $0
 * - Can be used as a filter for a product payment schedules
 */
export function onlyOnceIfZero(paySchedule: PaymentSchedule): boolean {
  if (MoneyCalc.isZero(paySchedule.amount)) {
    if (paySchedule.frequency !== 'ONCE') {
      return false
    }
  }
  return true
}

/** Returns true if the pickup is skipped by a SkipException or PatternException, false otherwise */
export function isPickupSkippedByException(pickup: DateTime, exceptions: Schedule['exceptions'] = []) {
  if (!pickup) return false

  return exceptions.some((ex) => {
    // Reschedule exceptions are not considered skipped
    if (isRescheduleException(ex)) return false

    if (isSkipException(ex)) {
      return ex.sourceDate.toISODate() === pickup.toISODate()
    }

    if (isPatternException(ex)) {
      return ex.dayOfWeek === pickup.weekday
    }

    return false
  })
}

/** Given a schedule, returns a function that replaces a pickup date with its respective target date or undefined, based on the exception type and whether there's a target date. Ideal for passing to a Array<DateTime>.map() to filter pickups based on the schedule's exceptions */
export function mapPickupExceptions(exceptions: Schedule['exceptions'] = []) {
  return (pickup: DateTime): DateTime | undefined => {
    // If skipped, just undefine the pickup
    if (isPickupSkippedByException(pickup, exceptions)) return undefined

    // If affected by a reschedule exception, replace with the target date
    for (const except of exceptions) {
      if (isRescheduleException(except) && except.sourceDate.toISODate() === pickup.toISODate()) {
        return except.targetDate
      }
    }

    return pickup
  }
}

/** Will filter out week numbers we don't want */
export function filterMonthWeeks(schedule: Schedule, firstWeek?: number) {
  return (pickup: DateTime) => {
    // Gets the first DayOfWeek of the month
    const firstDay = getNextDay(schedule.dayOfWeek, pickup.startOf('month'))
    const lastDay = getLastDay(schedule.dayOfWeek, pickup.endOf('month').plus({ day: 1 }))
    const hasWeek0 = getWeekOfMonth(firstDay) === 1
    const hasWeek5 = getWeekOfMonth(lastDay) === 5
    // If the month doesn't have a week 0 then use week 1
    if ((firstWeek || schedule.week || 0) === 0 && !hasWeek0) return getWeekOfMonth(pickup) === 2
    if ((firstWeek || schedule.week || 0) === 4 && !hasWeek5) return getWeekOfMonth(pickup) === 4
    // If not use the correct week
    return getWeekOfMonth(pickup) === (firstWeek || schedule.week || 0) + 1
  }
}

/** This holds a session cache for the getPickups helper */
export const getPickupsCache =
  makeHandleObj<ReturnType<typeof CachedCompute<ReturnType<typeof getPickups>, Parameters<typeof getPickups>>>>(
    'getPickupsCache',
  )

/** This product-derived type should hold the product properties which may affect the getPickups calculation, for any subtype of product that has pickups */
export type GetPickupsProductOpt =
  | Pick<
      Replace<ProductFields, 'type', PhysicalProduct['type']>,
      'type' | 'distributions' | 'distributionConstraints' | 'disableBuyInFuture' | 'numberPickups'
    >
  | Pick<Standard, 'type' | 'distributions' | 'distributionConstraints' | 'disableBuyInFuture'>
  | Pick<Share, 'type' | 'distributions' | 'distributionConstraints' | 'numberPickups'>
  | Product
  | undefined

/** Options for getPickups calculation related to the Distribution model */
export type DistroOpts = {
  /** Whether hidden distros should be excluded from the calculation */
  excludeHiddenDistros?: boolean
  /** Whether closed distros should be excluded from the calculation */
  excludeClosedDistros?: boolean
  /** Whether the schedule order cutoff window should be ignored during calculation. This would be desired for admin mode */
  ignoreOrderCutoffWindow?: boolean
}

/** Other options for getPickups */
export type GetPickupsOtherOpts = {
  /** the calculation will get pickups starting on this date */
  currDate?: DateTime
  /** the calculation will not take into account the "ignoreDisableBuyInFuture" field, which means it will not be limited to only the next date */
  ignoreDisableBuyInFuture?: boolean
  /** ignores the Share 'numberPickups' property. This will get the entire range of possible pickups for a rolling share (A share with a schedule whose date-range is wider than its pickups' date-range) */
  ignoreNumberPickups?: boolean
  /** if true, the result will be obtained from a memory cache */
  useCache?: boolean
}

/**
 * Wil calculate the pickup dates for a given distro & product, starting from the current date.
 * @param distro is the distribution selected for the product in a cartitem.
 * @param prod is the PhysicalProduct to get pickups for.
 * - the `distributionConstraints` will be applied.
 * - field `disableBuyInFuture` will limit pickups to 1.
 * - if share, expects `numberPickups`. Will limit the resulting pickups to the `numberPickups` property.
 * @param opts additional options that can customize the get pickup calculation
 * @returns array of pickup dates as DateTime[]
 */
export function getPickups(
  distro: Distribution,
  prod?: GetPickupsProductOpt,
  opts: Pick<DistroOpts, 'excludeClosedDistros' | 'ignoreOrderCutoffWindow'> & GetPickupsOtherOpts = {},
): DateTime[] {
  if (prod && !isPhysical(prod)) return []

  // Default values should be assigned to the opts, to ensure correct caching keys
  opts.excludeClosedDistros ??= false
  opts.ignoreOrderCutoffWindow ??= false
  opts.ignoreDisableBuyInFuture ??= false
  opts.ignoreNumberPickups ??= false
  opts.currDate ??= DateTime.now()

  if (opts.useCache) {
    if (!getPickupsCache.isSet()) {
      getPickupsCache.set(CachedCompute(getPickups, (d, p, opts = {}) => encodeArgsBasic(d, p, omit(opts, 'currDate'))))
    }
    const { cachedFn } = getPickupsCache.get()
    return cachedFn(distro, prod, { ...opts, useCache: false })
  }

  const { currDate, excludeClosedDistros, ignoreOrderCutoffWindow, ignoreDisableBuyInFuture, ignoreNumberPickups } =
    opts

  const { schedule, location, orderCutoffWindow: originalOrderCutoffWindow } = distro
  const orderCutoffWindow = ignoreOrderCutoffWindow ? 0 : originalOrderCutoffWindow
  const now = currDate.setZone(location.timezone)

  // The earliest and latest possible pickup days based on the schedule and product availability, not based on actual pickup dates.
  // if product was included, pickupWindow should reflect distro constraints, else use the full date range of the schedule.
  // for year round, end date should use the default pickupWindow duration
  const pickupWindow = prod
    ? getProductAvailability(prod, distro, {
        excludeClosedDistros,
        zone: location.timezone,
      })
    : getScheduleAvailability(distro.schedule)

  if (!pickupWindow) return []

  // Get the base frequency to be used for date calculation. if product was provided, use frequency constraint.
  const freq = prod
    ? getProductFrequency(prod, distro, { strict: false, excludeClosedDistros })
    : distro.schedule.frequency
  if (!freq) return []

  /** Get the first pickup day after startDate from the dayOfWeek.
   * In the case of daily schedules, the dayOfWeek doesn't matter and may distort the results. So for daily schedules only use the pickupWindow start date.
   */
  const firstDay =
    freq === Frequency.DAILY ? pickupWindow.startDate : getNextDay(schedule.dayOfWeek, pickupWindow.startDate)

  // If we try to create an invalid window return no pickups instead of throwing an error
  if (firstDay > pickupWindow.endDate) return []

  // Get all dates in the interval, based on frequency
  let pickups: DateTime[]
  if (freq === Frequency.DAILY)
    pickups = eachDayOfInterval({ start: firstDay, end: pickupWindow.endDate }, { zone: location.timezone })
  else
    pickups = eachWeekOfInterval(
      {
        start: firstDay,
        end: pickupWindow.endDate,
      },
      {
        weekStartsOn: getDayOfWeek_DateFns(distro),
        zone: location.timezone,
      },
    )

  if (freq === Frequency.BIWEEKLY) {
    // Will get all pickups on the distribution schedule from the distribution start date to the end of the window
    const biWeeklyDates = eachWeekOfInterval(
      {
        start: isSeasonalSchedule(distro.schedule) ? distro.schedule.season.startDate : distro.schedule.pickupStart,
        end: pickupWindow.endDate,
      },
      {
        weekStartsOn: getDayOfWeek_DateFns(distro),
        zone: location.timezone,
      },
    ).filter((_, idx) => idx % 2 === 0)
    // Will get the earliest pickup that is in the pickup window
    const startDate = biWeeklyDates.find((pickup) => isWithinInterval(pickupWindow, pickup))
    // If there is no bi-weekly pickups then return [], this should never happen unless the distro is invalid
    if (!startDate) return []
    // Will check if the startDate is the same as pickups[0] meaning we are on the correct bi-weekly schedule
    const isCorrectBiWeekly = isSameDay(pickups[0], startDate)
    // If we are not on the correct schedule then shift one week to be on the right bi-weekly schedule
    if (schedule.frequency === Frequency.BIWEEKLY && !isCorrectBiWeekly) {
      pickups.shift()
    }
    // Take every other week following
    pickups = pickups.filter((_, idx) => idx % 2 === 0)
  } else if (freq === Frequency.MONTHLY) {
    //Take the certain week of every month the user specifies
    const firstWeek = getWeekOfMonth(firstDay) - 1
    pickups = pickups.filter(filterMonthWeeks(schedule, firstWeek))
  }

  // Filters out all exceptions from pickups
  pickups = pickups.map(mapPickupExceptions(schedule.exceptions)).filter(isNonNullish)

  // Sort pickups by date if any exceptions messed up the order
  pickups.sort()

  // Filters out all days outside the pickup window or before the cutoff date
  pickups = pickups.filter((pickup) => {
    if (!isWithinInterval(pickupWindow, pickup)) return false
    return isAvailablePickup(pickup, { location, schedule, orderCutoffWindow }, now)
  })

  // Select only the number of pickups required by the share or if not specified keep all
  if (prod && isShare(prod) && prod.numberPickups !== 0 && !ignoreNumberPickups)
    pickups = pickups.slice(0, prod.numberPickups)
  if (prod && isStandard(prod) && prod.disableBuyInFuture && !ignoreDisableBuyInFuture) pickups = pickups.slice(0, 1)

  // If there are no valid pickups then return empty array
  if (pickups.length === 0) return []

  return pickups
}

type GetNextPickupOpts = {
  distribution?: Distribution
  product?: GetPickupsProductOpt
} & DistroOpts &
  Pick<GetPickupsOtherOpts, 'currDate'>

/** Will get the next available pickup date from the provided datetime, from a given product/distro pair.
 * - nonphysical products will return undefined.
 * - currDate can be customized to get the next distro from any point in time.
 * - by default this will exclude closed and hidden distros when calculating the next pickup, since that would be the correct way to determine which is the next pickup available to a customer interested in this product. In other words, the next pickup from a closed or hidden schedule should not be available for purchase; therefore such a pickup would not be the next pickup returned by this helper.
 * - the default behavior can be modified with the other options for getPickups. For example, if this were run in the admin, you'd want to ignore orderCutoffWindow, etc.
 * - product and distribution are optional because you may use only one. For example, a CartDigital item won't have a distribution. Or, you may want to know the next pickup for a distribution regardless of the product.
 */
export function getNextPickup({ product, distribution, ...opts }: GetNextPickupOpts): DateTime | undefined {
  return distribution && product && isPhysical(product)
    ? getPickups(distribution, product, {
        excludeClosedDistros: true,
        excludeHiddenDistros: true,
        ignoreOrderCutoffWindow: false,
        ...opts,
      })[0]
    : undefined
}

/** Use this when you know the pickup date in advance */
type OrderDeadlineOptsWithPickup = {
  /** This is the pickup date for which you want to get the order deadline. This pickup date is supposed to be obtained in advance. For example, assuming a previous step already called getPickups, and the data was passed here. */
  pickupDate: DateTime
  /** If the pickup date is provided in advance, we only need these properties to get the deadline */
  distro: Pick<Distribution, 'orderCutoffWindow' | 'schedule'>
  product?: undefined
  currDate?: undefined
}
/** Use this when you don't have the pickup date */
type OrderDeadlineOptsWithDistro = {
  pickupDate?: undefined
  /** The full distribution object is necessary when no pickup date is provided in advance */
  distro: Distribution
  /** This "product" will be passed into getPickups, when calculating the next pickup */
  product?: Product
  /** This "currDate" will be passed into getPickups when calculating the next pickup */
  currDate?: DateTime
}

export function getOrderDeadline(opts: OrderDeadlineOptsWithPickup): DateTime
export function getOrderDeadline(opts: OrderDeadlineOptsWithDistro): DateTime | undefined
/** Returns the order deadline for the provided pickup or schedule. If no pickup provided it will use the schedule's next pickup, starting from the current time, in the location's timezone.
 * - Definition of order deadline: DateTime object that specifies the latest moment the pickup is still available for ordering.
 * - It should correctly handle hours when ordercutoffwindow is zero, to allow same day until the endtime hours
 * - If a pickup is already available in opts, will skip pickup calculation (prefferable)
 * - If it returns undefined, it means there's no future pickups
 */
export function getOrderDeadline(
  opts: OrderDeadlineOptsWithDistro | OrderDeadlineOptsWithPickup,
): DateTime | undefined {
  if (opts.pickupDate === undefined) {
    // If we don't have a pickupDate already, must get the next pickup in order to determine the deadline
    const { currDate, product, distro } = opts
    const { orderCutoffWindow, location, schedule } = distro
    const now = (currDate ?? DateTime.now()).setZone(location.timezone)
    const window = orderCutoffWindow ?? 1
    const nextPickup = getNextPickup({ product, distribution: distro, currDate: now })
    if (!nextPickup) return undefined
    return getOrderDeadlineFromPickup(nextPickup, window, schedule.hours.endTime)
  } else {
    // If a pickup date is provided in advance, all we need to do is subtract the window
    const { distro, pickupDate } = opts
    return getOrderDeadlineFromPickup(pickupDate, distro.orderCutoffWindow ?? 1, distro.schedule.hours.endTime)
  }
}

/** Assuming that a pickup date was already calculated, this will return its order deadline.
 * - Definition of order deadline: DateTime object that specifies the latest moment the pickup is still available for ordering.
 * - The result should have the correct time (hours, min, seconds) set inside the DateTime instance, considering the possibility of zero cutoffWindow and schedule endTime hours
 */
export const getOrderDeadlineFromPickup = (
  pickupDate: DateTime,
  cutoffWindow: Distribution['orderCutoffWindow'],
  endTimeHours: Schedule['hours']['endTime'],
  zone?: Distribution['location']['timezone'],
): DateTime => {
  if (cutoffWindow === 0) {
    // If window is zero, deadline should be until the endTime hours of the pickup date
    return pickupDate
      .setZone(zone ?? pickupDate.zone)
      .startOf('day')
      .plus(Duration.fromISOTime(endTimeHours))
  }
  // If window greater than zero, allow until the pickup day minus the window num days, at end of day
  return pickupDate
    .setZone(zone ?? pickupDate.zone)
    .minus({ days: cutoffWindow })
    .endOf('day')
}

/**
 * Calculates the CSA change deadline from the pickup date. This wraps the getOrderDeadlineFromPickup function, but exposes parameters that are more relevant to CSA change deadlines.
 *
 * @param pickupDate - The date of the pickup.
 * @param cutoffWindow - The CSA change window. We will default to one if no change window is provided.
 * @param pickupStartTime - The start time of the pickup.
 * @param zone - The timezone of the distribution location. (optional)
 * @returns The CSA change deadline.
 */
export const getCsaChangeDeadlineFromPickup = (
  pickupDate: DateTime,
  cutoffWindow: CSA['changeWindow'] = 1,
  pickupStartTime: Pickup['distribution']['hours']['startTime'],
  zone?: Distribution['location']['timezone'],
): DateTime => {
  return getOrderDeadlineFromPickup(pickupDate, cutoffWindow, pickupStartTime, zone)
}

/** Determines if a distro's pickup date is considered available for ordering at a specified current time.
 */
export function isAvailablePickup(
  pickup: DateTime,
  { location, schedule, orderCutoffWindow }: Pick<Distribution, 'location' | 'schedule' | 'orderCutoffWindow'>,
  currDate?: DateTime,
): boolean {
  const now = (currDate ?? DateTime.now()).setZone(location.timezone)
  // We can assume cutoffDate is in the distro timezone
  const orderDeadline = getOrderDeadlineFromPickup(pickup, orderCutoffWindow, schedule.hours.endTime)

  // If the orderCutoffWindow is one or more, pickups after the cutoffDate day are still available regardless of hours
  return isBefore(now, orderDeadline, { zone: location.timezone })
}

/**
 *  Will get all pickups for the given distribution, from the pre-season date.
 * @param distribution the distribution to get pickups for
 * @param product if provided, the pickups will reflect any distro constraints from the product. Also the pre-season date will be based on this product's availability for that distro. (Expected to be a physical product)
 * @param opts additional options for the getPickups functions
 */
export function getDistributionPickups(
  distribution: Distribution,
  product?: GetPickupsProductOpt,
  opts: DistroOpts & Pick<GetPickupsOtherOpts, 'useCache'> = {},
): DateTime[] {
  const preSeasonDate = getPreSeasonDate(distribution, !!product && isPhysical(product) ? product : undefined)
  if (!preSeasonDate) return []

  return getPickups(distribution, product, {
    ...opts,
    /** ignoreDisableBuyInFuture should be true, for this helper to return all the distribution pickups, which is the expected behavior */
    ignoreDisableBuyInFuture: true,
    currDate: preSeasonDate,
  })
}

/** Additional options that can be passed to alter the invoice creation such as enforcing timezone or a due date tolerance */
type CalcPaymentsOpts = {
  /** The id for the farm whose items are being checked out. If a farm id is specified, calculation will only include cart items of this farm. This must be included when used in the app, for as long as we have single-farm checkout */
  farmId?: string
  /** Whether this calculation would be for the wholesale catalog or not. Only items for this catalog will be considered in the calculation */
  isWholesale: boolean
  /** If we know the splitTender the user will be paying with, we can calculate exact item discounts based on payment method restrictions */
  splitTender?: SplitTenderPayment
} & Pick<Partial<Farm>, 'timezone' | 'dueDateTolerance'>

/** calculatePayments provides accurate payment data for the UI by internally referencing the same installment-creation logic used in the real checkout. */
export function calculatePayments(
  cart: Pick<SimpleCart, 'items' | 'isAdmin' | 'discounts'>,
  opts: CalcPaymentsOpts,
): PaymentInterval[] | undefined {
  const { farmId, timezone, dueDateTolerance, isWholesale, splitTender } = opts

  const itemsForFarmAndCatalog = getFarmCartItems({ items: cart.items, farmId, isWholesale })

  if (!itemsForFarmAndCatalog.length) return undefined

  const currency = getCurrency(cart.items[0].product.farm)

  // Use our create invoice function to generate invoices
  const invoices: PartialPick<Invoice, 'items' | 'amountTotal' | 'dueDate'>[] = createInvoiceItems(cart, {
    farmId,
    isWholesale,
    timezone,
    dueDateTolerance,
  }).map((inv) => ({
    ...inv,
    amountTotal: invoiceTotal(inv),
  }))

  // If there are no invoices return undefined so that we don't return an empty array
  if (invoices.length === 0) return undefined

  /** Get any discount being applied to the specified farm */
  const discount = farmId ? getFarmCartDiscount(cart, farmId) : undefined

  if (discount) {
    // If there's a discount in the cart for this farm, apply it to the first invoice

    // If we have any coupons, splitTender will apply the correct amounts
    const tenderCopy =
      splitTender && splitTender.length > 0 ? deepClone(splitTender) : [{ paymentMethod: pmt_CashMethod }]

    try {
      invoices[0].couponApplied = discount
      invoiceApplySplitTender(invoices[0], tenderCopy)
    } catch (e) {
      // no-op
      // We can ignore any error coming from calculatePayments because it is only used to calculate the total, we don't
      // care if it is unable to fully cover the total
    }
  }

  // Compute taxes and flat fees for Cart
  const productFeesForCart = getProductFeesFromCart({ items: itemsForFarmAndCatalog })

  // Map the amounts and quantities to total values
  return invoices.map((inv, idx) => {
    const productFeesForInvoice = getAllProductFeesForInvoice(inv, productFeesForCart, {
      isFirstInvoice: idx === 0,
    })

    // Since we don't include the taxes and fees as invoice items in the createInvoiceItems function we should add them here so that each place using this helper can get the true total
    const totalTaxesAndFees = productFeesForInvoice.reduce((acc, itm) => MoneyCalc.add(acc, itm.amount), Zero) ?? Zero

    return {
      date: inv.dueDate,
      total: withCurrency(MoneyCalc.add(invoiceTotal(inv), totalTaxesAndFees), currency),
      subtotal: withCurrency(invoiceSubtotal(inv), currency),
      discounts: withCurrency(MoneyCalc.subtract(invoiceSubtotal(inv), invoiceTotal(inv)), currency),
      ebtEligibleAmount: withCurrency(invoiceEbtEligibleAmount(inv), currency),
      taxesAndFees: productFeesForInvoice,
    }
  })
}

/** Returns the upfront cost and base amount for the item, taking into account prorating.
 * - Since this is only a "base" amount does not take into account any multipliers such as quantity, or number of pickups in the case of multiPickup standard, nor any fees such as delivery.
 */
export function getProratedAmount(
  item: Pick<CartItem, 'product' | 'distribution' | 'paymentSchedule'>,
  opts?: Omit<DistroOpts, 'excludeHiddenDistros'> & Pick<GetPickupsOtherOpts, 'useCache'>,
): { itemAmount: number | null; upfrontPmt: number | null; isProrated: boolean } {
  const itemAmount = item.paymentSchedule.amount.value
  const upfrontPmt = item.paymentSchedule.deposit.value

  // If some pickups have already been missed then we will prorate.
  if (isCartPhysical(item)) {
    const nPickups = getPickups(item.distribution, item.product, opts).length
    // If no pickups left, return null values instead of zero
    if (nPickups < 1) return { itemAmount: null, upfrontPmt: null, isProrated: true }

    // Only shares require pro-ration
    if (isCartShare(item)) {
      const nAllPickups = getDistributionPickups(item.distribution, item.product, opts).length

      if (nPickups !== nAllPickups) {
        // prorate by including the deposit in the total and using only a percent of total cost
        return { itemAmount: Math.round(itemAmount * (nPickups / nAllPickups)), upfrontPmt: 0, isProrated: true }
      }
    }
  }

  // If we don't need to prorate return original value
  return { itemAmount, upfrontPmt, isProrated: false }
}

/**
 * This helper defines a cart item as being in pre-season when the first possible pickup at the distribution is still available for purchase
 * - The orderCutoffWindow is considered when determining if the first pickup is available.
 * - A null return value means bad data, whereas a false value means conclusively the item is not in pre-season
 */
export function isPreSeason(item: Pick<CartPhysical, 'product' | 'distribution'>, opts?: DistroOpts): boolean | null {
  const { product, distribution } = item

  const nAllPickups = getDistributionPickups(distribution, product, opts)
  const pickups = getPickups(distribution, product, opts)

  return nAllPickups.length === pickups.length
}

/** In general terms, Proration means adjusting a total price to reflect a smaller portion of services/ goods sold. For us, we only care about proration when it comes to adjusting a share's total amount to reflect the number of pickups still available at the time of purchase.
 * - This helper defines a cart item as being pro-rated if it's a share no longer in pre-season.
 */
export function isProrated(item: Pick<CartItem, 'product' | 'distribution'>, opts?: DistroOpts): boolean {
  if (!isCartShare(item)) return false
  return isPreSeason(item, opts) === false
}

/**Returns a date before the order deadline of the distro's first pickup. Used for getting the full list of pickup dates */
export const getPreSeasonDate = (
  distribution: Distribution,
  product?: PartialPick<PhysicalProduct, 'distributions' | 'distributionConstraints'>,
): DateTime | null => {
  //we add one day to the cutoff window, to be sure we get all the pickups
  const daysMinus = (distribution.orderCutoffWindow ?? 28) + 1

  if (product) {
    return (
      getProductAvailability(product, distribution, { zone: distribution.location.timezone })?.startDate.minus({
        days: daysMinus,
      }) ?? null
    )
  }
  const schedule = distribution.schedule
  if (isSeasonalSchedule(schedule)) {
    return schedule.season.startDate.minus({ days: daysMinus })
  } else if (isYearRoundSchedule(schedule)) return schedule.pickupStart.minus({ days: daysMinus })
  else return null
}

/** orderSubtotal returns the total amount of an order. */
export function orderSubtotal(order: Order): Money {
  let total = makeMoney(0)
  for (const item of order.items) {
    total = MoneyCalc.add(total, orderItemTotal(item))
  }
  return total
}

/** Will compute the total amount of an order item, taking into account quantity and number of pickups when required. */
export function orderItemTotal(item: OrderItem): Money {
  const pickupMultiplier = isCartStandardMulti(item) ? item.numPickups || 1 : 1
  return MoneyCalc.multiply(item.paymentSchedule.amount, item.quantity * pickupMultiplier)
}

/** Will compute the total amount of a cart item. This complements the result amount from getProratedAmount, by considering quantity, and number of pickups when necessary.
 *
 * This should be used for estimating the total BEFORE checkout, but will not include additional price alterations such as payment
 * schedules, discounts or any fees included in the cart. calculatePayments should be used to determine the subtotal including those additional
 * requirements.
 */
export function cartItemTotal(
  item: CartItem,
  opts?: Omit<DistroOpts, 'excludeHiddenDistros'>,
): MoneyWithCurrency | null {
  const pickupMultiplier = isCartStandardMulti(item) ? item.pickups.length : 1
  const proratedCents = getProratedAmount(item, { ...opts }).itemAmount

  if (proratedCents === null) return null

  const currency = getCurrency(item.product.farm)
  return MoneyCalc.multiply(MoneyCalc.fromCents(proratedCents, currency), item.quantity * pickupMultiplier)
}

/**
 * This function will compute the subtotal for the cart. This is useful when we don't want to consider payment
 * schedules, discounts or any fees included in the cart. It is just a raw total of the items.
 */
export function cartSubtotal(
  cartItems: CartItem[],
  farmId: string,
  opts?: Omit<DistroOpts, 'excludeHiddenDistros'> & { isWholesale: boolean },
) {
  // Cart items may already be filtered when passed here, but this extra check makes it so that no one has to pre-process the cart before calling this
  const items = getFarmCartItems({ items: cartItems, farmId, isWholesale: opts?.isWholesale })
  return items
    .map((itm) => cartItemTotal(itm, { ...opts }) ?? Zero)
    .reduce((acc, item) => MoneyCalc.add(acc, item), Zero)
}

/**
 * Returns true if the invoice is an upfront invoice
 * @param inv invoice to check
 */
export function isUpfrontInvoice(inv: Invoice) {
  return isSameDay(inv.dueDate, DateTime.now(), inv.farm.timezone)
}
