import { YUP_MONEY_OPTIONAL, YUP_MONEY_REQUIRED } from '@helpers/Yup'
import { errorToString, isObject, nonEmptyString } from '@helpers/helpers'
import { Discount } from '@models/Coupon'
import {
  Invoice,
  InvoiceItem,
  InvoiceNextStep,
  InvoicePayment,
  InvoiceStatus,
  Refund,
  invoiceTotal,
} from '@models/Invoice'
import { Location, LocationTypes, isLocalPickup, isNonPickup } from '@models/Location'
import { Order } from '@models/Order'
import { PaymentSources, pmt_CashMethod } from '@models/PaymentMethod'
import * as yup from 'yup'
import { getInvoicePaymentMethod } from '../../services/InvoiceService'
import { MoneyCalc, isMoney } from '../money'
import { includes, isOfType, keys, pick, values } from '../typescript'
import { addressSchema } from './AddressBuilder'
import { Builder } from './Builder'
import { couponSchema } from './CouponBuilder'
import { productFeeSchema } from './ProductFeeBuilder'
import { userAddressSchema } from './UserAddressSchema'
import { ValidationError, isValidationError, validateFromSchema } from './validators/helpers'
import { promoSchema } from './validators/promoSchema'
import { dateTimeInZone } from '@models/Timezone'

export const invoiceItemSchema: yup.ObjectSchema<InvoiceItem> = yup
  .object({
    id: yup.string().defined(),
    isEbtEligible: yup.boolean(),
    payments: yup
      .array(
        yup.object({
          source: yup.mixed<PaymentSources>().oneOf(values(PaymentSources)).defined(),
          amount: YUP_MONEY_REQUIRED('Amount', { requireCurrency: true, allowZero: true, allowNegative: true }),
          discount: YUP_MONEY_OPTIONAL('Discount', { requireCurrency: true }),
          refundedAmount: YUP_MONEY_OPTIONAL('Refunded Amount', { requireCurrency: true }),
        }),
      )
      .defined(),
    cancelled: yup.mixed().isDateTime(),
    isRefunded: yup.boolean(),
    quantity: yup.number().defined(),
    description: yup.string().defined(),
    appliedProductFees: yup.array(productFeeSchema),
    product: yup
      .object({
        id: yup.string().defined(),
        name: yup.string().defined(),
        category: yup.string().defined(),
        producer: yup.string(),
        description: yup.string().defined(),
      })
      .default(undefined),
    location: yup
      .object({
        id: yup.string().defined(),
        type: yup.mixed<LocationTypes>().oneOf(values(LocationTypes)).defined(),
        address: yup
          .object()
          .when('type', ([type], schema) => {
            if (!isOfType<LocationTypes>(type, includes(values(LocationTypes), type))) {
              return schema
            }
            if (isLocalPickup({ type })) {
              return addressSchema
            } else if (isNonPickup({ type })) {
              return userAddressSchema
            }
            return schema
          })
          .defined('Invoice item has location with no address') as yup.ObjectSchema<Location['address']>,
      })
      .default(undefined),
  })
  .defined()

const processorOptionsSchema: yup.ObjectSchema<InvoicePayment['processorOptions']> = yup.object({
  chargeType: yup
    .string<'direct' | 'destination'>()
    .oneOf(['direct', 'destination'])
    .test('is-valid', 'Charge type is not valid', function (val) {
      // If the object is undefined, it's valid
      if (!isObject(this.parent)) return true

      if (nonEmptyString(this.parent.specialProgramCaseKey)) {
        // If the specialProgramCaseKey is defined this must not be defined
        const isInvalid = nonEmptyString(val)
        if (isInvalid)
          return this.createError({
            message: 'Charge type should not be defined when special program key is defined',
          })
      } else {
        // If the specialProgramCaseKey is not defined this must be defined
        const isInvalid = !nonEmptyString(val)
        if (isInvalid)
          return this.createError({
            message: 'Charge type should be defined when special program key is not defined',
          })
      }
      return true
    }),
  specialProgramCaseKey: yup.string().test('is-valid', 'Special program key is not valid', function (val) {
    // If the object is undefined, it's valid
    if (!isObject(this.parent)) return true

    if (nonEmptyString(this.parent.chargeType)) {
      // If the chargeType is defined this must not be defined
      const isInvalid = nonEmptyString(val)
      if (isInvalid)
        return this.createError({ message: 'Special program key should not be defined when charge type is defined' })
    } else {
      // If the chargeType is not defined this must be defined
      const isInvalid = !nonEmptyString(val)
      if (isInvalid)
        return this.createError({ message: 'Special program key should be defined when charge type is not defined' })
    }
    return true
  }),
})

const refundSchema: yup.ObjectSchema<Refund> = yup.object({
  refundRef: yup.string().defined(),
  refundPayout: yup
    .object({
      id: yup.string().defined(),
      arrivalDate: yup.mixed().isDateTime(),
    })
    .default(undefined),
  amount: YUP_MONEY_REQUIRED('Refund Amount', { requireCurrency: true }),
  date: yup.mixed().isDateTime().defined(),
  note: yup.string(),
})

const invoicePaymentMethodSchema: yup.ObjectSchema<InvoicePayment['paymentMethod']> = yup.object({
  id: yup.string().defined(),
  token: yup.string().defined(),
  last4: yup.string(),
  card_type: yup.string(),
  ebtRemainingBalance: YUP_MONEY_OPTIONAL('EBT Remaining Balance', { requireCurrency: true, allowZero: true }),
  ebtCashRemainingBalance: YUP_MONEY_OPTIONAL('EBT Cash Remaining Balance', { requireCurrency: true, allowZero: true }),
  farmCreditRemainingBalance: YUP_MONEY_OPTIONAL('Farm Credit Remaining Balance', {
    requireCurrency: true,
    allowZero: true,
  }),
})

const invoicePaymentSchema: yup.ObjectSchema<InvoicePayment> = yup.object({
  source: yup.mixed<PaymentSources>().oneOf(values(PaymentSources)).defined(),
  paymentRef: yup.string(),
  paymentMethod: invoicePaymentMethodSchema,
  totalPaid: YUP_MONEY_REQUIRED('Total Paid', { requireCurrency: true, allowZero: true }).defined(),
  refundedAmount: YUP_MONEY_OPTIONAL('Refunded Amount', { requireCurrency: true }),
  refunds: yup.array(refundSchema),
  processorOptions: processorOptionsSchema.default(undefined),
})

const invoicePaymentsRecordSchema: yup.ObjectSchema<Invoice['payments']> = yup
  .object()
  .defined()
  // .test('at-least-one', 'Must have at least once payment source', (val) => isObject(val) && keys(val).length > 0)
  .test(
    'valid-source',
    'Payment source is not valid',
    (val) => isObject(val) && keys(val as Invoice['payments']).every((k) => includes(values(PaymentSources), k)),
  )
  .test('valid-payment', 'Payment is not valid', function (val) {
    if (!isObject(val)) return false

    for (const invPay of values(val as Invoice['payments'])) {
      try {
        invoicePaymentSchema.validateSync(invPay)
      } catch (error) {
        return this.createError({ message: errorToString(error) })
      }
    }

    return true
  }) as unknown as yup.ObjectSchema<Invoice['payments']>

const discountSchema: yup.ObjectSchema<Discount> = yup.object({
  coupon: couponSchema,
  promo: promoSchema,
})

const invoiceManualOrderSchema = yup.object({
  id: yup.string<'balance_transaction' | 'manual_invoice'>().oneOf(['balance_transaction', 'manual_invoice']).defined(),
  orderNum: yup.mixed().isUndefined(),
  purchaseOrder: yup.mixed().isUndefined(),
  isWholesale: yup.mixed().isUndefined(),
})

const invoiceDefaultOrderSchema: yup.ObjectSchema<Pick<Order, 'id' | 'orderNum' | 'purchaseOrder' | 'isWholesale'>> =
  yup.object({
    id: yup.string().defined(),
    orderNum: yup.number().defined(),
    purchaseOrder: yup.string(),
    isWholesale: yup.boolean(),
  })

export const invoiceOrderSchema: yup.ObjectSchema<Invoice['order']> = yup
  .object()
  .test('valid-order', 'Invoice Order not Vaid', (val) => {
    const isValid1 = invoiceManualOrderSchema.isValidSync(val)
    const isValid2 = invoiceDefaultOrderSchema.isValidSync(val)
    return isValid1 || isValid2
  })
  .defined() as yup.ObjectSchema<Invoice['order']>

export const invoiceSchema: yup.ObjectSchema<Invoice> = yup
  .object({
    id: yup.string().defined(),
    invoiceNum: yup.number().defined(),
    dueDate: yup.mixed().isDateTime().defined(),
    status: yup.mixed<InvoiceStatus>().oneOf(values(InvoiceStatus)).defined(),
    // Amount paid can be zero when there was no amount due, or it was all covered by a coupon
    amountPaid: YUP_MONEY_OPTIONAL('Amount Paid', { requireCurrency: true, allowZero: true })
      .when('status', {
        is: InvoiceStatus.Paid,
        then: (schema) => schema.defined('Invoice is marked as paid but does not have an amount paid'),
      })
      .when(['status', 'amountTotal'], ([status, amountTotal], schema) => {
        if (status === InvoiceStatus.Paid) {
          return schema.test(
            'amount-paid-must-not-exceed-total',
            'Invoice should not have an amount paid greater than the total',
            (val) => !isMoney(val) || !isMoney(amountTotal) || !MoneyCalc.isGreaterThan(val, amountTotal),
          )
        }
        return schema
      }),
    amountTotal: YUP_MONEY_REQUIRED('Amount Total', { requireCurrency: true, allowZero: true }).defined(),
    // PENDING: This is temporarily disabling this validation. This part of the validation for amount total should be either removed or put elsewhere
    // .when('status', {
    //   is: InvoiceStatus.Due,
    //   then: (schema) =>
    //     schema.test('not-zero', 'Invoice is marked as due but has a total of $0', (val) => !MoneyCalc.isZero(val)),
    // }),

    payments: invoicePaymentsRecordSchema,
    couponApplied: discountSchema.default(undefined),
    items: yup.array(invoiceItemSchema).defined(),
    user: yup.object({
      id: yup.string().defined(),
      name: yup.object({
        firstName: yup.string().defined(),
        lastName: yup.string().defined(),
      }),
      email: yup.string().defined(),
    }),
    farm: yup.object({
      id: yup.string().defined(),
      name: yup.string().defined(),
      timezone: yup.string().defined(),
    }),
    order: invoiceOrderSchema,
    pdf: yup.string(),
    payUrl: yup.string(),
    datePaid: yup
      .mixed()
      .isDateTime()
      .when('status', {
        is: (val: any) => val === InvoiceStatus.Paid,
        then: (schema) => schema.defined('Invoice is marked as paid but does not have a paid date'),
      }),
    dateVoided: yup.mixed().isDateTime(),
    note: yup.string(),
    stripePayout: yup
      .object({
        id: yup.string().defined(),
        arrivalDate: yup.mixed().isDateTime().defined(),
      })
      .default(undefined),
    worldpayPayout: yup
      .object({
        id: yup.string().defined(),
        arrivalDate: yup.mixed().isDateTime().defined(),
      })
      .default(undefined),
    coopFee: YUP_MONEY_OPTIONAL('Coop Fee', { requireCurrency: true, allowZero: true }),
    stripeCCFee: YUP_MONEY_OPTIONAL('Stripe Credit Card Fee', { requireCurrency: true, allowZero: true }),
    stripeACHFee: YUP_MONEY_OPTIONAL('Stripe ACH Fee', { requireCurrency: true, allowZero: true }),
    nextStep: yup
      .object({
        type: yup.mixed<InvoiceNextStep>().oneOf(values(InvoiceNextStep)).defined(),
        url: yup.string().when('type', { is: InvoiceNextStep.STRIPE_3D_SECURE, then: (s) => s.defined() }),
      })
      .default(undefined) as unknown as yup.ObjectSchema<Invoice['nextStep']>,
  })
  .defined()

export class InvoiceBuilder extends Builder<Invoice> {
  constructor() {
    super('invoice')
  }

  build({ id, status, items, dueDate, user, farm, ...otherOpts }: Partial<Invoice>): Invoice {
    const assembled: Partial<Invoice> = {
      ...otherOpts,
      id,
      items,
      dueDate,
      user: user && isObject(user) ? pick(user, 'id', 'name', 'email') : undefined,
      farm: farm && isObject(farm) ? pick(farm, 'id', 'name', 'timezone') : undefined,
      status: status ?? InvoiceStatus.Due,
      amountTotal: items ? invoiceTotal({ items }) : undefined,
    }

    const invoice = invoiceSchema.validateSync(assembled)

    if (MoneyCalc.isZero(invoice.amountTotal) && invoice.status === InvoiceStatus.Due) {
      invoice['status'] = InvoiceStatus.Paid
      invoice['amountPaid'] = invoice.amountTotal
      invoice['datePaid'] = dateTimeInZone(invoice.farm.timezone)

      invoice['payments'] = {
        [PaymentSources.OFFLINE]: {
          source: PaymentSources.OFFLINE,
          paymentRef: PaymentSources.OFFLINE,
          paymentMethod: getInvoicePaymentMethod(pmt_CashMethod),
          totalPaid: invoice.amountTotal, // This will be Zero in the farm currency
        },
      }
    }

    return invoice
  }

  validate(invoice: Partial<Invoice>): Invoice {
    try {
      return validateFromSchema(invoiceSchema, invoice)
    } catch (error) {
      if (isValidationError(error)) {
        throw new ValidationError({ path: 'invoice.' + (error.data?.path ?? ''), msg: error.message })
      }
      throw error
    }
  }
}
