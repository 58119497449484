import { MessageWithIcon } from '@components'
import { hideModal } from '@elements'
import { PaymentForms, PaymentMethod } from '@models/PaymentMethod'
import { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { isNonNullish } from '@helpers/helpers'
import { CurrencyCode } from '@models/Money'
import ButtonGroup from '../../../components/elements/ButtonGroup'
import { AddEbtCard } from './AddEbtCard'
import { AddStripePayment } from './AddStripePayment'

export type AddPaymentMethodProps = {
  /** Called on successful creation */
  onDismiss: (method?: PaymentMethod) => void
  ebtEnabled?: boolean
  hasEbtCard?: boolean
  userId: string
  /** If we pass a certain payment method, then we restrict the form to that type*/
  paymentForm?: PaymentForms
  currency: CurrencyCode
}

/** Modal for adding any type of payment method */
export function AddPaymentMethod({
  onDismiss,
  currency,
  ebtEnabled,
  hasEbtCard,
  userId,
  paymentForm,
}: AddPaymentMethodProps) {
  const [currIndex, updateIndex] = useState(
    paymentForm === PaymentForms.BANK ? 1 : paymentForm === PaymentForms.EBT ? 2 : 0,
  )
  const buttons = ['Credit Card', 'Bank', ebtEnabled ? 'EBT/ SNAP' : undefined].filter(isNonNullish)

  const onSubmit = useCallback(
    (payMethod?: PaymentMethod) => {
      hideModal()
      onDismiss(payMethod)
    },
    [onDismiss],
  )

  return (
    <View style={styles.container}>
      {!paymentForm && <ButtonGroup onSelect={updateIndex} selectedIndex={currIndex} buttons={buttons} />}

      {currIndex === 0 || currIndex === 1 ? (
        <AddStripePayment
          userId={userId}
          onDismiss={onSubmit}
          paymentMethod={currIndex === 1 ? PaymentForms.BANK : PaymentForms.CARD}
          currency={currency}
        />
      ) : hasEbtCard ? (
        <View style={styles.minHeight}>
          <MessageWithIcon title="Can't add EBT card" icon="id-card">
            Only one EBT card can be added to your account at a time. To add a new card, first delete your current
            EBT/SNAP card in your Account &rarr; Payment Methods page.
          </MessageWithIcon>
        </View>
      ) : (
        <AddEbtCard userId={userId} onDismiss={onSubmit} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  minHeight: { minHeight: 300 },
  container: {
    marginHorizontal: 20,
    marginBottom: 20,
  },
})
