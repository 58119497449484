import { parsePostalCode } from '@helpers/address'
import { capitalizeFirst, getPostalCodeKind } from '@helpers/display'
import { CountryCode } from '@helpers/international/types'
import { FormInput, FormInputProps } from './FormInput'

type ZipcodeInputProps = {
  country: CountryCode
} & Omit<FormInputProps, 'placeholder' | 'label' | 'keyboardType' | 'maxLength'>

/** Wrapper of the form input customized for entering a postal code in a given country */
export function FormZipcodeInput({ country, onChange, onChangeText, value, ...props }: ZipcodeInputProps) {
  const maxLength = (() => {
    switch (country) {
      case 'US':
        return 5
      case 'CA':
        return 6
      default:
        throw new Error('Country not implemented')
    }
  })()

  const keyboardType = (() => {
    switch (country) {
      case 'US':
        return 'number-pad'
      case 'CA':
        return undefined
      default:
        throw new Error('Country not implemented')
    }
  })()

  return (
    <FormInput
      placeholder={capitalizeFirst(getPostalCodeKind(country))}
      label={capitalizeFirst(getPostalCodeKind(country))}
      keyboardType={keyboardType}
      maxLength={maxLength}
      onChange={(e) => {
        e.nativeEvent.text = parsePostalCode(e.nativeEvent.text)
        onChange?.(e)
      }}
      onChangeText={(t) => onChangeText?.(parsePostalCode(t))}
      value={parsePostalCode(value || '')}
      {...props}
    />
  )
}
