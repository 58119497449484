import Colors from '@/constants/Colors'
import { useApiFx } from '@/hooks/useApiFx'
import { getEmailLogs } from '@api/FarmGenAdmin'
import { MessageWithIcon } from '@components'
import { LoadingView, Modal, Text } from '@elements'
import { displayTimeStamp } from '@helpers/time'
import { User, userName } from '@models/User'
import { FlatList, StyleSheet, View } from 'react-native'

/** Component to display email logs for a user */
function EmailLogs({ userEmail }: { userEmail: string }) {
  const emailsFx = useApiFx(getEmailLogs, [userEmail])

  return (
    <View style={styles.container}>
      <LoadingView loading={emailsFx.loading} error={emailsFx.err} success={emailsFx.data}>
        {(logs) => (
          <FlatList
            data={logs}
            renderItem={({ item }) => (
              <View style={styles.emailLogItem}>
                <Text type="medium" size={15}>
                  {item.templateName}
                </Text>
                <Text color={Colors.shades[200]}>{displayTimeStamp(item.date)}</Text>
                <Text>Event: {item.event}</Text>
                <Text>ID: {item.mailId}</Text>
              </View>
            )}
            ListEmptyComponent={
              <MessageWithIcon icon="envelope" title="No Emails" children="No email logs found for this user." />
            }
          />
        )}
      </LoadingView>
    </View>
  )
}

/** Modal component to display email logs for a user */
export function EmailLogsModal(user: User) {
  return Modal(<EmailLogs userEmail={user.email} />, { title: `Email Logs for ${userName(user)}`, dismissable: true })
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  emailLogItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shades[300],
    marginBottom: 10,
  },
})
