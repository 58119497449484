import { Zero, getCurrency, withCurrency } from '@helpers/money'
import { isSameDay } from '@helpers/time'
import { Pickup } from '@models/Order'
import { ProductType } from '@models/Product'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { OrderCreatorStatePayments } from './OrderCreatorScreen.helper'

import { UseApiFxReturn } from '@/hooks/useApiFx'
import { useCartService } from '@/hooks/useCart'
import { KeyedState } from '@/hooks/useKeyedState'
import { adminFarmIdSelector, adminFarmSelector, isWholesaleOrderCreatorSelector } from '@/redux/selectors'
import { pick } from '@helpers/typescript'
import { usePaymentsAndTotalsData_reusable } from './usePaymentsAndTotalsData-reusable'

/** Data layer for state related to payments, totals, amounts, etc; Indended for the order creator specifically */
export function usePaymentsAndTotalsData({
  custPickupsFx,
  keyedState,
}: {
  custPickupsFx: UseApiFxReturn<(userId: string, farmId: string) => Promise<Pickup[]>>
  keyedState: KeyedState<OrderCreatorStatePayments>
}) {
  const adminFarmId = useSelector(adminFarmIdSelector) || ''
  const isWholesaleOrderCreator = useSelector(isWholesaleOrderCreatorSelector)
  const cartService = useCartService({
    cartServiceType: 'orderCreator',
    farmId: adminFarmId,
    isWholesale: isWholesaleOrderCreator,
  })
  const farm = useSelector(adminFarmSelector)

  const { payments } = usePaymentsAndTotalsData_reusable({
    custPickupsFx,
    keyedState,
    cartService,
    farm,
    isWholesale: isWholesaleOrderCreator,
  })

  const [
    {
      total,
      deliveryFeesData: { hasDeliveryFees },
    },
  ] = keyedState

  /** Update the payment selector options. This data will be accessed from context and passed to the payment selector component */
  const paymentSelectorOptions = useMemo(() => {
    if (!farm || !payments) {
      return undefined
    }

    const isFirstInvoiceToday = isSameDay(payments[0].date, DateTime.now(), farm.timezone)

    const currency = getCurrency(farm)

    return {
      farm: pick(farm, 'id', 'name', 'offlinePayments', 'paymentTypes', 'dueDateTolerance'),
      amountTotal: withCurrency(total.total, currency),
      amountEbt: withCurrency(total.ebtEligibleAmount ?? Zero, currency),
      // If we have more than one payment or the only payment is not today then it must have future payments
      // TODO: This assumes draft orders are not being placed from the order creator, if that changes this needs to be updated like the consumer side
      hasFuturePayments: payments.length > 1 || (payments.length === 1 && !isFirstInvoiceToday),
      hasDelivery: hasDeliveryFees,
      hasFarmBalanceItem: cartService.cart.some((ci) => ci.product.type === ProductType.FarmBalance),
      // TODO: If we add draft orders to the order creator we will need to update this as well
      isInvoiceDueToday: isFirstInvoiceToday,
      allowOfflinePayments: true,
    }
  }, [cartService.cart, farm, payments, total, hasDeliveryFees])

  return { payments, paymentSelectorOptions }
}
