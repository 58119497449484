import { invoicesCollection } from '@api/framework/ClientCollections'
import { callEndpoint } from '@api/v2'
import { extendErr, removeDuplicates, splitToGroups } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { sortByEarliest, sortByLatest } from '@helpers/sorting'
import { Coupon, Discount, PromoCode } from '@models/Coupon'
import { getInvoiceTips, Invoice, InvoiceStatus } from '@models/Invoice'
import { Money } from '@models/Money'
import { SplitTenderPayment } from '@models/Order'
import {
  BasicInvoiceResponse,
  InvoiceValidateDiscountRequest,
  OldRefundInvoiceResponse,
  PayInvoiceRequest,
  Payment,
} from '@shared/types/v2/invoice'

// eslint-disable-next-line no-restricted-imports
import { collection, doc, limit, orderBy, QueryConstraint, where } from 'firebase/firestore'

import { Logger } from '../config/logger'
import { db } from './db'

import { getStore } from '@/redux/store'
import { PaymentSources } from '@models/PaymentMethod'
import { dateTimeInZone } from '@models/Timezone'
import { ErrorHandlerCallback } from 'react-native'
import { marshalDateObject, unmarshalDateObject } from './encoding/Time'
import { errorCatcher } from './Errors'

export const INITIAL_LIMIT_N_INVOICES = 20

/** for both admin and customer side */
export async function loadInvoice(id: string) {
  return invoicesCollection.fetch(id)
}

/** snapshotInvoicesByUserAndDueDate invokes as snapshot handler for the supplied user within the set date range.
 * The snapshot will be passed to the callback function as data changes. */
export function snapshotInvoicesByUserAndDueDate(
  callback: (invoices: Invoice[]) => void,
  onErr: ErrorHandlerCallback = errorCatcher,
  userId: string,
  /** `limitN`: The max number of documents to load. If null, there will be no limit. If undefined, will have the default limit */
  limitN: number | null = INITIAL_LIMIT_N_INVOICES,
): () => void {
  const qFilters: QueryConstraint[] = [where('user.id', '==', userId), orderBy('dueDate.utc', 'desc')]
  // firestore has a 10k max limit. This prevents misuse of this helper
  if (limitN !== null && limitN < 10000) qFilters.push(limit(limitN))
  const q = invoicesCollection.query(...qFilters)

  return invoicesCollection.snapshotMany(
    q,
    (invoices) => {
      const futureInvoices = invoices
        .filter((inv) => inv.dueDate > dateTimeInZone(inv.farm.timezone))
        .sort(sortByEarliest('dueDate'))
      const pastInvoices = invoices
        .filter((inv) => inv.dueDate <= dateTimeInZone(inv.farm.timezone))
        .sort(sortByLatest('dueDate'))
      callback(futureInvoices.concat(pastInvoices))
    },
    onErr,
  )
}

export function snapshotInvoicesByUserAndFarm(
  callback: (invoices: Invoice[]) => void,
  onError: (err: Error) => void,
  userId: string,
  farmId: string,
): () => void {
  const q = invoicesCollection.query(where('user.id', '==', userId), where('farm.id', '==', farmId))
  return invoicesCollection.snapshotMany(
    q,
    (invoices) => {
      callback(invoices.sort(sortByLatest('dueDate')))
    },
    onError,
  )
}

/** Will load all unpaid invoices for a farm and user */
export async function loadUnpaidInvoicesByUserAndFarm(userId: string, farmId: string): Promise<Invoice[]> {
  const realInvoices = await invoicesCollection.fetchAll(
    where('user.id', '==', userId),
    where('farm.id', '==', farmId),
    where('status', 'in', [InvoiceStatus.Due, InvoiceStatus.Failed, InvoiceStatus.Incomplete]),
  )
  return realInvoices.sort(sortByEarliest('dueDate'))
}

/** Loads invoices by order with a snapshot listener, admin only */
export function snapshotInvoicesByOrder(
  callback: (invoices: Invoice[]) => void,
  onError: (err: Error) => void,
  orderId: string,
  farmId: string,
) {
  const q = invoicesCollection.query(where('order.id', '==', orderId), where('farm.id', '==', farmId))
  return invoicesCollection.snapshotMany(q, (invoices) => callback(invoices.sort(sortByEarliest('dueDate'))), onError)
}

/** Loads invoices by order, for the logged-in user from client side */
export async function loadInvoicesByOrderAndUser(orderId: string): Promise<Invoice[]> {
  const id = getStore().getState().user.id
  const realInvoices = await invoicesCollection.fetchAll(where('user.id', '==', id), where('order.id', '==', orderId))
  return realInvoices.sort(sortByEarliest('dueDate'))
}

/**
 *
 * V2
 *
 */

export async function chargeInvoice(
  invoiceId: string,
  payments: SplitTenderPayment,
  opts: Pick<PayInvoiceRequest, 'fees'> & { discount?: Discount },
) {
  const result = await callEndpoint('v2.Invoice.payInvoiceService', {
    invoiceId,
    payments,
    fees: opts.fees,
    discount: opts.discount ? marshalDateObject(opts.discount) : undefined,
  })
  return result.success
}

/** Will allow the admin to recharge a failed invoice or manually charge a CC invoice before the dueDate */
export async function rechargeInvoice(invoiceId: string) {
  const result = await callEndpoint('v2.Invoice.chargeInvoiceService', {
    invoiceId,
  })
  return result.success
}

/** Mark an invoice as voided or un-void it */
export const voidInvoice = async (
  invoiceId: string,
  voidInv: boolean,
  note?: string,
): Promise<BasicInvoiceResponse> => {
  const res = await callEndpoint('v2.Invoice.voidInvoiceService', {
    invoiceId,
    voidInv,
    note,
  })
  return res
}

/** Create a new manual invoice for the given user and farm. */
export const createManualInvoice = async (args: {
  userId: string

  farmId: string

  amount: Money

  description: string

  markAsPaid: boolean

  note: string
}): Promise<string> => {
  const newInvoiceId = doc(collection(db(), 'invoices')).id
  const res = await callEndpoint(
    'v2.Invoice.createManualInvoiceService',
    args,
    // Will generate the invoice ID here so that we can make sure this operation is idempotent
    newInvoiceId,
  )
  return res.invoiceId
}

/** Will allow partial refunds of the invoice to its original payment source */
export const createPartialRefund = async (
  invoice: Invoice,
  payments: Payment[],
  refundNote?: string,
): Promise<OldRefundInvoiceResponse> => {
  const res = await callEndpoint('v2.Invoice.refundInvoiceService', {
    invoiceId: invoice.id,
    amounts: payments,
    refundNote,
  })
  return res
}

/** Will refund the entire invoice to its original payment source */
export const createFullRefund = async (invoice: Invoice): Promise<OldRefundInvoiceResponse> => {
  // Build refund amounts
  const payments = Object.values(invoice.payments)
    .map((payValue) => {
      if (payValue.source === PaymentSources.STRIPE || payValue.source === PaymentSources.STRIPE_INVOICE) {
        const tips = getInvoiceTips(invoice)
        return {
          processor: payValue.source,
          amount: MoneyCalc.subtract(payValue.totalPaid, tips),
        }
      }
      return {
        processor: payValue.source,
        amount: payValue.totalPaid,
      }
    })
    .filter((amt) => !MoneyCalc.isZero(amt.amount))

  // nothing to refund as amounts are all 0
  if (payments.length === 0) return { success: true }

  const res = await callEndpoint('v2.Invoice.refundInvoiceService', {
    invoiceId: invoice.id,
    amounts: payments,
  })
  return res
}

/** This will mark an invoice as paid outside of stripe */
export const payInvoiceOffline = async (invoiceId: string, note?: string) => {
  const res = await callEndpoint('v2.Invoice.markPaidOfflineService', {
    invoiceId,
    note,
  })
  return res.success
}
/** This will record an adjustment on the invoice, this is useful to record a partial payment */
export const addInvoiceAdjustment = async (invoiceId: string, amount: Money, reason: string) => {
  const res = await callEndpoint('v2.Invoice.invoiceAdjustmentService', {
    invoiceId,
    amount,
    reason,
  })
  return res.success
}

/** Will change the invoices payment method and optionally all future invoices for the user */
export const changeInvoicePaymentMethod = async (
  invoiceId: string,
  userId: string,
  payments: SplitTenderPayment,
  applyToFuture?: boolean,
) => {
  const res = await callEndpoint('v2.Invoice.changeInvoicePaymentMethod', {
    invoiceId,
    payments,
    userId,
    applyToAll: applyToFuture,
  })
  return res.success
}

export async function getCustomerHistoricTotalPurchaseAmounts(farmId: string, userId: string) {
  const res = await callEndpoint('v2.Invoice.getCustomerTotalPurchasedService', {
    userId,
    farmId,
  })
  return res.totalAmounts
}

/** Will validate a coupon code with an admin invoice */
export const invoiceValidateDiscountAdmin = (invoiceId: string, id: Coupon['id']) =>
  invoiceValidateDiscount({ invoiceId, type: 'coupon', id })

/** Will validate a promo code with a consumer invoice */
export const invoiceValidateDiscountConsumer = (invoiceId: string, id: PromoCode['id']) =>
  invoiceValidateDiscount({ invoiceId, type: 'promo', id })

async function invoiceValidateDiscount(req: InvoiceValidateDiscountRequest): Promise<Discount> {
  try {
    const res = await callEndpoint('v2.Invoice.invoiceValidateDiscountService', req)
    return unmarshalDateObject<Discount>(res)
  } catch (e) {
    Logger.error(extendErr(e, 'Error while adding invoice discount: '))
    throw e
  }
}

/** Loads invoices that have specific order ids. */
export async function loadInvoicesByOrderIds(orderIds: string[], farmId: string) {
  const ids = removeDuplicates(orderIds)

  // Firebase 'in' query is limited to 30 values
  const chunks = splitToGroups(ids, 30)
  const promises = chunks.map((chunk) =>
    invoicesCollection.fetchAll(where('order.id', 'in', chunk), where('farm.id', '==', farmId)),
  )

  const res = await Promise.all(promises)
  return res.flat()
}
