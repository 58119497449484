import { PartialPick } from '@helpers/typescript'

import { Farm } from './Farm'
import { UserAddress } from './UserAddress'

import { AccessRight, Permission } from '@helpers/Permission'
import { InstitutionRoles } from './Wholesale'

/** States to determine whether or not the user is signed in */
export enum SignedInState {
  UNKNOWN = 0,
  SIGNEDIN = 1,
  SIGNEDOUT = 2,
}

/** The authentication provider from which a user created their account */
export enum SignInProviders {
  Apple = 'apple',
  Google = 'google',
  Phone = 'phone',
  Email = 'email',
  None = 'none',
  Unknown = '',
}

/** Role represents the access permissions a user has for a farm. */
export enum Role {
  // A prospect is someone who favorites the farm but not purchased anything
  Prospect = 'prospect',
  // A Customer is someone who has made a purchase from the farm
  Customer = 'customer',
  // A Distributor manages schedules for a farm and is able to see orders that have been placed.
  Distributor = 'distributor',
  // A Manager is able to do everything on a farm. They are the account that claimed the farm and they cannot be removed.
  Manager = 'manager',
  // An Admin is permitted to access all account information and manage sensitive information. However, they can be removed by a manager.
  Admin = 'admin',
  // An accountant manages the sales and payouts for a farm and can see all sales information but cannot make edits to farm data.
  Accountant = 'accountant',
  //TODO: This role will probably not be used until we have a POS system
  // A seller is an account that operates a POS. They have access only to placing new orders and handling direct sales at a farm stand
  Seller = 'seller',
}

/** Roles that represent a higher role than a Customer / Prospect role*/
export const HIGH_LEVEL_ADMIN_ROLES = [Role.Manager, Role.Admin, Role.Accountant, Role.Distributor, Role.Seller]

/** generate the description function */
export function getRoleDescription(role: Role) {
  switch (role) {
    case Role.Distributor:
      return "A Distributor is responsible for managing the farm's schedules and has the capability to view all placed orders."
    case Role.Admin:
      return 'An Admin has full access to the entire farm dashboard, along with the authorization to manage all account-related and sensitive data.'
    case Role.Accountant:
      return 'An Accountant has visibility into all sales-related information. However, they have view only access to the rest of the farm dashboard.'
    case Role.Seller:
      return 'A seller operates the Point of Sale (POS) system and is exclusively authorized to place new orders and manage direct sales at a farm stand.'
    default:
      return ''
  }
}

export function isFarmCustomer(association: Pick<FarmAssociation, 'role'>) {
  return association.role === Role.Customer || association.role === Role.Prospect
}

/** isHighLevelRole will be check if role is manager or admin */
export function isHighLevelRole(association: PartialPick<FarmAssociation, 'role'>) {
  return association.role === Role.Manager || association.role === Role.Admin
}

/** UserRole represents the access permissions a user has for the GrownBy platform. */
export enum UserRole {
  // Someone who is a developer of GrownBy and should see special information
  Developer = 'developer',
  // Someone who is an admin of GrownBy and should be allowed special privileges
  Admin = 'admin',

  // Someone who is designated as an EBT tester, they will have access to EBT even if it is not enabled for the farm
  EbtTester = 'ebt-tester',
}

export type FarmAssociation = {
  // The document ID. The ID value is equivalent to the farm ID that the association references.

  id: string

  farmId: string

  /** Stripe child customer ID (Connect account) created for association with the farm. It is created automatically in firestore after an order is placed, but not when favoriting a farm. Therefore if the order is the user's first interaction with the farm, it would be undefined when the document is first written to firestore, and becomes available in subsequent async triggers. */
  customerRef?: string

  isFavorite: boolean

  // Notification options specific to the farm. An empty value defers to the global notification settings.

  farmMessages?: Notifications // Farm Messages and Reminders

  role?: Role

  name: string

  note?: string

  /** This type represents how we use the data in the app, but is not the type of data in Firestore. Farm will NEVER be
   * saved into Firestore, it is only loaded into this type for use in the client. */
  farm?: Farm

  /** Custom permission allows a user to have certain permissions that overwrite default permission for their role. This can be setup individually for a user. */
  customPermissions?: Partial<Record<Permission, AccessRight>>
}

/** A User represents a user account in the database. This data exists independently of the Auth user */
export type User = {
  // The role of the user, only developer and admin exists right now
  role?: UserRole

  // The document ID.
  id: string

  // The name of the user.
  name: {
    firstName: string
    /** For an institution account the lastName will be an empty string */
    lastName: string
  }

  /** If the user is an institutional account, this section will hold the account info. If this is undefined, it means the user is a regular user (A potential institution member) */
  institution?: {
    /** The type of institution */
    accountType: 'wholesale-buyer'

    /** A list of the ids. This is expected to hold the same ids as the keys in the authorizedUsers field. It only serves the purpose of helping with db queries as an array of primitive strings */
    authorizedUserIds: User['id'][]
    /** A map between user ids and their role in the institution */
    authorizedUsers: Record<User['id'], InstitutionRoles>
    /** This flag is a helper for querying all institution accounts */
    isInstitution: true
    /** The institution business name */
    businessName?: string
  }

  /** Whether the user is an authorized member of an institutional account. A user may NOT be associated to more than one institutional account. */
  isInstitutionAuthorizedUser?: boolean

  // The user's email address.
  email: string

  email2?: string

  // A token used to send notifications.
  notificationToken: string

  // A count of the users notification badges
  notificationBadgeCount?: number

  // GrownBy Updates. Email is excluded from the notifications because system emails must always be delivered.
  notifications: SystemNotifications
  // Promotions and Tips
  promotions: Notifications

  /** This address field contains the user's primary address.
   * - TODO: Move address from here to sub-collection and complete refactor  */
  address?: UserAddress

  avatar?: string

  /** Customer share preferences */
  preferences?: string

  pronouns?: string

  phoneNumber?: string

  // Stripe customer ID.
  customerRef: string

  /** The method they used to signIn.
  - "None" should be used for institution accounts which do not have a sign in method directly because they're accessed only through their member users.
   */
  signInProvider?: SignInProviders

  // A reference to the farm that originally invited the user. This value will only be defined when the user account
  // was created by a farm manager and will be undefined when users signed up on their own.
  invitedByFarm?: Pick<Farm, 'id' | 'name'>

  // True if the user is suspended and should not be allowed to access their account
  isDisabled?: boolean
}

export type Notifications = {
  email: boolean

  pushNotification: boolean

  text: boolean
}

/** SystemNotifications identifies a set of system notification types like Notifications but excludes email notifications. Email notifications are not optional for system level messages and therefore are excluded. */
export type SystemNotifications = Omit<Notifications, 'email'>

/** userName returns a user's full name. */
export function userName(user: Pick<User, 'name'>): string {
  const { firstName, lastName } = user.name
  return [firstName, lastName].filter((element) => element.length).join(' ')
}

/** Returns the institution's businessName if available; otherwise, returns the user's full name */
export function getBusinessNameOrUsername(user: Pick<User, 'name' | 'institution'>): string {
  if (user.institution?.businessName) {
    return user.institution?.businessName
  }

  return userName(user)
}

/** A staff with following role can access farm admin side */
export function isFarmAdmin(farmAssociation: FarmAssociation) {
  return (
    farmAssociation.role === Role.Manager ||
    farmAssociation.role === Role.Admin ||
    farmAssociation.role === Role.Distributor ||
    farmAssociation.role === Role.Accountant ||
    farmAssociation.role === Role.Seller
  )
}

export function hasGrownByPrivilege(user: PartialPick<User, 'role'>, privilege: UserRole) {
  // Specifies additional permissions some roles have
  const roleHierarchy: Record<UserRole, UserRole[]> = {
    [UserRole.Developer]: [UserRole.Admin, UserRole.EbtTester],
    [UserRole.Admin]: [UserRole.EbtTester],
    [UserRole.EbtTester]: [],
  }

  if (!user.role) return false

  // If the user has the given role then authorize them
  if (user.role === privilege) return true

  // Check if the role has additional privileges
  return roleHierarchy[user.role]?.includes(privilege)
}

/** The type of the user */
export enum UserType {
  /** Represents a wholesale user */
  wholesale = 'wholesale',
  /** Represents a retail user */
  retail = 'retail',
}
