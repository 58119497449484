import { Modal, TextH2 } from '@elements'
import { MoneyCalc } from '@helpers/money'
import { PaymentForms, PaymentMethod, PaymentSources } from '@models/PaymentMethod'
import { useContext, useMemo } from 'react'
import { View } from 'react-native'
import { isMobile } from '../../../constants/Layout'
import { AddBankAccountButton, AddCreditCardButton, AddEbtCardButton } from '../AddPaymentMethod/AddPaymentButtons'
import { AddPaymentMethod } from '../AddPaymentMethod/AddPaymentMethod'
import { PaymentOptionsContext } from './PaymentOptionsContext'
import { PaymentOptionsModalContext } from './PaymentOptionsModalContext'
import { showInfinitePaymentsHelper } from './helpers/display'
import { sharedStyles } from './helpers/styles'
import { PmtDef } from '@shared/helpers/PaymentDefinitions'

type AddNewPaymentProps = {
  /** If there is an existing EBT payment method for the user (only allow 1)*/
  hasEbtPaymentMethod: boolean
}

/** This component handles adding a new credit card or EBT card */
export function AddNewPaymentOptions({ hasEbtPaymentMethod }: AddNewPaymentProps) {
  const { userId, options, splitTender, onAddNewPaymentMethod, refreshPaymentMethods } =
    useContext(PaymentOptionsContext)
  const modalContext = useContext(PaymentOptionsModalContext)

  const showInfinitePayments = showInfinitePaymentsHelper(splitTender, options)
  const showAddBank = useMemo(
    () =>
      options.farm.paymentTypes.check &&
      PmtDef(PaymentSources.STRIPE_ACH).hasCurrencySupport(options.amountTotal.currency),
    [options.amountTotal.currency, options.farm.paymentTypes.check],
  )

  const showAddEbt = useMemo(
    () =>
      MoneyCalc.isGTZero(options.amountEbt) &&
      PmtDef(PaymentSources.WORLD_PAY_EBT).hasCurrencySupport(options.amountEbt.currency),
    [options.amountEbt],
  )

  const onAddNewPaymentOption = (pmt?: PaymentMethod) => {
    // Hide the add payment method modal and open back up the payment selection modal
    modalContext.openLocalModal()
    // On mobile, we do not receive the new payment method, so we need to refresh the payment methods
    if (isMobile) refreshPaymentMethods()

    if (pmt) {
      onAddNewPaymentMethod(pmt)
    }
  }

  const goAddEbtCard = () => {
    // Hide the payment selection modal so that this does not show in front of the other
    modalContext.openGlobalModal(() => {
      Modal(
        <AddPaymentMethod
          userId={userId}
          onDismiss={onAddNewPaymentOption}
          ebtEnabled
          paymentForm={PaymentForms.EBT}
          currency={options.amountTotal.currency}
        />,
        {
          title: 'New EBT card',
          onDismiss: onAddNewPaymentOption,
        },
      )
    })
  }
  const goAddBankAccount = () => {
    // Hide the payment selection modal so that this does not show in front of the other
    modalContext.openGlobalModal(function () {
      Modal(
        <AddPaymentMethod
          userId={userId}
          onDismiss={onAddNewPaymentOption}
          paymentForm={PaymentForms.BANK}
          currency={options.amountTotal.currency}
        />,
        {
          title: 'New bank account',
          onDismiss: onAddNewPaymentOption,
        },
      )
    })
  }
  const goAddCreditCard = () => {
    // Hide the payment selection modal so that this does not show in front of the other
    modalContext.openGlobalModal(function () {
      Modal(
        <AddPaymentMethod
          userId={userId}
          onDismiss={onAddNewPaymentOption}
          paymentForm={PaymentForms.CARD}
          currency={options.amountTotal.currency}
        />,
        {
          title: 'New credit card',
          onDismiss: onAddNewPaymentOption,
        },
      )
    })
  }

  if (!showInfinitePayments) return null

  return (
    <View>
      <TextH2 size={16} style={sharedStyles.headerText}>
        Add Payment Method
      </TextH2>
      {showAddBank && <AddBankAccountButton onPress={goAddBankAccount} />}
      <AddCreditCardButton onPress={goAddCreditCard} />
      {/* We should only show the option to add an EBT card when there is EBT eligible amount on the order*/}
      {showAddEbt && (
        <AddEbtCardButton
          onPress={goAddEbtCard}
          subtitle={
            hasEbtPaymentMethod
              ? 'You can only have one EBT card. You can change your EBT card in the account page.'
              : undefined
          }
          isDisabled={hasEbtPaymentMethod}
        />
      )}
    </View>
  )
}
