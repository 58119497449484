import { useRangeRefinement } from '@/hooks/useAlgoliaRangeRefinement'
import { useFocusFx } from '@/hooks/useFocusFx'
import { internationalSelector, wholesaleSelector } from '@/redux/selectors'
import { getNearbyZipcodes } from '@api/Addresses'
import { getState, isValidZipcode } from '@helpers/address'
import { getCoordString } from '@helpers/coordinate'
import { isNum, isTruthy } from '@helpers/helpers'
import { CountryCode } from '@helpers/international/types'
import { AlgoliaGeoProduct, FILTERS, asFilter } from '@models/Algolia'
import { Coordinate } from '@models/Coordinate'
import { DefaultCatalog } from '@models/Product'
import { LocType } from '@screens/SearchScreen/searchScreen-helpers'
import { DateTime } from 'luxon'
import { useMemo, useRef, useState } from 'react'
import { useConfigure } from 'react-instantsearch'
import { useSelector } from 'react-redux'
import { UseApiFxReturn, useApiFx } from '../useApiFx'

type SearchConfigureProps = {
  center?: Coordinate
  radius?: number
  locType?: LocType
  region?: string
  includeNearbyZipcodes?: boolean
  farmIdsFilter?: string[]
}

const SEARCH_HITS_PER_PAGE = 400

/** Wrapper for algolia useConfigure for the search screen */
export function useSearchScreenConfigure({
  center,
  radius,
  locType,
  region,
  includeNearbyZipcodes,
  farmIdsFilter,
}: SearchConfigureProps) {
  const { isWholesale = false } = useSelector(wholesaleSelector)
  const [minDate, setMinDate] = useState(getMinAvailDate())
  const { country } = useSelector(internationalSelector)

  const updateCount = useRef(0)
  useFocusFx(() => {
    const intervalId = setInterval(() => {
      updateCount.current++

      if (updateCount.current > 5) {
        clearInterval(intervalId)
        return
      }

      setMinDate(getMinAvailDate())
    }, MIN_MINUTES_FROM_NOW * 60 * 1000)

    return () => clearInterval(intervalId)
  }, [])

  useRangeRefinement('lastAvailStamp', { min: minDate })

  // If there's a region zip code should fetch nearby zip codes
  const nearbyZipFx = useApiFx(getNearbyZipcodes, [region, undefined, country], includeNearbyZipcodes === true, {
    noRefocus: true,
  })

  // Calculates the geolocation search configuration
  const { aroundLatLng, aroundRadius } = useMemo(() => {
    const aroundLatLng = getCoordString(center) || undefined
    const aroundRadius = !aroundLatLng
      ? undefined // If there's no center coords it doesn't make sense to use the radius parameter
      : typeof radius === 'number'
      ? Math.floor(radius) // No floats
      : typeof radius === 'string' && isNum(radius)
      ? parseInt(radius, 10)
      : undefined

    if (locType === undefined || locType === 'coord') {
      /** Returning a value for these options will include products that have a pickup option, because if you pass the aroundLatLng option
       * algolia will include products that have the _geoloc property for their coordinates (And only pickup products have the _geoloc property).
       *
       * Then if aroundRadius is defined, it will further narrow those products based on their distance from the center coords. But if the aroundRadius is undefined then it should include all the products in the index that have a pickup option.
       */

      return { aroundLatLng, aroundRadius }
    }

    // This will deactivate any filtering based on the geolocation field, which is necessary when filtering by nonPickup loc types because those documents have undefined geolocation data, so if the location type is not 'coord' then these values should be undefined. This will ensure if the location type is delivery or shipping the search will not be based on geolocation data, but rather based on the delivery region.
    return { aroundLatLng: undefined, aroundRadius: undefined }
  }, [locType, radius, center])

  useConfigure({
    filters: [
      getFiltersString(isWholesale, locType, region, includeNearbyZipcodes, nearbyZipFx, center, country),
      farmIdsToFilter(farmIdsFilter),
    ]
      .filter(isTruthy)
      .join(' AND '),
    aroundLatLng,
    aroundRadius,
    // Hits are needed only on Search screen
    hitsPerPage: SEARCH_HITS_PER_PAGE,
    // Will make sure that the filter counts are taking in account only one version of the product
    facetingAfterDistinct: true,
  })
}

const farmIdsToFilter = (farmIds: string[] | undefined): string | null => {
  if (!farmIds?.length) return null
  const formatted = farmIds.map((farmId) => `farm.id: ${farmId}`)

  return `(${formatted.join(' OR ')})`
}

/** How many minutes from now is the minimum acceptable value for the last available timestamp filter. That means the filter is including products whose last available timestamp is at least this much time from now. */
export const MIN_MINUTES_FROM_NOW = 5

/** Returns a future timestamp based on the current time, which will be used as a filter for the minimum last available timestamp for a product */
export const getMinAvailDate = () => DateTime.now().plus({ minutes: MIN_MINUTES_FROM_NOW }).toMillis()

/** Returns the string to be used as a filter for the product's location */
function getFiltersString(
  isWholesale: boolean,
  locType: LocType | undefined,
  region: string | undefined,
  includeNearbyZipcodes: boolean | undefined,
  nearbyZipFx: UseApiFxReturn<typeof getNearbyZipcodes>,
  center: Coordinate | undefined,
  country: CountryCode,
) {
  let filtersString = `defaultCatalog:${isWholesale ? DefaultCatalog.Wholesale : DefaultCatalog.Retail} AND ${
    FILTERS.Product
  } ${
    isWholesale ? `AND ${FILTERS.WholesaleFarm}` : ''
  } AND NOT farm.status:Inactive AND NOT isHidden:true AND NOT isPrivate:true`

  if (locType === 'zip' || locType === 'state') {
    if (region) {
      // If a region is specified use it as the nonPickup filter
      if (locType === 'state' && !!getState(region, country)) {
        // If location type is state, the region must be a valid state
        filtersString += ` AND regions:${region}`
      } else if (locType === 'zip' && isValidZipcode(region, country)) {
        // If the location type is delivery the region must be a valid short zip
        if (!includeNearbyZipcodes || nearbyZipFx.loading || !nearbyZipFx.data) {
          // For a specific zip code, just use the region as a filter
          filtersString += ` AND regions:${region}`
        } else {
          // For nearby zip codes, must match any of the nearby zip codes
          const zipFilters = nearbyZipFx.data
            .slice(0, 100) // This is an artificial limit to the number of zip codes we'll consider nearby the user-selected region. If this is increased I recommend testing for performance
            .map((zip) => asFilter<Required<AlgoliaGeoProduct>, 'regions'>(`regions:${zip}`))

          filtersString += ` AND (${zipFilters.join(' OR ')})`
        }
      }
    } else {
      // If there's no region parameter, but the location type is a NonPickup type, we can show only documents that have any region

      /** This could be improved later: If we wanted to only show documents that have delivery separately from documents that have shipping, we'd need to remove the 'regions' field, and instead have separate fields for `zipCodes: ShortZip[] | ('None')[]` and for `states: ShortState[] | ('None')[]`. Then we could say `AND NOT zipCodes:None` for delivery only, or `AND NOT states:None` for shipping only. */
      filtersString += ' AND NOT regions:None'
    }
  } else if (locType === 'coord' && !center) {
    // If filtering by pickup, and the center coords is undefined, it must show any documents where there's no regions because these are the ones that belong to a pickup location
    // This must be done here because normally the geolocation search is controlled via the aroundLatLng property, but since the center is undefined, aroundLatLng is also undefined.
    // So this allows us to see products with at least a pickup option when clearing the center coords
    filtersString += ` AND regions:None`
  }
  return filtersString
}
